import React, { Fragment } from "react";

const BlogLargePage = () => {
  return (
    <Fragment>
      <div className="cs-subheader">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-subheader-text">
                <h2>Recent blogs</h2>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li className="active">
                      <a href="#">Latest Blog</a>
                    </li>
                    <li>
                      <a href="#">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ paddingTop: "20px" }}>
          <div className="container">
            <div className="row">
              <div className="section-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <div className="content-area">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <figure>
                            <img
                              src="assets/extra-images/blog-large-img-1.jpg"
                              alt="#"
                            />
                            <figcaption>
                              <div className="caption-text">
                                <span>STICKY POST</span>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Creak Limbron</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 23 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <figure>
                            <img
                              src="assets/extra-images/blog-large-img-2.jpg"
                              alt="#"
                            />
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Katya Dihich</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 15 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <ul className="blog-listing-large-slider">
                            <li>
                              <figure>
                                <img
                                  src="assets/extra-images/blog-large-img-4.jpg"
                                  alt="#"
                                />
                              </figure>
                            </li>
                            <li>
                              <figure>
                                <img
                                  src="assets/extra-images/blog-large-img-4.jpg"
                                  alt="#"
                                />
                              </figure>
                            </li>
                          </ul>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Creak Limbron</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 23 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <figure>
                            <iframe
                              src="https://player.vimeo.com/video/36305675?color=ff2300&title=0&byline=0&portrait=0"
                              width="500"
                              height="463"
                              allowfullscreen
                            ></iframe>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Creak Limbron</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 23 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <figure>
                            <img
                              src="assets/extra-images/blog-large-img-3.jpg"
                              alt="#"
                            />
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Creak Limbron</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 23 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing large-view">
                        <div className="cs-media">
                          <figure>
                            <img
                              src="assets/extra-images/blog-music-large.jpg"
                              alt="#"
                            />
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i> by{" "}
                              <a href="#">Creak Limbron</a>
                            </span>
                            <span className="post-date"> 15 March 2023</span>
                            <span className="post-comments">
                              <a href="#">
                                <i className="icon-bubble2"></i> 23 Comments
                              </a>
                            </span>
                          </div>
                          <div className="post-title">
                            <h2>
                              <a href="#">
                                Nissan Rogue SV AWD Review: Not So Roguish What
                                Price Supercar-dom? Audi pricing Released
                              </a>
                            </h2>
                          </div>
                          <p>
                            Bathroom. First bedroom features bay windows and gas
                            fireplace facing Fair Oaks. the ConvenientHardwood
                            floors throughout with high-coved ceilings. Two
                            bright bedrooms with split bathroom. First bedroom
                            features bay windows and gas fireplace facing Fair
                            Oaks. Convenient. Bathroom. First bedroom features
                            bay windows and gas fireplace facing Fair Oaks.
                            ConvenientHardwood floors throughout with high-coved
                            ceilings... Two bright bedrooms with split bathroom.
                            First bedroom features bay windows and gas fireplace
                            facing Fair Oaks. Convenient.
                          </p>
                          <a href="#" className="btn-more cs-color">
                            Read more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="section-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <div className="widget-search">
                  <div className="input-holder">
                    <input type="text" placeholder="Enter any keyword" />
                    <label>
                      <input type="submit" value="" />
                    </label>
                  </div>
                </div>
                <div className="widget widget-recent-posts">
                  <h6>Recent Posts</h6>
                  <ul>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Nissan Rogue SV AWD Review: best So Roguish What Price
                          Supercar-dom?
                        </a>
                        <span>
                          <i className="icon-clock5"></i>3 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Nissan Rogue SV AWD Review: best So Roguish What Price
                          Supercar-dom?
                        </a>
                        <span>
                          <i className="icon-clock5"></i>27 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Audi Switches Focus to e-tron, Delays Other Models
                        </a>
                        <span>
                          <i className="icon-clock5"></i>58 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Gripping News: Mini Adds All-Wheel-Drive Option to New
                          Clubman
                        </a>
                        <span>
                          <i className="icon-clock5"></i>23 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-5.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Audi Switches Focus to e-tron, Delays Other Models
                        </a>
                        <span>
                          <i className="icon-clock5"></i>23 Days Ago
                        </span>
                      </div>
                    </li>
                  </ul>
                  <a href="#." className="cs-view-blog">
                    View all Blogs
                  </a>
                </div>
                <div className="widget widget-tags">
                  <h6>Tag Cloud</h6>
                  <a href="#">Houses</a>
                  <a href="#">Apartment</a>
                  <a href="#">Rates</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Rates</a>
                  <a href="#">Flats</a>
                  <a href="#">People</a>
                  <a href="#">United Kingdom</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Flats</a>
                  <a href="#">Houses</a>
                  <a href="#">Apartment</a>
                  <a href="#">Rates</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Road</a>
                  <a href="#">Flats</a>
                  <a href="#">People</a>
                  <a href="#">United Kingdom</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                </div>
                <div className="widget widget-categories">
                  <h6>Top Categores</h6>
                  <ul>
                    <li>
                      <a href="#">Motorbikes</a>
                    </li>
                    <li>
                      <a href="#">Compacts</a>
                    </li>
                    <li>
                      <a href="#">Sedans</a>
                    </li>
                    <li>
                      <a href="#">4x4 SUVs</a>
                    </li>
                    <li>
                      <a href="#">Pickups</a>
                    </li>
                    <li>
                      <a href="#">Vans &amp; Trucks</a>
                    </li>
                    <li>
                      <a href="#">Buy a car</a>
                    </li>
                    <li>
                      <a href="#">Sell your Car</a>
                    </li>
                    <li>
                      <a href="#">Buy Back</a>
                    </li>
                    <li>
                      <a href="#">Buy a car</a>
                    </li>
                    <li>
                      <a href="#">Sell your Car</a>
                    </li>
                    <li>
                      <a href="#">Buy Back</a>
                    </li>
                    <li>
                      <a href="#">Repair Shop</a>
                    </li>
                    <li>
                      <a href="#">Car Land</a>
                    </li>
                    <li>
                      <a href="#">Car Showrooms</a>
                    </li>
                    <li>
                      <a href="#">Repair Shop</a>
                    </li>
                  </ul>
                </div>
                <div className="widget featured-listing">
                  <h6>Featured Listings</h6>
                  <ul>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ferrari 360 Modena 2dr F1 3.6</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Mborghini Aventador LP700-4</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ford Fiesta 1.6 TDCi Zetec 5dr</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ford Fiesta 1.6 TDCi Zetec 5dr</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-twitter">
                  <h6>Twitter</h6>
                  <ul>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <i className="icon-twitter"></i>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <p>
                          <a href="#">@Crssp</a> we literally just updated the
                          theme born for new now: themeforest.net/theme
                          #Previews{" "}
                          <span>
                            <i className="icon-dot-single"></i>2 minutes ago
                          </span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <i className="icon-twitter"></i>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <p>
                          <a href="#">@Crssp</a> we literally just updated the
                          theme born for new now: themeforest.net/theme
                          #Previews{" "}
                          <span>
                            <i className="icon-dot-single"></i>2 minutes ago
                          </span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <i className="icon-twitter"></i>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <p>
                          <a href="#">@Crssp</a> we literally just updated the
                          theme born for new now: themeforest.net/theme
                          #Previews{" "}
                          <span>
                            <i className="icon-dot-single"></i>2 minutes ago
                          </span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <i className="icon-twitter"></i>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <p>
                          <a href="#">@Crssp</a> we literally just updated the
                          theme born for new now: themeforest.net/theme
                          #Previews{" "}
                          <span>
                            <i className="icon-dot-single"></i>2 minutes ago
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-ad">
                  <figure>
                    <img src="assets/extra-images/widget-ad.png" alt="#" />
                  </figure>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: "#19171a" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="cs-ad"
                  style={{ textAlign: "center", padding: "55px 0 32px" }}
                >
                  <div className="cs-media">
                    <figure>
                      <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogLargePage;
