import React from "react";
import UserNavbar from "../../../components/user-navbar";

const UserShortlistedVehicles = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "url(assets/extra-images/user-bg-img.jpg) no-repeat",
          backgroundSize: "cover",
          minHeight: "175px",
          marginTop: "-30px",
          marginBottom: "-129px",
        }}
      ></div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-user-account-holder">
                <div className="row">
                  <UserNavbar />
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                    style={{ zIndex: "0" }}
                  >
                    <div className="cs-user-section-title">
                      <h4>Shortlisted vehicles</h4>
                      <ul>
                        <li>
                          <a href="#" className="cs-active-btn">
                            Active
                          </a>
                          <ul>
                            <li>
                              <a href="#">Expired</a>
                            </li>{" "}
                            <li>
                              <a href="#">Removed</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="cs-shortlisted-car row">
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <address>
                          <i className=" icon-map2"></i>Quartz Blue Metallic
                        </address>
                        <h6>
                          <a href="#">BMW 535i, Navi, Leather, ABS</a>
                        </h6>
                        <div className="cs-price">
                          <span className="cs-color">$17,845</span>
                          <em>MSRP $32,000</em>
                        </div>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          className="cs-remove-btn cs-bgcolor"
                        >
                          Remove
                        </a>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <address>
                          <i className=" icon-map2"></i>Quartz Blue Metallic
                        </address>
                        <h6>
                          <a href="#">Toyota Corolla XLi VVTi for Sale</a>
                        </h6>
                        <div className="cs-price">
                          <span className="cs-color">$70,000</span>
                          <em>MSRP $75,000</em>
                        </div>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          className="cs-remove-btn cs-bgcolor"
                        >
                          Remove
                        </a>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <address>
                          <i className=" icon-map2"></i>Quartz Blue Metallic
                        </address>
                        <h6>
                          <a href="#">BMW 328i, Sport Line Body Kit</a>
                        </h6>
                        <div className="cs-price">
                          <span className="cs-color">$20,000</span>
                          <em>MSRP $25,000</em>
                        </div>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          className="cs-remove-btn cs-bgcolor"
                        >
                          Remove
                        </a>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <address>
                          <i className=" icon-map2"></i>Quartz Blue Metallic
                        </address>
                        <h6>
                          <a href="#">Toyota Avalon LTX, Leather, Sunroof</a>
                        </h6>
                        <div className="cs-price">
                          <span className="cs-color">$21,000</span>
                          <em>MSRP $25,000</em>
                        </div>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          className="cs-remove-btn cs-bgcolor"
                        >
                          Remove
                        </a>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img5.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <address>
                          <i className=" icon-map2"></i>Quartz Blue Metallic
                        </address>
                        <h6>
                          <a href="#">Kia Soul, Brand New Model 2023</a>
                        </h6>
                        <div className="cs-price">
                          <span className="cs-color">$17,845</span>
                          <em>MSRP $32,000</em>
                        </div>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          className="cs-remove-btn cs-bgcolor"
                        >
                          Remove
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="cs-load-more">
                    <a href="#" className="loadmore-btn">
                      Load more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserShortlistedVehicles;
