import React from "react";
import { Link } from "react-router-dom";

const UserNavbar = () => {
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
      <ul className="cs-user-accounts-list">
        <li>
          <Link to="/user-vehicle-listings">My vehicles</Link>
        </li>
        <li>
          <Link to="/user-shortlisted-vehicles">Shortlisted</Link>
        </li>
        <li>
          <Link to="/user-packages">Packages</Link>
        </li>
        <li>
          <Link to="/user-payment">Payments</Link>
        </li>
        <li className="active">
          <Link to="/user-profile">Profile Settings</Link>
        </li>
        <li>
          <Link to="/user-add-new-vehicle">Post a vehicles</Link>
        </li>
        <li>
          <a href="#">Sign Out</a>
        </li>
      </ul>
    </div>
  );
};

export default UserNavbar;
