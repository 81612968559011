import React from 'react';

const ShopCheckout = () => {
  return (
    <div className="main-section">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="woocommerce">
                <div className="woocommerce-info">
                  <span className="cs-color">Informational.</span>
                  Returning customer?
                  <a className="showlogin cs-color" href="#">
                    Click here to login
                  </a>
                </div>
                <form className="checkout woocommerce-checkout row">
                  <div
                    id="customer_details"
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"
                  >
                    <div className="col2-set">
                      <div className="woocommerce-billing-fields">
                        <h4>Billing Details</h4>
                        <p className="form-row form-row form-row-first validate-required">
                          <label>
                            First Name{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="John"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-last validate-required">
                          <label className="" for="billing_last_name">
                            Last Name{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="Snow"
                            id="billing_last_name"
                            name="billing_last_name"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-first validate-required">
                          <label>Company Name</label>
                          <input
                            type="text"
                            value=""
                            placeholder="Chimp Solutions"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-last validate-required">
                          <label className="" for="billing_last_name1">
                            Email Address{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="Info@chimpsolutions.co.uk"
                            id="billing_last_name1"
                            name="billing_last_name"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-first validate-required">
                          <label>
                            Phone{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="+44 123 4567 89"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-last validate-required">
                          <label className="" for="billing_last_name2">
                            Country{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="United Kingdom"
                            id="billing_last_name2"
                            name="billing_last_name"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-first validate-required">
                          <label>
                            Address <em>(1)</em>{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="Street Address"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-last validate-required">
                          <label className="" for="billing_last_name3">
                            Address <em>(2)</em>{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="Apartment, suite, unit etc. (optional)"
                            id="billing_last_name3"
                            name="billing_last_name"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-first validate-required">
                          <label>
                            Town / City{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="London"
                            className="input-text "
                          />
                        </p>
                        <p className="form-row form-row form-row-last validate-required">
                          <label className="" for="billing_last_name4">
                            Postcode / Zipe{' '}
                            <abbr title="required" className="required">
                              *
                            </abbr>
                          </label>
                          <input
                            type="text"
                            value=""
                            placeholder="NW1W"
                            id="billing_last_name4"
                            name="billing_last_name"
                            className="input-text "
                          />
                        </p>
                        <div className="clear"></div>
                        <p className="form-row form-row-wide create-account">
                          <input
                            type="checkbox"
                            value="1"
                            name="createaccount"
                            id="createaccount"
                            className="input-checkbox"
                          />
                          <label className="checkbox" for="createaccount">
                            Create an account?
                          </label>
                        </p>
                        <div
                          className="create-account"
                          style={{ display: 'block' }}
                        >
                          <p>
                            Create an account by entering the information below.
                            If you are a returning customer please login at the
                            top of the page.
                          </p>
                          <p
                            id="account_password_field"
                            className="form-row form-row form-row-first validate-required"
                          >
                            <label className="" for="account_password">
                              Account password
                              <abbr title="required" className="required">
                                *
                              </abbr>
                            </label>
                            <input
                              type="password"
                              value=""
                              placeholder="Password"
                              id="account_password"
                              name="account_password"
                              className="input-text "
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <h4>Your order</h4>
                    <div
                      className="woocommerce-checkout-review-order"
                      id="order_review"
                    >
                      <table className="shop_table woocommerce-checkout-review-order-table">
                        <thead>
                          <tr>
                            <th className="product-name">Product</th>
                            <th className="product-total">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="cart_item">
                            <td className="product-name"> Air Filter × 1</td>
                            <td className="product-total">
                              <span className="amount">$250.00</span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="cart-subtotal">
                            <th>Subtotal</th>
                            <td>
                              <span className="amount">$250.00</span>
                            </td>
                          </tr>
                          <tr className="shipping">
                            <th>Shipping</th>
                            <td></td>
                          </tr>
                          <tr className="order-total">
                            <th>Total</th>
                            <td>
                              <strong>
                                <span className="amount">$250.00</span>
                              </strong>{' '}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <h4>payment</h4>
                    <div className="woocommerce-checkout-payment" id="payment">
                      <ul className="wc_payment_methods payment_methods methods">
                        <li className="wc_payment_method payment_method_cheque">
                          <input
                            type="radio"
                            data-order_button_text=""
                            name="1"
                            value="cheque"
                            className="input-radio"
                            id="payment_method_cheque"
                          />
                          <label for="payment_method_cheque">
                            Cheque Payment
                          </label>
                          <div className="payment_box payment_method_cheque">
                            <p>
                              Please send your cheque to Store Name, Store
                              Street, Store Town, Store State / County, Store
                              Postcode.
                            </p>
                          </div>
                        </li>
                        <li className="wc_payment_method payment_method_paypal">
                          <input
                            type="radio"
                            data-order_button_text="Proceed to PayPal"
                            name="1"
                            value="paypal"
                            className="input-radio"
                            id="payment_method_paypal"
                          />
                          <label for="payment_method_paypal">PayPal</label>
                        </li>
                        <li>
                          <div className="radiobox">
                            <input type="radio" name="2" value="" id="paypal" />
                            <label for="paypal">
                              <img src="assets/images/paypal-img.jpg" alt="#" />
                            </label>
                          </div>
                          <div className="radiobox">
                            <input
                              type="radio"
                              name="2"
                              value="skrill"
                              id="skrill"
                            />
                            <label for="skrill">
                              <img src="assets/images/skrill-img.jpg" alt="#" />
                            </label>
                          </div>
                          <div className="radiobox">
                            <input
                              type="radio"
                              name="2"
                              value="fastbank"
                              id="fastbank"
                            />
                            <label for="fastbank">
                              <img
                                src="assets/images/fastbank-img.jpg"
                                alt="#"
                              />
                            </label>
                          </div>
                          <div className="radiobox">
                            <input
                              type="radio"
                              name="2"
                              value="authorize"
                              id="authorize"
                            />
                            <label for="authorize">
                              <img
                                src="assets/images/authorize-img.jpg"
                                alt="#"
                              />
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div className="form-row place-order">
                        <input
                          type="submit"
                          data-value="Upload Photos"
                          value="Upload Photos"
                          className="button alt cs-bgcolor"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#19171a' }} className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                style={{ textAlign: 'center', padding: '55px 0 25px' }}
                className="cs-ad"
              >
                <div className="cs-media">
                  <figure>
                    {' '}
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />{' '}
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCheckout;
