import React from "react";
import CarouselSlider from "../../components/carousel";

const Home = () => {
  return (
    <div className="main-section">
      {/* <!--Main Banner--> */}
      <div
        className="page-section cs-banner"
        style={{
          background: "url(assets/extra-images/full-section-img.jpg) no-repeat",
          backgroundSize: "cover",
          padding: "206px 0 80px",
          textShadow: "3px 4px 6px rgba(0, 0, 0, 0.37)",
          marginTop: "-30px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div
                className="image-frame defualt"
                style={{ paddingTop: "40px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/image-frame-1.png" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="cs-column-text">
                <span
                  className="cs-bgcolor"
                  style={{
                    color: "#fff",
                    padding: "5px 20px",
                    textShadow: "3px 4px 6px rgba(0, 0, 0, 0.37)",
                    marginBottom: "20px",
                  }}
                >
                  More refined interior
                </span>
                <h1
                  style={{
                    color: "#fff !important",
                    fontSize: "50px",
                    lineHeight: "53px",
                    textShadow: "3px 4px 6px rgba(0, 0, 0, 0.37)",
                  }}
                >
                  SAY HELLO TO NEW KIND OF yum DRIVING PLEASURE.
                </h1>
                <a
                  href="#"
                  className="cs-button"
                  style={{
                    color: "#fff",
                    backgroundColor: "#d00000 !important",
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    lineHeight: "17px",
                    textAlign: "center",
                    paddingTop: "20px",
                    marginRight: "10px",
                    letterSpacing: "1px",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    textShadow: "3px 4px 6px rgba(0, 0, 0, 0.37)",
                  }}
                >
                  Starts From
                </a>
                <strong
                  style={{
                    verticalAlign: "middle",
                    fontSize: "50px",
                    color: "#fff",
                    textShadow: "3px 4px 6px rgba(0, 0, 0, 0.37)",
                  }}
                >
                  <em
                    style={{
                      fontStyle: "normal",
                      fontSize: "30px",
                      verticalAlign: "top",
                      lineHeight: "0",
                    }}
                  >
                    $
                  </em>
                  55,897
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Main Banner--> */}
      {/* <!--Main Banner form--> */}
      <div
        className="page-section"
        style={{
          background: "rgba(36, 41, 49, 1)",
          paddingTop: "33px",
          //   -webkitBoxShadow: '0 0 5px rgba(0, 0, 0, 0.4), inset 1px 2px rgba(255, 255, 255, 0.3)',
          //   -mozBoxShadow: '0 0 5px rgba(0, 0, 0, 0.4), inset 1px 2px rgba(255, 255, 255, 0.3)',
          boxShadow:
            "0 0 5px rgba(0, 0, 0, 0.4), inset 0px 2px rgba(255, 255, 255, 0.3)",
          border: "solid 1px #242931",
          marginBottom: "110px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="section-fullwidtht col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {/* <!--Element Section Start--> */}
              <div className="main-search">
                {/* <!-- <form className="row">
                          <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
                              <div className="search-input"> <i className="icon-search2"></i>
                                  <input type="text" placeholder="Search by Keyword">
                              </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                              <div className="select-dropdown">
                                  <select className="chosen-select-no-single">
                                      <option value="" selected="selected">Select Make</option>
                                      <option value="Alfa Romeo">Alfa Romeo</option>
                                      <option value="Alpine">Alpine</option>
                                      <option value="Aston Martin">Aston Martin</option>
                                      <option value="Audi">Audi</option>
                                      <option value="Bently">Bently</option>
                                      <option value="BMW">BMW</option>
                                      <option value="Bugatti">Bugatti</option>
                                      <option value="Ferrari">Ferrari</option>
                                      <option value="Fiat">Fiat</option>
                                      <option value="Lamborghini">Lamborghini</option>
                                      <option value="Lancia">Lancia</option>
                                      <option value="Land Rover">Land Rover</option>
                                      <option value="Maserati">Maserati</option>
                                      <option value="McLaren">McLaren</option>
                                      <option value="Mercedes-Benz">Mercedes-Benz</option>
                                      <option value="Mini">Mini</option>
                                      <option value="Opel">Opel</option>
                                      <option value="Peugeot">Peugeot</option>
                                      <option value="Porsche">Porsche</option>
                                      <option value="Renault">Renault</option>
                                      <option value="Rolls-Royce">Rolls-Royce</option>
                                      <option value="Vauxhall">Vauxhall</option>
                                      <option value="Volkswagen">Volkswagen</option>
                                  </select>
                              </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2">
                              <div className="select-dropdown">
                                  <select  className="chosen-select-no-single">
                                      <option selected="selected">Select Type</option>
                                      <option value="Vehicles">Vehicles</option>
                                      <option value="Motors">Motors</option>
                                      <option value="Cars and Races">Cars and Races</option>
                                  </select>
                              </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2"> 
                              <div className="select-location">
                                  <div className="select-location" id="cs-top-select-holder">
                                      <div className="cs_searchbox_div">
                                          <input type="text" name="cs_" className="form-control cs_search_location_field" placeholder="All Locations" autocomplete="off">
                                          <input type="hidden" value="" name="location" className="search_keyword">
                                          <div className="cs_location_autocomplete" style="width: 260px; left: 1042.5px; top: 751px; display: none;"></div>
                                      </div>
                                      <a className="location-btn pop" href="#;" id="popup"><i className="icon-target3"></i></a>
                                      <div className="select-popup" id="popup1"> 
                                          <a id="cs_close" className="cs-location-close-popup"><i className="cs-color icon-times"></i></a>
                                          <p>Show With in</p>
                                          <div className="slider slider-horizontal">
                                              <div className="slider-track">
                                                  <div className="slider-track-low" style="left: 0px; width: 0%;"></div>
                                                  <div className="slider-selection" style="left: 0%; width: 100%;"></div>
                                                  <div className="slider-track-high" style="right: 0px; width: 0%;"></div>
                                                  <div className="slider-handle min-slider-handle round" style="left: 100%;" tabindex="0"></div>
                                                  <div className="slider-handle max-slider-handle round hide" style="left: 0%;" tabindex="0"></div>
                                              </div>
                                              <div className="tooltip tooltip-main top" style="left: 100%; margin-left: 0px;">
                                                  <div className="tooltip-arrow"></div>
                                                  <div className="tooltip-inner">500</div>
                                              </div>
                                              <div className="tooltip tooltip-min top" style="display: none;">
                                                  <div className="tooltip-arrow"></div>
                                                  <div className="tooltip-inner"></div>
                                              </div>
                                              <div className="tooltip tooltip-max top" style="display: none;">
                                                  <div className="tooltip-arrow"></div>
                                                  <div className="tooltip-inner"></div>
                                              </div>
                                          </div>
                                          <input type="text" data-slider-value="200" data-slider-step="20" data-slider-max="500" data-slider-min="0" name="radius" id="ex6392375604" style="display: none;" data-value="500" value="500">
                                          <span id="ex6CurrentSliderValLabel_job"><span id="ex6SliderVal392375604">500</span>Miles</span>
                                          <p className="my-location">of <i className="cs-color icon-location-arrow"></i><a onClick="cs_get_location(this)" className="cs-color">My location</a></p>
                                      </div>
                                  </div>
                                  <input type="text" name="cs_" className="cs-geo-location form-control txt-field geo-search-location" style="display:none;" onChange="this.form.submit()">
                                  <div style="display:none;" className="cs-undo-select"> <i className="icon-times"></i> </div>
                              </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 col-xxl-1">
                              <div className="search-btn">
                                   <input type="button" value="submit" className="cs-bgcolor">
                                  <a href="auto-listing.html" className="submit-btn cs-bgcolor"></a>
                                  <label><a href="#">ADVANCE SEARCH</a></label>
                              </div>
                          </div>
                      </form> --> */}
              </div>
              {/* <!--Element Section End--> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!--Main Banner form--> */}
      {/* <!--image frame with cloum text by My Shahzad--> */}
      {/* <!-- <div className="page-section" style="margin-bottom:110px;">
      <div className="container">
          <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div className="image-frame defualt">
                      <div className="cs-media">
                          <figure> <img src="assets/extra-images/image-frame.jpg" alt="#"> </figure>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div className="cs-column-text"> <span className="cs-color" style="font-size:14px;margin-bottom:15px;text-transform: capitalize !important; font-weight:bold;">ADAPTIVE DRIVE HEAD uP DISPLAY</span>
                      <h1 style=" line-height:43px !important;">Matte Black Chevy C7 Corvette 
                          Stingray on Custom 21in Forgiato 
                          Quinto Wheels.</h1>
                      <p style="margin-bottom:25px; ">Arear view camera and lane departure warning. This car has 6 airbags fitted for your protection. It has front &amp; rear power windows, central locking and 2nd row split folding seats. Family life is made easy in this 2023 Jaguar XF. This car has Bluetooth connectivity, side curtain airbags, subwoofer, trailer sway control, sports pedals and heads up information display. This car has forward collision alert/warning. This car comes with enough seats for 5. You can relax.</p>
                      <div className="cs-button-effect"><a href="#" className="cs-button" style="color:#fff;font-size:11px; padding:12px 45px; font-weight:bold; text-transform:uppercase;">Kim Broders</a></div> 
                  </div>
              </div>
          </div>
      </div>
  </div> --> */}
      {/* <!--image frame with cloum text by My Shahzad-->
  <!--full width category section--> */}
      <div
        className="page-section"
        style={{
          background:
            "#edf0f5 url(assets/extra-images/full-width-img-01.png) no-repeat",
          padding: "100px 0 0 0",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div style={{ minHeight: "510px" }}></div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="catagory-section">
                {/* <!--Element Section Start--> */}
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-element-title">
                      <h3>Browse cars by make</h3>
                      <span className="cs-color">
                        We currently have 428,897 cars available
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-catagory">
                      <ul>
                        <li>
                          <a href="#">
                            <span>Alfa Romeo</span>
                            <small>(256)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Alpine</span>
                            <small>(157)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Alpine</span>
                            <small>(145)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Aston Martin</span>
                            <small>(456)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Audi</span>
                            <small>(856)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Bently</span>
                            <small>(125)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>BMW</span>
                            <small>(562)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Bugatti</span>
                            <small>(562)</small>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-catagory">
                      <ul>
                        <li>
                          <a href="#">
                            <span>Ferrari</span>
                            <small>(256)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Fiat</span>
                            <small>(157)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Lamborghini</span>
                            <small>(145)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Lancia</span>
                            <small>(456)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Land Rover</span>
                            <small>(856)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Maserati</span>
                            <small>(125)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>McLaren</span>
                            <small>(562)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Mercedes-Benz</span>
                            <small>(562)</small>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-catagory">
                      <ul>
                        <li>
                          <a href="#">
                            <span>Mini</span>
                            <small>(256)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Opel</span>
                            <small>(157)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Peugeot</span>
                            <small>(145)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Porsche</span>
                            <small>(456)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Renault</span>
                            <small>(856)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span> Rolls-Royce</span>
                            <small>(125)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Vauxhall</span>
                            <small>(562)</small>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>Volkswagen</span>
                            <small>(562)</small>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="button_style cs-button-effect">
                      <a href="#" className="cs-button">
                        Search for cars
                      </a>
                    </div>
                  </div>
                </div>
                {/* <!--Element Section End--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--full width category section-->
  <!--tabs section--> */}
      <div
        className="page-section"
        style={{ paddingTop: "70px", paddingBottom: "65px" }}
      >
        <div className="container">
          <div className="row">
            <div className="section-fullwidtht col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 co-sm-12 col-xs-12">
                  {/* <!--Element Section Start--> */}
                  <div className="cs-section-title">
                    <h3 style={{ textTransform: "uppercase !important" }}>
                      Perfect Cars for you
                    </h3>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "11px",
                        color: "#999999 !important",
                      }}
                    >
                      It will help us find the Toyota you're looking for in your
                      area.
                    </p>
                  </div>
                  {/* <!--Tabs Start--> */}
                  <div className="cs-tabs full-width">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a className="active" data-bs-toggle="tab" href="#home">
                          New Cars
                        </a>
                      </li>
                      <li className="nav-item">
                        <a data-bs-toggle="tab" href="#menu1">
                          Used Cars
                        </a>
                      </li>
                      <li className="nav-item">
                        <a data-bs-toggle="tab" href="#menu2">
                          Featured Cars
                        </a>
                      </li>
                      <li className="nav-item">
                        <a data-bs-toggle="tab" href="#menu3">
                          Bikes and Trucks
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div id="home" className="tab-pane fade show active">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img1.jpg"
                                    alt="#"
                                  />
                                  <figcaption>
                                    <span className="auto-featured">
                                      Featured
                                    </span>
                                  </figcaption>
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view"
                                  ></a>
                                  <div id="list-view" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list"
                                  />
                                  <label htmlFor="check-list">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img2.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view1"
                                  ></a>
                                  <div id="list-view1" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list1"
                                  />
                                  <label htmlFor="check-list1">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img3.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view2"
                                  ></a>
                                  <div id="list-view2" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list2"
                                  />
                                  <label htmlFor="check-list2">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img4.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list3"
                                  />
                                  <label htmlFor="check-list3">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img5.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list4"
                                  />
                                  <label htmlFor="check-list4">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img6.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list5"
                                  />
                                  <label htmlFor="check-list5">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img7.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list6"
                                  />
                                  <label htmlFor="check-list6">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img8.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list8"
                                  />
                                  <label htmlFor="check-list8">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="menu1" className="tab-pane fade">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img1.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      -Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-01"
                                  ></a>
                                  <div id="list-view-01" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-01"
                                  />
                                  <label htmlFor="check-list-01">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img2.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-02"
                                  ></a>
                                  <div id="list-view-02" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-02"
                                  />
                                  <label htmlFor="check-list-02">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img3.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-03"
                                  ></a>
                                  <div id="list-view-03" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-03"
                                  />
                                  <label htmlFor="check-list-03">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img4.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-003"
                                  />
                                  <label htmlFor="check-list-003">
                                    Compare
                                  </label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img5.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-04"
                                  />
                                  <label htmlFor="check-list-04">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img6.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-05"
                                  />
                                  <label htmlFor="check-list-05">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img7.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-06"
                                  />
                                  <label htmlFor="check-list-06">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img8.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-08"
                                  />
                                  <label htmlFor="check-list-08">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="menu2" className="tab-pane fade">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img1.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-11"
                                  ></a>
                                  <div id="list-view-11" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-11"
                                  />
                                  <label htmlFor="check-list-11">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img2.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-12"
                                  ></a>
                                  <div id="list-view-12" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-12"
                                  />
                                  <label htmlFor="check-list-12">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img3.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-13"
                                  ></a>
                                  <div id="list-view-13" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-13"
                                  />
                                  <label htmlFor="check-list-13">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img4.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-14"
                                  />
                                  <label htmlFor="check-list-14">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img5.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-15"
                                  />
                                  <label htmlFor="check-list-15">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img6.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-16"
                                  />
                                  <label htmlFor="check-list-16">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img7.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-17"
                                  />
                                  <label htmlFor="check-list-17">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img8.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-18"
                                  />
                                  <label htmlFor="check-list-18">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="menu3" className="tab-pane fade">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img1.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-21"
                                  ></a>
                                  <div id="list-view-21" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-21"
                                  />
                                  <label htmlFor="check-list-21">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img2.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-22"
                                  ></a>
                                  <div id="list-view-22" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-22"
                                  />
                                  <label htmlFor="check-list-22">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img3.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="btn-list">
                                  <a
                                    href="#"
                                    className="btn btn-danger collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#list-view-250"
                                  ></a>
                                  <div id="list-view-250" className="collapse">
                                    <ul>
                                      <li>30/36 est. mpg 18</li>
                                      <li>
                                        Black front grille with chrome accent
                                      </li>
                                      <li>Cruise control</li>
                                      <li>Remote keyless entry system</li>
                                      <li>
                                        Tilt 3-spoke steering wheel with audio
                                        controls
                                      </li>
                                      <li>15-in. alloy wheels</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-23"
                                  />
                                  <label htmlFor="check-list-23">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img4.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list24"
                                  />
                                  <label htmlFor="check-list24">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img5.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-25"
                                  />
                                  <label htmlFor="check-list-25">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img6.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-26"
                                  />
                                  <label htmlFor="check-list-26">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img7.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-27"
                                  />
                                  <label htmlFor="check-list-27">Compare</label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="auto-listing auto-grid">
                              <div className="cs-media">
                                <figure>
                                  <img
                                    src="assets/extra-images/listing-Grid-img8.jpg"
                                    alt="#"
                                  />
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span className="cs-categories">
                                  <a href="#">Timlers Motors</a>
                                </span>
                                <div className="post-title">
                                  <h6>
                                    <a href="#">
                                      Mazda CX-5 SX, V6, ABS, Sunroof
                                    </a>
                                  </h6>
                                  <div className="auto-price">
                                    <span className="cs-color">$25,000</span>
                                    <em>MSRP $27,000</em>
                                  </div>
                                </div>
                                <div className="cs-checkbox">
                                  <input
                                    type="checkbox"
                                    name="list"
                                    value="check-listn"
                                    id="check-list-027"
                                  />
                                  <label htmlFor="check-list-027">
                                    Compare
                                  </label>
                                </div>
                                <a href="#" className="View-btn">
                                  View Detail
                                  <i className="icon-arrow-long-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--Tabs End--> */}
                  {/* <!--Element Section End--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--tabs section--> */}
      {/* <!--Latest Model Auto Slider Start--> */}
      <div
        className="page-section"
        style={{
          background: "rgba(237, 240, 245, 1)",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="section-fullwidtht col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-auto-listing cs-auto-box">
                {/* <!--Element Section Start--> */}
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-element-title">
                      <h2>Latest Released Car Models</h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <CarouselSlider />
                    {/* <div className="cs-auto-box-slider">
                      <div className="cs-auto-box-slide">
                        <div className="cs-auto-box-inner">
                          <div className="cs-media">
                            <span className="featured"></span>
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/latest-model-01.jpg"
                                  alt="#"
                                />
                              </a>
                              <figcaption></figcaption>
                            </figure>
                            <div className="caption-text">
                              <a href="#">
                                <h2>
                                  Avalon Hybrid Built for the endless weekend.
                                </h2>
                              </a>
                            </div>
                          </div>
                          <div className="auto-text cs-bgcolor">
                            <span>$28,000</span>
                            <small>MSRP $35,000</small>
                            <a href="#" className="cs-button pull-right">
                              <i className="icon-arrow_forward"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="cs-auto-box-slide">
                        <div className="cs-auto-box-inner">
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/latest-model-02.jpg"
                                  alt="#"
                                />
                              </a>
                              <figcaption></figcaption>
                            </figure>
                            <div className="caption-text">
                              <a href="#">
                                <h2>
                                  Speed Dual-Clutch Featured Special New ReLease
                                </h2>
                              </a>
                            </div>
                          </div>
                          <div className="auto-text cs-bgcolor">
                            <span>$20,045</span>
                            <small>MSRP $32,000</small>
                            <a href="#" className="cs-button pull-right">
                              <i className="icon-arrow_forward"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="cs-auto-box-slide">
                        <div className="cs-auto-box-inner">
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/latest-model-03.jpg"
                                  alt="#"
                                />
                              </a>
                              <figcaption></figcaption>
                            </figure>
                            <div className="caption-text">
                              <a href="#">
                                <h2>
                                  All New BMW ADAPTIVE DRIVE HEAD uP DISPLAY
                                </h2>
                              </a>
                            </div>
                          </div>
                          <div className="auto-text cs-bgcolor">
                            <span>$20,045</span>
                            <small>MSRP $32,000</small>
                            <a href="#" className="cs-button pull-right">
                              <i className="icon-arrow_forward"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="cs-auto-box-slide">
                        <div className="cs-auto-box-inner">
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/latest-model-02.jpg"
                                  alt="#"
                                />
                              </a>
                              <figcaption></figcaption>
                            </figure>
                            <div className="caption-text">
                              <a href="#">
                                <h2>
                                  Avalon Hybrid Built for the endless weekend.
                                </h2>
                              </a>
                            </div>
                          </div>
                          <div className="auto-text cs-bgcolor">
                            <span>$28,000</span>
                            <small>MSRP $35,000</small>
                            <a href="#" className="cs-button pull-right">
                              <i className="icon-arrow_forward"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <!--Element Section End--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Latest Model Auto Slider End--> */}
      <div
        className="page-section"
        style={{ paddingTop: "70px", paddingBottom: "50px" }}
      >
        <div className="container">
          <div className="row">
            <div className="section-fullwidtht col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-blog cs-blog-grid">
                {/* <!--Element Section Start--> */}
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-element-title">
                      <h2>WHAT'S TRENDING in Car World</h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-blog-listing blog-grid">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/blog-listing-1.jpg"
                              alt="#"
                            />
                          </a>
                          <figcaption>
                            <div className="caption-text">
                              <span>STICKY POST</span>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                      <div className="blog-text">
                        <div className="post-option">
                          <span className="post-date">Vehicles</span>
                        </div>
                        <div className="post-title">
                          <h4>
                            <a href="#">
                              Avalon Hybrid Built for the endless weekend.
                            </a>
                          </h4>
                        </div>
                        <p>
                          Norwegian airline website named Widerøe generated a
                          tremendous amount of buzz and a lot of very happy
                          customers this weekend.
                        </p>
                        <div className="post-meta">
                          <figure>
                            <img
                              src="assets/extra-images/blog-grid-1.jpg"
                              alt="#"
                            />
                          </figure>
                          <span className="post-by">Anselm Hannemann</span>
                          <em>August 15, 2023</em>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-blog-listing blog-grid">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/blog-listing-2.jpg"
                              alt="#"
                            />
                          </a>
                          <figcaption></figcaption>
                        </figure>
                      </div>
                      <div className="blog-text">
                        <div className="post-option">
                          <span className="post-date">Motors</span>
                        </div>
                        <div className="post-title">
                          <h4>
                            <a href="#">
                              Speed Dual-Clutch Featured Special New ReLease
                            </a>
                          </h4>
                        </div>
                        <p>
                          Website named Widerøe generated a tremendous amount of
                          buzz and a lot of very happy customers this weekend
                          when word got out that supposed.
                        </p>
                        <div className="post-meta">
                          <figure>
                            <img
                              src="assets/extra-images/blog-grid-2.jpg"
                              alt="#"
                            />
                          </figure>
                          <span className="post-by">Arashasghari</span>
                          <em>September 30, 2023</em>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="cs-blog-listing blog-grid">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/blog-listing-3.jpg"
                              alt="#"
                            />
                          </a>
                          <figcaption></figcaption>
                        </figure>
                      </div>
                      <div className="blog-text">
                        <div className="post-option">
                          <span className="post-date">Cars and Races</span>
                        </div>
                        <div className="post-title">
                          <h4>
                            <a href="#">
                              Human laws, but we cannot resist natural ones
                            </a>
                          </h4>
                        </div>
                        <p>
                          Amount of buzz and a lot of very happy customers this
                          weekend when word got out that supposed unintentional
                          error in their reservation.
                        </p>
                        <div className="post-meta">
                          <figure>
                            <img
                              src="assets/extra-images/blog-grid-3.jpg"
                              alt="#"
                            />
                          </figure>
                          <span className="post-by">Darrellwhitelaw</span>
                          <em>October 9, 2023</em>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-seprater" style={{ textAlign: "center" }}>
                  <span>
                    {" "}
                    <i className="icon-transport177"> </i>{" "}
                  </span>
                </div>
                {/* <!--Element Section End--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="section-title"
                style={{
                  textAlign: "center",
                  color: "#333333",
                  marginBottom: "45px",
                }}
              >
                <h3 style={{ marginBottom: "5px" }}>WHY CHOOSE US</h3>
                <p>
                  It will help us find the Toyota you're looking for in your
                  area.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-fuel1 cs-color"
                    style={{ fontSize: "70px" }}
                  >
                    {" "}
                  </i>
                </div>
                <div className="cs-text">
                  <h6>OIL CHANGES</h6>
                  <p>
                    Vivamus erat diam, condimentum in aliquet sed, bibendum at
                    quam. Phasellus lacus mauris.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-car230 cs-color"
                    style={{ fontSize: "40px" }}
                  ></i>
                </div>
                <div className="cs-text">
                  <h6>AIR CONDITIONING</h6>
                  <p>
                    Nulla a gravida purus. Fusce at interdum libero, at. Vivamus
                    erat diam, condimentum in bibendum at quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-energy42 cs-color"
                    style={{ fontSize: "40px" }}
                  ></i>
                </div>
                <div className="cs-text">
                  <h6>AUTO ELECTRIC</h6>
                  <p>
                    Etiam et maximus nisi, nec scelerisque neque. Nunc euismod
                    iaculis urna, id eleifend lorem aliquet at.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-car36 cs-color"
                    style={{ fontSize: "40px" }}
                  >
                    {" "}
                  </i>
                </div>
                <div className="cs-text">
                  <h6>BRAKE SERVICE</h6>
                  <p>
                    Vivamus erat diam, condimentum in aliquet sed, bibendum at
                    quam. Phasellus lacus mauris.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-car228 cs-color"
                    style={{ fontSize: "40px" }}
                  ></i>
                </div>
                <div className="cs-text">
                  <h6>TRANSMISSION</h6>
                  <p>
                    Nulla a gravida purus. Fusce at interdum libero, at. Vivamus
                    erat diam, condimentum in bibendum at quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="cs-services">
                <div className="cs-media">
                  <i
                    className="icon-transport177 cs-color"
                    style={{ fontSize: "40px" }}
                  ></i>
                </div>
                <div className="cs-text">
                  <h6>TIRE &amp; WHEEL SERVICE</h6>
                  <p>
                    Etiam et maximus nisi, nec scelerisque neque. Nunc euismod
                    iaculis urna, id eleifend lorem aliquet at.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 25px" }}
              >
                <div className="cs-media">
                  <figure>
                    <a href="#">
                      <img alt="#" src="assets/extra-images/cs-ad-img.jpg" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
