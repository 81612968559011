import React from 'react';

const ShopDetail = () => {
  return (
    <div className="main-section ">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="section-fullwidth col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="row">
                <div className="page-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                  <div className="site-main">
                    <div className=" product type-product has-post-thumbnail product-type-simple">
                      <div className="images">
                        <a href="#">
                          <img
                            src="assets/extra-images/shop-detail1.jpg"
                            alt="shop"
                          />
                        </a>
                        <div class="thumbnails columns-4">
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-thumb1.jpg"
                              alt="shop"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-thumb2.jpg"
                              alt="shop"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-thumb3.jpg"
                              alt="shop"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-thumb4.jpg"
                              alt="shop"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="summary entry-summary">
                        <div className="star-rating" title="Rated 5 out of 5">
                          <span style={{ width: '66%' }}>
                            <strong className="rating">5</strong> out of 5
                          </span>
                        </div>
                        <em>(1 customer review)</em>
                        <span className="stock_wrapper">
                          Availability:{' '}
                          <span className="stock cs-color">In stock</span>
                        </span>
                        <h3>Automotive Door</h3>
                        <span className="price">
                          <ins>
                            <span className="amount cs-color">$99.00</span>
                          </ins>
                          <del>
                            <span className="amount">$150.00</span>
                          </del>
                        </span>
                        <div className="description">
                          <p>
                            Phasellus egestas, nunc non consectetur hendrerit,
                            risus mauris cursus velit, et condimentum nisi enim
                            in eros.Nam ullamcorper neque non erat elementum
                            vulputate.{' '}
                          </p>
                        </div>
                        <form
                          id="myform"
                          method="POST"
                          action="#"
                          className="cart"
                        >
                          <div className="quantity">
                            <span className="qtyminus">-</span>
                            <input type="text" value="1" className="qty" />
                            <span className="qtyplus">+</span>
                          </div>
                          <button
                            className="single_add_to_cart_button button alt cs-bgcolor"
                            type="submit"
                          >
                            Add to cart
                          </button>
                        </form>
                        <div className="product_meta">
                          <span className="posted_in">
                            Category:
                            <a rel="tag" href="#">
                              Oil &amp; Filters
                            </a>
                            ,
                            <a rel="tag" href="#">
                              Parts
                            </a>
                            ,
                            <a rel="tag" href="#">
                              Exterior
                            </a>
                          </span>
                          <span className="posted_in">
                            tags:
                            <a rel="tag" href="#">
                              Hybrid
                            </a>
                            ,
                            <a rel="tag" href="#">
                              Radiator
                            </a>
                            ,
                            <a rel="tag" href="#">
                              Wheel Hub
                            </a>
                            ,
                            <a rel="tag" href="#">
                              BMW
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="woocommerce-tabs wc-tabs-wrapper">
                        <ul className="nav nav-tabs wc-tabs" role="tablist">
                          <li role="presentation">
                            <a
                              href="#home"
                              className="active"
                              aria-controls="home"
                              role="tab"
                              data-bs-toggle="tab"
                            >
                              Description
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#profile"
                              aria-controls="profile"
                              role="tab"
                              data-bs-toggle="tab"
                            >
                              Additional Information
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#messages"
                              aria-controls="messages"
                              role="tab"
                              data-bs-toggle="tab"
                            >
                              Reviews
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content">
                          <div
                            role="tabpanel"
                            className="tab-pane active"
                            id="home"
                          >
                            <h5>Product Description</h5>
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by injected humour, or
                              randomised words which don’t look even slightly
                              believable.
                            </p>
                            <p>
                              Sed ut perspiciatis unde omnis iste natus error
                              sit voluptatem accusantium doloremque laudantium,
                              totam rem aperiam, eaque ipsa quae ab illo
                              inventore veritatis et quasi architecto beatae
                              vitae dicta sunt explicabo.Nemo enim ipsam
                              voluptatem quia voluptas sit aspernatur aut odit
                              aut fugit, sed quia consequuntur magni dolores eos
                              qui ratione voluptatem sequi nesciunt.
                            </p>
                            <ul className="list-style">
                              <li>
                                <i className="icon-check3"></i>That thus hey
                                porpoise uncritical gosh and much and this
                                haughtily
                              </li>
                              <li>
                                <i className="icon-check3"></i>Squid darn in
                                sheepish yet the slapped mildly and
                                adventurously
                              </li>
                              <li>
                                <i className="icon-check3"></i>sincere less
                                dalmatian assentingly wherever left ethereal the
                              </li>
                              <li>
                                <i className="icon-check3"></i>Groggily some
                                vexedly broadcast sheepish yet the
                              </li>
                              <li>
                                <i className="icon-check3"></i>Adventurously.
                                That sneered vivaciously that thus hey porpoise.
                              </li>
                            </ul>
                          </div>
                          <div
                            role="tabpanel"
                            className="tab-pane fade"
                            id="profile"
                          >
                            <h5>Product Description</h5>
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by injected humour, or
                              randomised words which don’t look even slightly
                              believable.
                            </p>
                            <p>
                              Sed ut perspiciatis unde omnis iste natus error
                              sit voluptatem accusantium doloremque laudantium,
                              totam rem aperiam, eaque ipsa quae ab illo
                              inventore veritatis et quasi architecto beatae
                              vitae dicta sunt explicabo.Nemo enim ipsam
                              voluptatem quia voluptas sit aspernatur aut odit
                              aut fugit, sed quia consequuntur magni dolores eos
                              qui ratione voluptatem sequi nesciunt.
                            </p>
                            <ul className="list-style">
                              <li>
                                <i className="icon-check3"></i>That thus hey
                                porpoise uncritical gosh and much and this
                                haughtily
                              </li>
                              <li>
                                <i className="icon-check3"></i>Squid darn in
                                sheepish yet the slapped mildly and
                                adventurously
                              </li>
                              <li>
                                <i className="icon-check3"></i>sincere less
                                dalmatian assentingly wherever left ethereal the
                              </li>
                              <li>
                                <i className="icon-check3"></i>Groggily some
                                vexedly broadcast sheepish yet the
                              </li>
                              <li>
                                <i className="icon-check3"></i>Adventurously.
                                That sneered vivaciously that thus hey porpoise.
                              </li>
                            </ul>
                          </div>
                          <div
                            role="tabpanel"
                            className="tab-pane fade"
                            id="messages"
                          >
                            <div id="reviews">
                              <div id="comments">
                                <h5>Product Reviews</h5>
                                <ol className="commentlist">
                                  <li className="comment even thread-even depth-1">
                                    <div className="comment_container">
                                      <img
                                        src="assets/extra-images/Shop-comment1.jpg"
                                        alt="#"
                                      />
                                      <div className="comment-text">
                                        <p className="meta">
                                          <em>James Warson</em>
                                          September 6, 2023, 05:32PM
                                        </p>
                                        <div className="star-rating">
                                          <span style={{ width: '60%' }}>
                                            <strong>3</strong> out of 5
                                          </span>
                                        </div>
                                        <div className="description">
                                          <p>
                                            {' '}
                                            Sed id magna tellus. Ut eget dictum
                                            magna. Proin pharetra, elit nec
                                            accumsan posuere, massa tellus
                                            lobortis mi, a viverra nisi metus et
                                            risus. Praesent efficitur neque
                                            nibh, non maximus ante suscipit non.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="comment even thread-even depth-1">
                                    <div className="comment_container">
                                      <img
                                        src="assets/extra-images/Shop-comment2.jpg"
                                        alt="#"
                                      />
                                      <div className="comment-text">
                                        <p className="meta">
                                          <em>Brian Adamas</em>
                                          September 6, 2023, 05:32PM
                                        </p>
                                        <div className="star-rating">
                                          <span style={{ width: '60%' }}>
                                            <strong>3</strong> out of 5
                                          </span>
                                        </div>
                                        <div className="description">
                                          <p>
                                            {' '}
                                            Sed id magna tellus. Ut eget dictum
                                            magna. Proin pharetra, elit nec
                                            accumsan posuere, massa tellus
                                            lobortis mi, a viverra nisi metus et
                                            risus. Praesent efficitur neque
                                            nibh, non maximus ante suscipit non.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ol>
                              </div>
                              <div id="review_form_wrapper">
                                <div id="review_form">
                                  <div className="comment-respond" id="respond">
                                    <h5
                                      className="comment-reply-title"
                                      id="reply-title"
                                    >
                                      Add a Review{' '}
                                      <small>
                                        <a
                                          style={{ display: 'none' }}
                                          href="#"
                                          id="cancel-comment-reply-link"
                                          rel="nofollow"
                                        >
                                          Cancel reply
                                        </a>
                                      </small>
                                    </h5>
                                    <form
                                      novalidate
                                      className="comment-form"
                                      id="commentform"
                                      method="post"
                                      action="http://motors.stylemixthemes.com/wp-comments-post.php"
                                    >
                                      <div className="comment-form-rating">
                                        <p className="stars">
                                          <span>
                                            <a href="#" className="star-1">
                                              1
                                            </a>
                                            <a href="#" className="star-2">
                                              2
                                            </a>
                                            <a href="#" className="star-3">
                                              3
                                            </a>
                                            <a href="#" className="star-4">
                                              4
                                            </a>
                                            <a href="#" className="star-5">
                                              5
                                            </a>
                                          </span>
                                        </p>
                                        <select
                                          id="rating"
                                          name="rating"
                                          style={{ display: 'none' }}
                                          tabIndex="-1"
                                          className="select2-hidden-accessible"
                                          aria-hidden="true"
                                        >
                                          <option value="">Rate…</option>
                                          <option value="5">Perfect</option>
                                          <option value="4">Good</option>
                                          <option value="3">Average</option>
                                          <option value="2">
                                            Not that bad
                                          </option>
                                          <option value="1">Very Poor</option>
                                        </select>
                                        <span
                                          className="select2 select2-container select2-container--default"
                                          dir="ltr"
                                          style={{ width: '100%' }}
                                        >
                                          <span className="selection">
                                            <span
                                              aria-expanded="false"
                                              aria-haspopup="true"
                                              aria-autocomplete="list"
                                              role="combobox"
                                              className="select2-selection select2-selection--single"
                                              tabindex="0"
                                              aria-labelledby="select2-rating-container"
                                            >
                                              <span
                                                className="select2-selection__rendered"
                                                id="select2-rating-container"
                                                title="Rate…"
                                              >
                                                Give Rating
                                              </span>
                                              <span
                                                role="presentation"
                                                className="select2-selection__arrow"
                                              >
                                                <b role="presentation"></b>
                                              </span>
                                            </span>
                                          </span>
                                          <span
                                            aria-hidden="true"
                                            className="dropdown-wrapper"
                                          ></span>
                                        </span>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                          <p className="comment-form-author">
                                            <input
                                              type="text"
                                              placeholder="Name"
                                            />
                                          </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                          <p className="comment-form-author">
                                            <input
                                              type="text"
                                              placeholder="Phone Number"
                                            />
                                          </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                          <p className="comment-form-author">
                                            <input
                                              type="text"
                                              placeholder="Subject"
                                            />
                                          </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                          <p className="comment-form-email">
                                            <input
                                              type="text"
                                              placeholder="Email Address"
                                            />
                                          </p>
                                        </div>
                                      </div>
                                      <p className="comment-form-comment">
                                        <textarea placeholder="Your Review"></textarea>
                                      </p>
                                      <p className="form-submit">
                                        <input
                                          type="submit"
                                          value="Submit Message"
                                          className="submit cs-bgcolor"
                                        />
                                        <input type="hidden" />
                                        <input type="hidden" />
                                      </p>
                                      <p style={{ display: 'none' }}>
                                        <input type="hidden" />
                                      </p>
                                      <p
                                        style={{ display: 'none' }}
                                        className="stm-hidden"
                                      ></p>
                                      <input type="hidden" />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="columns-3">
                        <div className="shop-sec-title">
                          <h3>Related Products</h3>
                        </div>
                        <ul className="products">
                          <li className="product">
                            <a href="#">
                              <img
                                alt="shop"
                                src="assets/extra-images/shop4.jpg"
                              />
                              <h5>Air Filter</h5>
                              <span className="price">
                                <span className="amount">$2,000.00</span>
                              </span>
                              <div
                                className="star-rating"
                                title="Rated 5 out of 5"
                              >
                                <span style={{ width: '66%' }}>
                                  <strong className="rating">5</strong> out of 5
                                </span>
                              </div>
                            </a>
                            <div className="product-action-button">
                              <a
                                className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                href="#"
                              >
                                <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                Add to cart
                              </a>
                            </div>
                          </li>
                          <li className="product">
                            <a href="#">
                              <img
                                alt="shop"
                                src="assets/extra-images/shop5.jpg"
                              />
                              <h5>Car brush</h5>
                              <span className="price">
                                <span className="amount">$2,000.00</span>
                              </span>
                              <div
                                className="star-rating"
                                title="Rated 5 out of 5"
                              >
                                <span style={{ width: '66%' }}>
                                  <strong className="rating">5</strong> out of 5
                                </span>
                              </div>
                            </a>
                            <div className="product-action-button">
                              <a
                                className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                href="#"
                              >
                                <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                Add to cart
                              </a>
                            </div>
                          </li>
                          <li className="product">
                            <a href="#">
                              <img
                                alt="shop"
                                src="assets/extra-images/shop6.jpg"
                              />
                              <h5>Car mats for BMW F10</h5>
                              <span className="price">
                                <ins>
                                  <span className="amount">$99.00</span>
                                </ins>
                                <del>
                                  <span className="amount">$150.00</span>
                                </del>
                              </span>
                              <div
                                className="star-rating"
                                title="Rated 5 out of 5"
                              >
                                <span style={{ width: '66%' }}>
                                  <strong className="rating">5</strong> out of 5
                                </span>
                              </div>
                            </a>
                            <div className="product-action-button">
                              <a
                                className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                href="#"
                              >
                                <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                Add to cart
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <aside className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="widget woocommerce widget_product_categories">
                    <h6>Product Categories</h6>
                    <ul>
                      <li className="cat-item cat-item-3 cat-parent">
                        <a href="#">
                          Clothes &amp; Footwear <span>(17)</span>
                        </a>
                        <ul className="children">
                          <li>
                            <a href="#">
                              Footwear <span>(3)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              Hoodies <span>(7)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              T-shirts <span>(7)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              Digital goods <span>(3)</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="widget woocommerce widget_top_rated_products">
                    <h6>latest Products</h6>
                    <ul className="product_list_widget">
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget1.jpg"
                            alt="shop"
                          />
                          <span className="product-title">
                            USB Air Compressor
                          </span>
                        </a>
                        <span className="amount cs-color">$500.00</span>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget2.jpg"
                            alt="shop"
                          />
                          <span className="product-title">Ninja Sound</span>
                        </a>
                        <span className="amount cs-color">$800.00</span>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget3.jpg"
                            alt="shop"
                          />
                          <span className="product-title">
                            Car mats for BMW
                          </span>
                        </a>
                        <span className="amount cs-color">$900.00</span>
                      </li>
                    </ul>
                  </div>
                  <div className="widget woocommerce widget_shopping_cart">
                    <h6>Top Rated Products</h6>
                    <div className="widget_shopping_cart_content">
                      <ul className="cart_list product_list_widget">
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-widget4.jpg"
                              alt="shop"
                            />
                            Brembo Sport Brakes
                          </a>
                          <span className="amount">$500.00</span>
                          <div title="Rated 5 out of 5" className="star-rating">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-widget5.jpg"
                              alt="shop"
                            />
                            Custom spark-plug
                          </a>
                          <span className="amount">$800.00</span>
                          <div title="Rated 5 out of 5" className="star-rating">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              src="assets/extra-images/Shop-widget6.jpg"
                              alt="shop"
                            />
                            Air Filter
                          </a>
                          <span className="amount">$900.00</span>
                          <div title="Rated 5 out of 5" className="star-rating">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget widget-tags woocommerce">
                    <h6>PRODUCTS TAG</h6>
                    <a href="#">Houses</a>
                    <a href="#">Apartment</a>
                    <a href="#">Rates</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Rates</a>
                    <a href="#">Flats</a>
                    <a href="#">People</a>
                    <a href="#">United Kingdom</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Flats</a>
                    <a href="#">Houses</a>
                    <a href="#">Apartment</a>
                    <a href="#">Rates</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Road</a>
                    <a href="#">Flats</a>
                    <a href="#">People</a>
                    <a href="#">United Kingdom</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: '#19171a' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: 'center', padding: '55px 0 25px' }}
              >
                <div className="cs-media">
                  <figure>
                    <a href="#">
                      <img alt="#" src="assets/extra-images/cs-ad-img.jpg" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetail;
