import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" style={{ background: '#19171a', padding: '32px 0 0' }}>
      <div className="cs-footer-widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="widget widget-our-partners">
                <div className="widget-section-title">
                  <h6 style={{ color: '#fff !important' }}>Our Partners</h6>
                </div>
                <ul>
                  <li>
                    <a href="#">Apartments.com</a>
                  </li>
                  <li>
                    <a href="#">CareerBuilder.com</a>
                  </li>
                  <li>
                    <a href="#">Cars.com</a>
                  </li>
                  <li>
                    <a href="#">HomeFinder.com</a>
                  </li>
                  <li>
                    <a href="#">Detroit Newspapers in Education</a>
                  </li>
                  <li>
                    <a href="#">Michigan.com</a>
                  </li>
                  <li>
                    <a href="#">MovingCompanyReviews.com</a>
                  </li>
                  <li>
                    <a href="#">Find & Save</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="widget widget-categores">
                <div className="widget-section-title">
                  <h6 style={{ color: '#fff !important' }}>Top Categores</h6>
                </div>
                <ul>
                  <li>
                    <a href="#">All Toyota Vehicles</a>
                  </li>
                  <li>
                    <a href="#">SUVs</a>
                  </li>
                  <li>
                    <a href="#">Trucks</a>
                  </li>
                  <li>
                    <a href="#">Cars</a>
                  </li>
                  <li>
                    <a href="#">Crossovers</a>
                  </li>
                  <li>
                    <a href="#">Hybrids</a>
                  </li>
                  <li>
                    <a href="#">Hybrid Cars</a>
                  </li>
                  <li>
                    <a href="#">Hybrid SUVs</a>
                  </li>
                  <li>
                    <a href="#">Concept Vehicles</a>
                  </li>
                  <li>
                    <a href="#">TRD Pro Series</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="widget widget-about-us">
                <div className="widget-section-title">
                  <h6 style={{ color: '#fff !important' }}>About Us</h6>
                </div>
                <ul>
                  <li>
                    <a href="#">Shopping Tools</a>
                  </li>
                  <li>
                    <a href="#">Trade-In Value</a>
                  </li>
                  <li>
                    <a href="#">Customize Your Own Toyota</a>
                  </li>
                  <li>
                    <a href="#">Search Inventory</a>
                  </li>
                  <li>
                    <a href="#">Find a Dealer</a>
                  </li>
                  <li>
                    <a href="#">Local Specials</a>
                  </li>
                  <li>
                    <a href="#">Request a Quote</a>
                  </li>
                  <li>
                    <a href="#">Accessories</a>
                  </li>
                  <li>
                    <a href="#">Find Your Match</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="widget widget-news-letter">
                <div className="widget-section-title">
                  <h6 style={{ color: '#fff !important' }}>
                    SIGN UP FOR TOYOTA UPDATES
                  </h6>
                </div>
                <p>
                  Recently received some brochures from my traders here are some
                  that might be of interest.
                </p>
                <div className="cs-form">
                  <form>
                    <div className="input-holder">
                      <input
                        type="email"
                        placeholder="Enter Valid Email Address"
                      />
                      <label>
                        <i className="icon-send2"></i>
                        <input
                          className="cs-bgcolor"
                          type="submit"
                          value="submit"
                        />
                      </label>
                    </div>
                  </form>
                </div>
                <div className="cs-social-media">
                  <ul>
                    <li>
                      <a href="#" data-original-title="facebook">
                        <i className="icon-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" data-original-title="twitter">
                        <i className="icon-twitter4"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" data-original-title="linkedin">
                        <i className="icon-linkedin22"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" data-original-title="rss">
                        <i className="icon-rss"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" data-original-title="vimeo">
                        <i className="icon-vimeo"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cs-copyright"
        style={{
          background: '#141215',
          paddingTop: '37px',
          paddingBottom: '37px',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="copyright-text">
                <p>
                  Powered by
                  <a href="#" className="cs-color">
                    ChimpStudio.
                  </a>{' '}
                  Built with Automobile
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="cs-back-to-top">
                <address>
                  <i className="icon-phone"></i>
                  <a href="#">+0 (123) 456-789-10</a>
                </address>
                <a className="btn-to-top cs-bgcolor" href="#">
                  <i className="icon-keyboard_arrow_up"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
