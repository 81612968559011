import React from "react";
import UserNavbar from "../../../components/user-navbar";

const UserProfilePage = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "url(assets/extra-images/user-bg-img.jpg) no-repeat",
          backgroundSize: "cover",
          minHeight: "175px",
          marginTop: "-30px",
          marginBottom: "-129px",
        }}
      ></div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-user-account-holder">
                <div className="row">
                  <UserNavbar />
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-user-section-title">
                      <h4>Genral Settings</h4>
                    </div>
                  </div>
                  <form className="user-setting">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-profile-pic row">
                        <div className="col-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-2">
                          <div className="profile-pic">
                            <div className="cs-media">
                              <figure>
                                {" "}
                                <img
                                  src="assets/extra-images/profile-pic.jpg"
                                  alt="#"
                                />{" "}
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-8 col-md-9 col-lg-10 col-xl-10 col-xxl-10">
                          <div className="cs-browse-holder">
                            {" "}
                            <em>My Profile Photo</em>{" "}
                            <span className="file-input btn-file">
                              {" "}
                              Update Avatar
                              <input type="file" multiple />
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Full Name</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Email Address</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <label>Name</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="cs-field-holder">
                                  <select
                                    data-placeholder="Select Make"
                                    tabindex="1"
                                    className="chosen-select"
                                  >
                                    <option value="United States">
                                      United States
                                    </option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                    <option value="Afghanistan">
                                      Afghanistan
                                    </option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                    <option value="Afghanistan">
                                      Afghanistan
                                    </option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <label>Date of Birth</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <select
                                  data-placeholder="Select Make"
                                  tabindex="1"
                                  className="chosen-select"
                                >
                                  <option value="United States">01</option>
                                  <option value="United Kingdom">02</option>
                                  <option value="Afghanistan">03</option>
                                  <option value="Afghanistan">04</option>
                                  <option value="Afghanistan">05</option>
                                  <option value="Afghanistan">06</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Country</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <select
                          data-placeholder="Select Make"
                          tabindex="1"
                          className="chosen-select"
                        >
                          <option value="United States">United States</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="United Kingdom">United Kingdom</option>
                        </select>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>City</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <select
                          data-placeholder="Select Make"
                          tabindex="1"
                          className="chosen-select"
                        >
                          <option value="United States">United States</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="United Kingdom">United Kingdom</option>
                        </select>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>About us</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <textarea></textarea>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="cs-switch-holder">
                          <label for="someSwitchOptionDefault">
                            Contact Form
                          </label>
                          <div className="material-switch">
                            <input
                              id="someSwitchOptionDefault"
                              name="someSwitchOption001"
                              type="checkbox"
                            />
                            <label
                              for="someSwitchOptionDefault"
                              className="label-default"
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="cs-switch-holder">
                          <label for="someSwitchOptionPrimary">
                            Account Activation
                          </label>
                          <div className="material-switch">
                            <input
                              id="someSwitchOptionPrimary"
                              name="someSwitchOption001"
                              type="checkbox"
                            />
                            <label
                              for="someSwitchOptionPrimary"
                              className="label-default"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Contact Information</h6>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Mobile Number</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Phone number</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Emaill Address</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Update password</h6>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Password</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Confirm password</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <input type="text" placeholder="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Social icons</h6>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-field">
                          <a href="#" data-original-title="Facebook">
                            Facebook<i className="icon-facebook2"></i>
                          </a>
                        </div>
                        <div className="cs-field">
                          <a href="#" data-original-title="Twitter">
                            Twitter<i className="icon-twitter2"></i>
                          </a>
                        </div>
                        <div className="cs-field">
                          <a href="#" data-original-title="Linkedin">
                            LinkedIn<i className="icon-linkedin22"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="cs-field">
                          <div className="cs-btn-submit">
                            <input type="submit" value="Save Changes" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
