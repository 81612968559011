import React, { Fragment } from "react";

const About = () => {
  return (
    <Fragment>
      <div
        className="cs-subheader center"
        style={{
          background:
            "url(assets/extra-images/bg-subheader.jpg) no-repeat 0 0 /cover",
          minHeight: "296px",
          padding: "90px 0 0",
          marginBottom: "70px",
        }}
      >
        <div className="container">
          <div className="cs-page-title">
            <h1 style={{ color: "#fff !important" }}>About US</h1>
            <p style={{ color: "#fff" }}>
              If you think we might be a good fit for a project you’re working
              on and you’d like to get the feel of how we
              <br />
              can work together please contact us using contact data.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ marginBottom: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {" "}
                    <i
                      className="icon-fuel1 cs-color"
                      style={{ fontSize: "70px" }}
                    >
                      {" "}
                    </i>{" "}
                  </div>
                  <div className="cs-text">
                    <h6>OIL CHANGES </h6>
                    <p>
                      Vivamus erat diam, condimentum in aliquet sed, bibendum at
                      quam. Phasellus lacus mauris.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {" "}
                    <i
                      className="icon-car230 cs-color"
                      style={{ fontSize: "40px" }}
                    >
                      {" "}
                    </i>{" "}
                  </div>
                  <div className="cs-text">
                    <h6>AIR CONDITIONING</h6>
                    <p>
                      Nulla a gravida purus. Fusce at interdum libero, at.
                      Vivamus erat diam, condimentum in bibendum at quam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {" "}
                    <i
                      className="icon-energy42 cs-color"
                      style={{ fontSize: "40px" }}
                    >
                      {" "}
                    </i>{" "}
                  </div>
                  <div className="cs-text">
                    <h6>AUTO ELECTRIC </h6>
                    <p>
                      Etiam et maximus nisi, nec scelerisque neque. Nunc euismod
                      iaculis urna, id eleifend lorem aliquet at.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "40px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div className="cs-column-text">
                  <h2 style={{ fontSize: "26px !important" }}>
                    WELCOME TO THE AutoMotive
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur corporis lab.
                    Architecto, maiores, similique, eos, ea doloribus nihil
                    accusantium fuga numquam corporis nostrum eligendi nemo
                    sapiente quibusdam consequuntur tempore. Suscipit.
                  </p>
                  <ul className="cs-icon-list">
                    <li>
                      <i className="icon-check2 cs-color"></i>Volutpat luctus
                      lacus hendrerit taciti lobortis in.
                    </li>
                    <li>
                      <i className="icon-check2 cs-color"></i>Faucibus amet
                      consectetur erat donec venenatis leo.
                    </li>
                    <li>
                      <i className="icon-check2 cs-color"></i>Ullamcorper
                      conubia, aenean quisque mattis malesuada.
                    </li>
                    <li>
                      <i className="icon-check2 cs-color"></i>quam quisque
                      ligula, maecenas ligula proin ut augue.
                    </li>
                  </ul>
                  <a href="#" className="btn-contact cs-color csborder-color">
                    Contact us
                  </a>{" "}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div className="cs-video-frame">
                  <iframe
                    width="560"
                    height="340"
                    src="https://www.youtube.com/embed/21CY9RtMQkU"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "70px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="section-title"
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: "45px",
                  }}
                >
                  <h2
                    style={{
                      marginBottom: "5px",
                      textTransform: "uppercase !important",
                    }}
                  >
                    Meet Our Team
                  </h2>
                  <p>
                    Goodness far this grimaced more domestically overrode bawled
                    one much hello macaw spurious.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-team">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img src="assets/extra-images/team-img-1.jpg" alt="#" />
                      </a>
                    </figure>
                    <div className="cs-caption">
                      {" "}
                      <i className="icon-bars cs-top-icon"></i>
                      <ul>
                        <li>
                          <a href="#">
                            <i className="icon-facebook2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-twitter2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-linkedin4"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-text">
                    <h6>
                      <a href="#">Lennox Wardell</a>
                    </h6>
                    <span>Chief Executive Officer </span>{" "}
                    <em>
                      <i className="icon-phone4"></i>001-234-567-XXXX
                    </em>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-team">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img src="assets/extra-images/team-img-2.jpg" alt="#" />
                      </a>
                    </figure>
                    <div className="cs-caption">
                      {" "}
                      <i className="icon-bars cs-top-icon"></i>
                      <ul>
                        <li>
                          <a href="#">
                            <i className="icon-facebook2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-twitter2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-linkedin4"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-text">
                    <h6>
                      <a href="#">Sarah Odegard </a>
                    </h6>
                    <span>President of Finance </span>{" "}
                    <em>
                      <i className="icon-phone4"></i>001-234-567-XXXX
                    </em>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-team">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img src="assets/extra-images/team-img-3.jpg" alt="#" />
                      </a>
                    </figure>
                    <div className="cs-caption">
                      {" "}
                      <i className="icon-bars cs-top-icon"></i>
                      <ul>
                        <li>
                          <a href="#">
                            <i className="icon-facebook2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-twitter2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-linkedin4"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-text">
                    <h6>
                      <a href="#">Lars Jakuba </a>
                    </h6>
                    <span>Executive Vice President </span>{" "}
                    <em>
                      <i className="icon-phone4"></i>001-234-567-XXXX
                    </em>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-team">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img src="assets/extra-images/team-img-4.jpg" alt="#" />
                      </a>
                    </figure>
                    <div className="cs-caption">
                      {" "}
                      <i className="icon-bars cs-top-icon"></i>
                      <ul>
                        <li>
                          <a href="#">
                            <i className="icon-facebook2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-twitter2"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-linkedin4"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-text">
                    <h6>
                      <a href="#">Mikey Diokles </a>
                    </h6>
                    <span>Sales Manager </span>{" "}
                    <em>
                      <i className="icon-phone4"></i>001-234-567-XXXX
                    </em>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{
            background:
              "url(assets/extra-images/bg-counter.jpg) no-repeat 0 0 / cover",
            marginBottom: "50px",
            marginTop: "20px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <i className="icon-truck2 cs-color"> </i>
                    </figure>
                  </div>
                  <div className="cs-text" style={{ color: "#fff" }}>
                    {" "}
                    <strong className="counter">3,863</strong>{" "}
                    <span>Car Sold</span>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <i className="icon-car36  cs-color"> </i>{" "}
                    </figure>
                  </div>
                  <div className="cs-text" style={{ color: "#fff" }}>
                    {" "}
                    <strong className="counter">89,897</strong>{" "}
                    <span>Car Sold</span>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <i className="icon-settings  cs-color"> </i>{" "}
                    </figure>
                  </div>
                  <div className="cs-text" style={{ color: "#fff" }}>
                    {" "}
                    <strong className="counter">741</strong>{" "}
                    <span>Registerd Agents</span>{" "}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <i className="icon-directions_car cs-color"> </i>{" "}
                    </figure>
                  </div>
                  <div className="cs-text" style={{ color: "#fff" }}>
                    {" "}
                    <strong className="counter">3289</strong>{" "}
                    <span>Certified Agents</span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "70px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div
                      className="section-title"
                      style={{ color: "#333333", marginBottom: "45px" }}
                    >
                      <h2
                        style={{
                          marginBottom: "5px",
                          textTransform: "uppercase !important",
                        }}
                      >
                        OUR ADVANTAGES
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="panel-group box" id="accordion5">
                      <div className="panel panel-default">
                        <div className="panel-heading" id="headingOne">
                          <h6 className="panel-title">
                            {" "}
                            <a
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse30"
                              aria-expanded="true"
                              aria-controls="collapse30"
                            >
                              {" "}
                              <i className="icon-directions_car"></i>We go
                              through extensive factory training so that we may
                              provide{" "}
                            </a>{" "}
                          </h6>
                        </div>
                        <div
                          id="collapse30"
                          className="panel-collapse collapse show"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            {" "}
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading" id="heading31">
                          <h6 className="panel-title">
                            {" "}
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse31"
                              aria-expanded="false"
                              aria-controls="collapse31"
                            >
                              {" "}
                              <i className="icon-key22"></i>You need to make an
                              educated decision in choosing the vehicle{" "}
                            </a>{" "}
                          </h6>
                        </div>
                        <div
                          id="collapse31"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            {" "}
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading" id="heading32">
                          <h6 className="panel-title">
                            {" "}
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse32"
                              aria-expanded="false"
                              aria-controls="collapse32"
                            >
                              {" "}
                              <i className="icon-cog2"></i>Motors, we think you
                              should also have pretty high expectations for your
                              dealership.{" "}
                            </a>{" "}
                          </h6>
                        </div>
                        <div
                          id="collapse32"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            {" "}
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <ul className="cs-testimonial-slider">
                  <li>
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />{" "}
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        {" "}
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.{" "}
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />{" "}
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        {" "}
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.{" "}
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />{" "}
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        {" "}
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.{" "}
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />{" "}
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        {" "}
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.{" "}
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />{" "}
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        {" "}
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.{" "}
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "70px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="section-title"
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: "45px",
                  }}
                >
                  <h2 style={{ marginBottom: "5px" }}>MEDIA GALLERY</h2>
                  <p>
                    It will help us find the Toyota you're looking for in your
                    area.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery imglist">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-1.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-1./jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-2.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-2.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-3.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-3.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-4.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-4.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-5.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-5.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-6.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-6.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-7.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-7.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-gallery">
                  <div className="cs-media">
                    <figure>
                      <a
                        href="assets/extra-images/gallery-img-8.jpg"
                        data-fancybox="preview"
                        data-width="1500"
                        data-height="1000"
                      >
                        <img
                          src="assets/extra-images/gallery-img-8.jpg"
                          alt="#"
                        />
                        <div className="figcaption">
                          <i className="icon-search2"></i>
                          <span>People will get clean water</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{
            marginBottom: "0",
            background: "#edf0f5",
            padding: "35px 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <ul className="cs-partners-slider">
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-1.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-2.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-3.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-4.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-5.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-6.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-1.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-2.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-3.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-4.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-5.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="assets/extra-images/partner-img-6.jpg"
                        alt="partner"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
