import React, { Fragment } from "react";

const InventoryDetailPage = () => {
  return (
    <Fragment>
      {/* BANNNER COMMENTED FOR NOW  */}
      {/* <div className="cs-banner loader">
        <ul className="cs-banner-slider">
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-1.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-2.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-3.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-1.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-2.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-3.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-1.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-2.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
          <li>
            <div className="cs-media">
              <figure>
                <img
                  data-echo="assets/extra-images/single-banner-img-3.jpg"
                  src="assets/images/blank.gif"
                  alt="#"
                />
              </figure>
            </div>
          </li>
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right">
              <div className="cs-button-style">
                <a className="btn-video" href="#">
                  <i className="icon-play_arrow"></i> Watch video
                </a>
                <a className="btn-compare" href="#">
                  <i className="icon-flow-tree"></i> Compare
                </a>
                <a className="btn-shortlist" href="#">
                  <i className="icon-heart-o"></i> shortlist
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Single - Page Slider End -->
	<!-- Main Start --> */}
      <div className="main-section">
        <div
          className="page-section"
          style={{
            boxShadow: " 0 2px 3px -2px rgba(0,0,0,0.4)",
            position: "relative",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="custom-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <div className="page-section">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="car-detail-heading">
                        <div className="auto-text">
                          <h2>Alfa Romeo Launch Edition 2dr Coupe</h2>
                          <span>
                            <i className="icon-building-o"></i> Quartz Blue
                            Metallic
                          </span>
                          <address>
                            <i className="icon-location"></i>1840 E Garvey Ave
                            South West Covina, CA 91791
                          </address>
                        </div>
                        <div className="auto-price">
                          <span className="cs-color">$17,845</span>{" "}
                          <em>MSRP $32,000</em>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-detail-nav">
                        <ul>
                          <li>
                            <a className="active" href="#vehicle">
                              Vehicle overview
                            </a>
                          </li>
                          <li>
                            <a href="#specification">Technical Specification</a>
                          </li>
                          <li>
                            <a href="#accessories">Accessories</a>
                          </li>
                          <li>
                            <a href="#location">Location</a>
                          </li>
                          <li>
                            <a href="#contact">Contact Us</a>
                          </li>
                        </ul>
                        <div className="detail-btn">
                          <div className="cs-button-style">
                            <a className="btn-compare" href="#">
                              <i className="icon-flow-tree"></i> Compare
                            </a>
                            <a className="btn-shortlist" href="#">
                              <i className="icon-heart-o"></i> shortlist
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="on-scroll">
                        <div
                          id="vehicle"
                          className="auto-overview detail-content"
                        >
                          <ul className="row">
                            <li className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="cs-media">
                                <figure>
                                  <i className="icon-driving2 cs-color"></i>
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span>Year</span>
                                <strong>2002</strong>
                              </div>
                            </li>
                            <li className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="cs-media">
                                <figure>
                                  <i className="icon-vehicle92 cs-color"></i>
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span>Mileage</span>
                                <strong>121,899</strong>
                              </div>
                            </li>
                            <li className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="cs-media">
                                <figure>
                                  <i className="icon-engine cs-color"></i>
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span>Trans</span>
                                <strong>Automatic</strong>
                              </div>
                            </li>
                            <li className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="cs-media">
                                <figure>
                                  <i className="icon-gas20 cs-color"></i>
                                </figure>
                              </div>
                              <div className="auto-text">
                                <span>Fuel Type</span>
                                <strong>Hybrid</strong>
                              </div>
                            </li>
                          </ul>
                          <p className="more-text">
                            Arear view camera and lane departure warning. This
                            car has 6 airbags fitted for your protection. It has
                            front & rear power windows, central locking and 2nd
                            row split folding seats. Family life is made easy in
                            this 2023 Jaguar XF. This car has Bluetooth
                            connectivity, side curtain airbags, subwoofer,
                            trailer sway control, sports pedals and heads up
                            information display. This car has forward collision
                            alert/warning. This car comes with enough seats for
                            5. You can relax, this car also has park assist. It
                            has electric power assisted steering. It has 19"
                            alloy wheels. It has an ANCAP safety rating 4. This
                            car has starter button. Options added to vehicle
                            includes Seats - 2nd Row Electric Folding, Sunvisors
                            - Rear and Sunvisors - Rear.
                          </p>
                          <p className="more-text">
                            This Stunning Brand New Jaguar XF 2023 comes with 3
                            years / Unlimited km new car warranty. This car has
                            an ANCAP safety rating of 4 so you can be sure you
                            are driving with utmost safety new car available to
                            order.Family life is made easy in this 2023 Jaguar
                            XF. This car has Bluetooth connectivity, side
                            curtain airbags, subwoofer, trailer sway control,
                            sports pedals and heads up information display.
                          </p>
                          <p className="more-text">
                            This Stunning Brand New Jaguar XF 2023 comes with 3
                            years / Unlimited km new car warranty. This car has
                            an ANCAP safety rating of 4 so you can be sure you
                            are driving with utmost safety new car available to
                            order.Family life is made easy in this 2023 Jaguar
                            XF.
                          </p>
                          <a
                            id="load-text"
                            href=""
                            className="btn-show-more cs-color"
                          >
                            {" "}
                            + Show More
                          </a>
                        </div>
                        <div
                          id="specification"
                          className="auto-specifications detail-content"
                        >
                          <div
                            className="section-title"
                            style={{ textAlign: "left" }}
                          >
                            <h4>Technical Specifications</h4>
                          </div>
                          <ul className="row">
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="element-title">
                                <i className="cs-color icon-engine"></i>
                                <span>Engine and Drive Train</span>
                              </div>
                            </li>
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="specifications-info">
                                <ul>
                                  <li>
                                    <span>Number of cylinders</span>
                                    <strong>825 KG</strong>
                                  </li>
                                  <li>
                                    <span>Displacement</span>
                                    <strong>KM/L</strong>
                                  </li>
                                  <li>
                                    <span>Drive layout</span>
                                    <strong>4 doors</strong>
                                  </li>
                                  <li>
                                    <span>Horespower</span>
                                    <strong>1670 mm</strong>
                                  </li>
                                  <li>
                                    <span>@ rpm</span>
                                    <strong>3600 mm</strong>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="specifications-info">
                                <ul>
                                  <li>
                                    <span>Weight</span>
                                    <strong>Est. 42 mpg</strong>
                                  </li>
                                  <li>
                                    <span>Mileage</span>
                                    <strong>ECO driving mode</strong>
                                  </li>
                                  <li>
                                    <span>No of Doors</span>
                                    <strong>Standard Bluetooth®</strong>
                                  </li>
                                  <li>
                                    <span>Height</span>
                                    <strong>Backup camera</strong>
                                  </li>
                                  <li>
                                    <span>Length</span>
                                    <strong>Voice recognitioN</strong>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <ul className="row">
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="element-title">
                                <i className="cs-color icon-vehicle92"></i>
                                <span>Transmission</span>
                              </div>
                            </li>
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="specifications-info">
                                <ul>
                                  <li>
                                    <span>Weiaght</span>
                                    <strong>Sport gauge cluster</strong>
                                  </li>
                                  <li>
                                    <span>MileagE</span>
                                    <strong>Sportier driver’s position</strong>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="specifications-info">
                                <ul>
                                  <li>
                                    <span>Weiaght</span>
                                    <strong>Paddle shifters</strong>
                                  </li>
                                  <li>
                                    <span>MileagE</span>
                                    <strong>6-speed manual</strong>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          id="accessories"
                          className="cs-auto-accessories detail-content"
                        >
                          <div className="element-title">
                            <i className="cs-color icon-gear42"></i>
                            <span>Accessories & Options</span>
                          </div>
                          <ul className="row">
                            <li className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="cs-listing-icon">
                                <ul>
                                  <li className="available">
                                    <span>Automatic Stability Control</span>
                                  </li>
                                  <li className="available">
                                    <span>Tire Pressure Monitoring</span>
                                  </li>
                                  <li className="available">
                                    <span>Bluetooth</span>
                                  </li>
                                  <li className="available">
                                    <span>Onboard computer</span>
                                  </li>
                                  <li className="available">
                                    <span>Mirrors in body color</span>
                                  </li>
                                  <li className="available">
                                    <span>Outside temperature display</span>
                                  </li>
                                  <li className="available">
                                    <span>Body-color bumpers</span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="cs-listing-icon">
                                <ul>
                                  <li className="available">
                                    <span>Car Kit</span>
                                  </li>
                                  <li className="available">
                                    <span>Remote central locking</span>
                                  </li>
                                  <li className="not-available">
                                    <span>Climate control</span>
                                  </li>
                                  <li className="available">
                                    <span>Cruise Control</span>
                                  </li>
                                  <li className="not-available">
                                    <span>Roof rails</span>
                                  </li>
                                  <li className="available">
                                    <span>Power sunroof</span>
                                  </li>
                                  <li className="available">
                                    <span>Power windows front</span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                              <div className="cs-listing-icon">
                                <ul>
                                  <li className="available">
                                    <span>Headlight washers</span>
                                  </li>
                                  <li className="available">
                                    <span>Leather steering wheel</span>
                                  </li>
                                  <li className="available">
                                    <span>Alloy wheels (17)</span>
                                  </li>
                                  <li className="available">
                                    <span>Adaptive headlights</span>
                                  </li>
                                  <li className="available">
                                    <span>Center rear armrest</span>
                                  </li>
                                  <li className="available">
                                    <span>Front armrest</span>
                                  </li>
                                  <li className="available">
                                    <span>Navigation</span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          id="contact"
                          className="cs-contact-form detail-content"
                        >
                          <div className="section-title">
                            <h4 style={{ textAlign: "left" }}>Contact Us</h4>
                          </div>
                          <form>
                            <input type="text" placeholder="Full Name" />
                            <input type="email" placeholder="Email Address" />
                            <input type="text" placeholder="Phone Number" />
                            <textarea placeholder="Your Message"></textarea>
                            <input
                              type="submit"
                              value="submit"
                              className="cs-bgcolor"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="page-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-category-link-icon">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="modal"
                        href="remote.html"
                        data-bs-target="#request-more-info"
                      >
                        <i className="cs-color icon-question-circle"></i>Request
                        More Info
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="modal"
                        href="remote.html"
                        data-bs-target="#schedule-test-drive"
                      >
                        <i className="cs-color icon-chrome2"></i>Schedule Test
                        Drive
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="modal"
                        href="remote.html"
                        data-bs-target="#make-an-Offer"
                      >
                        <i className="cs-color icon-documents2"></i>Make an
                        Offer
                      </a>
                    </li>
                    <li>
                      <a href="assets/extra-images/pdf-sample.pdf" download>
                        <i className="cs-color icon-print3"></i>Print this
                        Detail
                      </a>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="modal"
                        href="remote.html"
                        data-bs-target="#email-to-friend"
                      >
                        <i className="cs-color icon-mail5"></i>Email to a Friend
                      </a>
                    </li>
                  </ul>
                  <div className="cs-form-modal">
                    <div
                      className="modal fade"
                      id="request-more-info"
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h4>Request More Info</h4>
                            <div className="cs-login-form">
                              <form className="row">
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-username-3">
                                      <strong>USERNAME</strong>
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        id="cs-username-3"
                                        placeholder="Type desired username"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-email-3">
                                      <strong>Email</strong>
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        id="cs-email-3"
                                        placeholder="Type desired email"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="phone">
                                      <strong>Phone</strong>
                                      <i className="icon-iphone26"></i>
                                      <input
                                        type="tel"
                                        id="phone"
                                        placeholder="+44 123 456 789"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label>
                                      <textarea required></textarea>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <div className="cs-media">
                                      <figure>
                                        <img
                                          src="assets/extra-images/cs-captha-img.png"
                                          alt="#"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Submit Query"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-form-modal">
                    <div
                      className="modal fade"
                      id="schedule-test-drive"
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h4>Schedule Test Drive</h4>
                            <div className="cs-login-form">
                              <form className="row">
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-username-4">
                                      <strong>USERNAME</strong>
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        id="cs-username-4"
                                        placeholder="Type desired username"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-email-4">
                                      <strong>Email</strong>
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        id="cs-email-4"
                                        placeholder="Type desired username"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="phone-1">
                                      <strong>Phone</strong>
                                      <i className="icon-iphone26"></i>
                                      <input
                                        type="tel"
                                        id="phone-1"
                                        placeholder="+44 123 456 789"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="best-time-1">
                                      <strong>Best time</strong>
                                      <i className="icon-clock96"></i>
                                      <input
                                        type="text"
                                        id="best-time-1"
                                        placeholder="Select date"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label>
                                      <textarea required></textarea>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <div className="cs-media">
                                      <figure>
                                        <img
                                          src="assets/extra-images/cs-captha-img.png"
                                          alt="#"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Submit Query"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-form-modal">
                    <div
                      className="modal fade"
                      id="make-an-Offer"
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h4>Make an Offer</h4>
                            <div className="cs-login-form">
                              <form className="row">
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-username-6">
                                      <strong>USERNAME</strong>
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        id="cs-username-6"
                                        placeholder="Type desired username"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-email-6">
                                      <strong>Email</strong>
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        id="cs-email-6"
                                        placeholder="Type desired email"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="phone-6">
                                      <strong>Phone</strong>
                                      <i className="icon-iphone26"></i>
                                      <input
                                        type="tel"
                                        id="phone-6"
                                        placeholder="+44 123 456 789"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="offered-price">
                                      <strong>Offered Price</strong>
                                      <i className="icon-dollar183"></i>
                                      <input
                                        type="text"
                                        id="offered-price"
                                        placeholder="$25,000"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label>
                                      <strong>Financing Required</strong>
                                      <i className="icon-expand38"></i>
                                      <select
                                        data-placeholder=""
                                        style={{ width: "100%" }}
                                        className="chosen-select"
                                        tabIndex="5"
                                      >
                                        <option>$30,000</option>
                                        <option>$35,000</option>
                                        <option>$45,000</option>
                                        <option>$55,000</option>
                                      </select>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label>
                                      <textarea required></textarea>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <div className="cs-media">
                                      <figure>
                                        <img
                                          src="assets/extra-images/cs-captha-img.png"
                                          alt="#"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Submit Query"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-form-modal">
                    <div
                      className="modal fade"
                      id="email-to-friend"
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h4>Email to a Friend</h4>
                            <div className="cs-login-form">
                              <form className="row">
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-username-7">
                                      <strong>USERNAME</strong>
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        id="cs-username-7"
                                        placeholder="Type desired username"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-email-7">
                                      <strong>Email</strong>
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        id="cs-email-7"
                                        placeholder="Type desired email"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="cs-friend-email">
                                      <strong>Friend Email</strong>
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        id="cs-friend-email"
                                        placeholder="Type desired friend email"
                                        required
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label htmlFor="phone-7">
                                      <strong>Phone</strong>
                                      <i className="icon-iphone26"></i>
                                      <input
                                        type="tel"
                                        id="phone-7"
                                        placeholder="+44 123 456 789"
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <label>
                                      <textarea required></textarea>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <div className="cs-media">
                                      <figure>
                                        <img
                                          src="assets/extra-images/cs-captha-img.png"
                                          alt="#"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div className="cs-modal-field">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Submit Query"
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auto-detail-filter">
                  <div className="element-title">
                    <h6>
                      <i className="cs-bgcolor icon-line-graph"></i> Financing
                      calculator
                    </h6>
                  </div>
                  <div className="auto-filter">
                    <form>
                      <ul className="row">
                        <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="auto-field">
                            <label>
                              Vehicle price{" "}
                              <span className="cs-color"> (&#x24;)</span>
                            </label>
                            <select
                              data-placeholder="$25,000"
                              style={{ width: "100%" }}
                              className="chosen-select"
                              tabIndex="5"
                            >
                              <option>$30,000</option>
                              <option>$35,000</option>
                              <option>$45,000</option>
                              <option>$55,000</option>
                            </select>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="auto-field">
                            <label>
                              Interest rate{" "}
                              <span className="cs-color"> (&#x25;)</span>
                            </label>
                            <select
                              data-placeholder="50%"
                              style={{ width: "100%" }}
                              className="chosen-select"
                              tabIndex="5"
                            >
                              <option>30%</option>
                              <option>35%</option>
                              <option>45%</option>
                              <option>55%</option>
                            </select>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="auto-field">
                            <label>
                              Period <span className="cs-color"> (month)</span>
                            </label>
                            <span id="ex6CurrentSliderValLabel">
                              <span id="ex6SliderVal">9</span> Months
                            </span>
                            <input
                              id="ex6"
                              type="text"
                              data-slider-min="0"
                              data-slider-max="12"
                              data-slider-step="1"
                              data-slider-value="9"
                            />
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="auto-field">
                            <label>
                              Down Payment
                              <span className="cs-color"> (&#x25;)</span>
                            </label>
                            <input type="text" placeholder="$326,500" />
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div className="auto-field">
                            <input
                              className="cs-bgcolor"
                              type="submit"
                              value="Calculate"
                            />
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "50px" }}>
          <div id="location" className="cs-map loader maps">
            <iframe
              height="450"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d155419.5727920435!2d13.426141949999993!3d52.507541899999964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e2b719cf2f3%3A0x8dba8fa58c57d186!2sBerlin!5e0!3m2!1sen!2sde!4v1411014193840"
            >
              {" "}
            </iframe>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div style={{ textAlign: "left" }} className="cs-section-title">
                  <h3>Related Cars</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="auto-listing auto-grid">
                  <div className="cs-media">
                    <figure>
                      <img
                        src="assets/extra-images/listing-Grid-img1.jpg"
                        alt="#"
                      />
                    </figure>
                  </div>
                  <div className="auto-text">
                    <span className="cs-categories">
                      <a href="#">Timlers Motors</a>
                    </span>
                    <div className="post-title">
                      <h6>
                        <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                      </h6>
                      <div className="auto-price">
                        <span className="cs-color">$25,000</span>{" "}
                        <em>MSRP $27,000</em>
                      </div>
                    </div>
                    <div className="btn-list">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-danger collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#list-view"
                      ></a>
                      <div id="list-view" className="collapse">
                        <ul>
                          <li>30/36 est. mpg 18</li>
                          <li>Black front grille with chrome accent</li>
                          <li>Cruise control</li>
                          <li>Remote keyless entry system</li>
                          <li>
                            Tilt 3-spoke steering wheel with audio controls
                          </li>
                          <li>15-in. alloy wheels</li>
                        </ul>
                      </div>
                    </div>
                    <div className="cs-checkbox">
                      <input
                        type="checkbox"
                        name="list"
                        value="check-listn"
                        id="check-list"
                      />
                      <label htmlFor="check-list">Compare</label>
                    </div>
                    <a href="#" className="View-btn">
                      View Detail<i className=" icon-arrow-long-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="auto-listing auto-grid">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <img
                        src="assets/extra-images/listing-Grid-img2.jpg"
                        alt="#"
                      />
                    </figure>
                  </div>
                  <div className="auto-text">
                    <span className="cs-categories">
                      <a href="#">Timlers Motors</a>
                    </span>
                    <div className="post-title">
                      <h6>
                        <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                      </h6>
                      <div className="auto-price">
                        <span className="cs-color">$25,000</span>{" "}
                        <em>MSRP $27,000</em>
                      </div>
                    </div>
                    <div className="btn-list">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-danger collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#list-view1"
                      ></a>
                      <div id="list-view1" className="collapse">
                        <ul>
                          <li>30/36 est. mpg 18</li>
                          <li>Black front grille with chrome accent</li>
                          <li>Cruise control</li>
                          <li>Remote keyless entry system</li>
                          <li>
                            Tilt 3-spoke steering wheel with audio controls
                          </li>
                          <li>15-in. alloy wheels</li>
                        </ul>
                      </div>
                    </div>
                    <div className="cs-checkbox">
                      <input
                        type="checkbox"
                        name="list"
                        value="check-listn"
                        id="check-list1"
                      />
                      <label htmlFor="check-list1">Compare</label>
                    </div>
                    <a href="#" className="View-btn">
                      View Detail<i className=" icon-arrow-long-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="auto-listing auto-grid">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <img
                        src="assets/extra-images/listing-Grid-img3.jpg"
                        alt="#"
                      />
                    </figure>
                  </div>
                  <div className="auto-text">
                    <span className="cs-categories">
                      <a href="#">Timlers Motors</a>
                    </span>
                    <div className="post-title">
                      <h6>
                        <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                      </h6>
                      <div className="auto-price">
                        <span className="cs-color">$25,000</span>{" "}
                        <em>MSRP $27,000</em>
                      </div>
                    </div>
                    <div className="btn-list">
                      <a
                        href="javascript:void(0)"
                        className="btn btn-danger collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#list-view2"
                      ></a>
                      <div id="list-view2" className="collapse">
                        <ul>
                          <li>30/36 est. mpg 18</li>
                          <li>Black front grille with chrome accent</li>
                          <li>Cruise control</li>
                          <li>Remote keyless entry system</li>
                          <li>
                            Tilt 3-spoke steering wheel with audio controls
                          </li>
                          <li>15-in. alloy wheels</li>
                        </ul>
                      </div>
                    </div>
                    <div className="cs-checkbox">
                      <input
                        type="checkbox"
                        name="list"
                        value="check-listn"
                        id="check-list2"
                      />
                      <label htmlFor="check-list2">Compare</label>
                    </div>
                    <a href="#" className="View-btn">
                      View Detail<i className=" icon-arrow-long-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <div className="auto-listing auto-grid">
                  <div className="cs-media">
                    <figure>
                      {" "}
                      <img
                        src="assets/extra-images/listing-Grid-img4.jpg"
                        alt="#"
                      />
                    </figure>
                  </div>
                  <div className="auto-text">
                    <span className="cs-categories">
                      <a href="#">Timlers Motors</a>
                    </span>
                    <div className="post-title">
                      <h6>
                        <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                      </h6>
                      <div className="auto-price">
                        <span className="cs-color">$25,000</span>{" "}
                        <em>MSRP $27,000</em>
                      </div>
                    </div>
                    <div className="cs-checkbox">
                      <input
                        type="checkbox"
                        name="list"
                        value="check-listn"
                        id="check-list3"
                      />
                      <label htmlFor="check-list3">Compare</label>
                    </div>
                    <a href="#" className="View-btn">
                      View Detail<i className=" icon-arrow-long-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: "#19171a" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="cs-ad"
                  style={{ textAlign: "center", padding: "55px 0 32px" }}
                >
                  <div className="cs-media">
                    <figure>
                      <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryDetailPage;
