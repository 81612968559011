import React from "react";

const SignInPage = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "#fafafa none repeat scroll 0 0",
          marginBottom: "30px",
          marginTop: "-30px",
          padding: "39px 0 44px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-socials-media">
                <em>Signup with your Social Networks</em>
                <ul>
                  <li>
                    <a data-original-title="facebook" href="#">
                      <i className="icon-facebook-f"></i>facebook
                    </a>
                  </li>
                  <li>
                    <a data-original-title="twitter" href="#">
                      <i className="icon-twitter4"></i>twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-section-title"
                style={{ marginBottom: "20px" }}
              >
                <h3 style={{ textAlign: "left" }}>MY ACCOUNT</h3>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-massege-box">
                <div className="alert alert-info alert-dismissable">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <i className="icon-info-with-circle"></i>
                  <span>
                    Ut ultricies potenti maecenas non urna iaculis integer odio
                    neque, pellentesque nostra potenti{" "}
                  </span>{" "}
                </div>
                <div className="alert alert-warning alert-dismissable">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <i className="icon-warning3"></i>
                  <span>
                    Quis potenti auctor urna curae scelerisque neque vestibulum
                    nam aliquam, class egestas{" "}
                  </span>{" "}
                </div>
                <div className="alert alert-danger alert-dismissable">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <i className="icon-blocked"></i>
                  <span>
                    Quis potenti auctor urna curae scelerisque neque vestibulum
                    nam aliquam, class egestas{" "}
                  </span>{" "}
                </div>
                <div className="alert alert-success alert-dismissable">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="alert"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <i className="icon-error_outline"></i>
                  <span>
                    Quis potenti auctor urna curae scelerisque neque vestibulum
                    nam aliquam, class egestas{" "}
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="cs-signin">
                <h4>LOGIN</h4>
                <div className="row">
                  <form>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Username or email address *</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <input type="text" name="name" placeholder="" />
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Password *</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <input type="text" name="name" placeholder="" />
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-btn-submit">
                          <input type="submit" value="Login" />
                        </div>
                        <a
                          href="#"
                          className="cs-forgot-password"
                          data-toggle="modal"
                          data-target="#user-forgot-pass"
                          data-dismiss="modal"
                        >
                          <i className="cs-color icon-help-with-circle"></i>
                          Forgot password?
                        </a>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-checkbox">
                          <input
                            id="check15"
                            type="checkbox"
                            name="check"
                            value="check1"
                          />
                          <label htmlFor="check15">Remember me</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="cs-register">
                <h4>LOGIN</h4>
                <div className="row">
                  <form>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Email address *</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <input type="text" name="name" placeholder="" />
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label>Password *</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <input type="text" name="name" placeholder="" />
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-btn-submit">
                          <input type="submit" value="Register" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "30px" }} className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-section-title" style={{ textAlign: "center" }}>
                <h3>Perfect Cars for youT</h3>
                <p>
                  It will help us find the Toyota you're looking for in your
                  area.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="auto-listing auto-grid">
                <div className="cs-media">
                  <figure>
                    <img
                      alt="#"
                      src="assets/extra-images/listing-Grid-img1.jpg"
                    />
                  </figure>
                </div>
                <div className="auto-text">
                  <span className="cs-categories">
                    <a href="#">Timlers Motors</a>
                  </span>
                  <div className="post-title">
                    <h6>
                      <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                    </h6>
                    <div className="auto-price">
                      <span className="cs-color">$25,000</span>{" "}
                      <em>MSRP $27,000</em>
                    </div>
                  </div>
                  <div className="btn-list">
                    <a
                      data-bs-target="#list-view"
                      data-bs-toggle="collapse"
                      className="btn btn-danger collapsed"
                      href="javascript:void(0)"
                    ></a>
                    <div className="collapse" id="list-view">
                      <ul>
                        <li>30/36 est. mpg 18</li>
                        <li>Black front grille with chrome accent</li>
                        <li>Cruise control</li>
                        <li>Remote keyless entry system</li>
                        <li>Tilt 3-spoke steering wheel with audio controls</li>
                        <li>15-in. alloy wheels</li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-checkbox">
                    <input
                      type="checkbox"
                      id="check-list"
                      value="check-listn"
                      name="list"
                    />
                    <label htmlFor="check-list">Compare</label>
                  </div>
                  <a className="View-btn" href="#">
                    View Detail<i className=" icon-arrow-long-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="auto-listing auto-grid">
                <div className="cs-media">
                  <figure>
                    {" "}
                    <img
                      alt="#"
                      src="assets/extra-images/listing-Grid-img2.jpg"
                    />
                  </figure>
                </div>
                <div className="auto-text">
                  <span className="cs-categories">
                    <a href="#">Timlers Motors</a>
                  </span>
                  <div className="post-title">
                    <h6>
                      <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                    </h6>
                    <div className="auto-price">
                      <span className="cs-color">$25,000</span>{" "}
                      <em>MSRP $27,000</em>
                    </div>
                  </div>
                  <div className="btn-list">
                    <a
                      data-bs-target="#list-view1"
                      data-bs-toggle="collapse"
                      className="btn btn-danger collapsed"
                      href="javascript:void(0)"
                    ></a>
                    <div className="collapse" id="list-view1">
                      <ul>
                        <li>30/36 est. mpg 18</li>
                        <li>Black front grille with chrome accent</li>
                        <li>Cruise control</li>
                        <li>Remote keyless entry system</li>
                        <li>Tilt 3-spoke steering wheel with audio controls</li>
                        <li>15-in. alloy wheels</li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-checkbox">
                    <input
                      type="checkbox"
                      id="check-list1"
                      value="check-listn"
                      name="list"
                    />
                    <label htmlFor="check-list1">Compare</label>
                  </div>
                  <a className="View-btn" href="#">
                    View Detail<i className=" icon-arrow-long-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="auto-listing auto-grid">
                <div className="cs-media">
                  <figure>
                    {" "}
                    <img
                      alt="#"
                      src="assets/extra-images/listing-Grid-img3.jpg"
                    />
                  </figure>
                </div>
                <div className="auto-text">
                  <span className="cs-categories">
                    <a href="#">Timlers Motors</a>
                  </span>
                  <div className="post-title">
                    <h6>
                      <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                    </h6>
                    <div className="auto-price">
                      <span className="cs-color">$25,000</span>{" "}
                      <em>MSRP $27,000</em>
                    </div>
                  </div>
                  <div className="btn-list">
                    <a
                      data-bs-target="#list-view2"
                      data-bs-toggle="collapse"
                      className="btn btn-danger collapsed"
                      href="javascript:void(0)"
                    ></a>
                    <div className="collapse" id="list-view2">
                      <ul>
                        <li>30/36 est. mpg 18</li>
                        <li>Black front grille with chrome accent</li>
                        <li>Cruise control</li>
                        <li>Remote keyless entry system</li>
                        <li>Tilt 3-spoke steering wheel with audio controls</li>
                        <li>15-in. alloy wheels</li>
                      </ul>
                    </div>
                  </div>
                  <div className="cs-checkbox">
                    <input
                      type="checkbox"
                      id="check-list2"
                      value="check-listn"
                      name="list"
                    />
                    <label htmlFor="check-list2">Compare</label>
                  </div>
                  <a className="View-btn" href="#">
                    View Detail<i className=" icon-arrow-long-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              <div className="auto-listing auto-grid">
                <div className="cs-media">
                  <figure>
                    {" "}
                    <img
                      alt="#"
                      src="assets/extra-images/listing-Grid-img4.jpg"
                    />
                  </figure>
                </div>
                <div className="auto-text">
                  <span className="cs-categories">
                    <a href="#">Timlers Motors</a>
                  </span>
                  <div className="post-title">
                    <h6>
                      <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                    </h6>
                    <div className="auto-price">
                      <span className="cs-color">$25,000</span>{" "}
                      <em>MSRP $27,000</em>
                    </div>
                  </div>
                  <div className="cs-checkbox">
                    <input
                      type="checkbox"
                      id="check-list3"
                      value="check-listn"
                      name="list"
                    />
                    <label htmlFor="check-list3">Compare</label>
                  </div>
                  <a className="View-btn" href="#">
                    View Detail<i className=" icon-arrow-long-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
