import React from "react";
import UserNavbar from "../../../components/user-navbar";

const UserAddNewVehicle = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "url(assets/extra-images/user-bg-img.jpg) no-repeat",
          backgroundSize: "cover",
          minHeight: "175px",
          marginTop: "-30px",
          marginBottom: "-129px",
        }}
      ></div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-user-account-holder">
                <div className="row">
                  <UserNavbar />
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-user-section-title">
                      <h4>Post New Vehicles</h4>
                    </div>
                  </div>
                  <form className="user-post-vehicles">
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>vehicles Information</h6>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Vehicles Title</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Model Year</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Select Make</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Model</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field has-error">
                          <select
                            data-placeholder="Select Model"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">Model.01</option>
                            <option value="United Kingdom">Model.02</option>
                            <option value="Afghanistan">Model.03</option>
                            <option value="Afghanistan">Model.04</option>
                            <option value="Afghanistan">Model.05</option>
                            <option value="Afghanistan">Model.06</option>
                          </select>
                          <span>Please select model</span>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Vehicles Version</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">
                              Vehicles Version
                            </option>
                            <option value="United Kingdom">
                              Vehicles Version
                            </option>
                            <option value="Afghanistan">
                              Vehicles Version
                            </option>
                            <option value="United Kingdom">
                              Vehicles Version
                            </option>
                            <option value="Afghanistan">
                              Vehicles Version
                            </option>
                            <option value="United Kingdom">
                              Vehicles Version
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>City</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                          <em>Enter valid mileage (1-1000000)</em>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Price* ($)</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                          <em>Enter valid mileage (1-1000000)</em>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Description</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <textarea></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Upload Images</h6>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-upload-img">
                          <ul>
                            <li>
                              <a href="#">
                                <img
                                  src="assets/extra-images/upload-img-1.jpg"
                                  alt="#"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="assets/extra-images/upload-img-2.jpg"
                                  alt="#"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <img
                                  src="assets/extra-images/upload-img-3.jpg"
                                  alt="#"
                                />
                              </a>
                            </li>
                          </ul>
                          <p>
                            Pictures should be in "jpeg, jpg, png, gif" format
                            only.
                          </p>
                          <p>
                            You can select and upload multiple pictures at the
                            same time.
                          </p>
                          <div className="cs-browse-holder">
                            <span className="file-input btn-file">
                              {" "}
                              Upload Photos
                              <input type="file" multiple />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Select Options</h6>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>
                          <i className="cs-color icon-engine"></i>Engine and
                          Drive Train
                        </h6>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>
                          Registration City <em>(Optional)</em>
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Exterior Color</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Assembly</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>
                          <i className="cs-color icon-vehicle92"></i>
                          Transmission
                        </h6>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Engine Type</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Engine Capacity </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Transmission</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Accessories and Options</h6>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder">
                      <ul className="cs-checkbox-list row">
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check1"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check1">
                              Automatic Stability Control
                            </label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check2"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check2">Car Kit</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check3"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check3">
                              Tire Pressure Monitoring
                            </label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check4"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check4">
                              Remote central locking
                            </label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check5"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check5">Bluetooth</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check6"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check6">Climate control</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check7"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check7">Onboard computer</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check8"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check8">Cruise Control</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check9"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check9">
                              Mirrors in body color
                            </label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check10"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check10">Roof rails</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check11"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check11">
                              Outside temperature display
                            </label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check12"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check12">Power sunroof</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check13"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check13">Body-color bumpers</label>
                          </div>
                        </li>
                        <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="cs-checkbox">
                            <input
                              id="check14"
                              type="checkbox"
                              name="check"
                              value="check1"
                            />
                            <label htmlFor="check14">Power windows front</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <h6>Contact Information</h6>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-seprator"></div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Mobile Number</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field has-error">
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Email Address</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Websites</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Country</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>City</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <select
                            data-placeholder="Select Make"
                            tabIndex="1"
                            className="chosen-select"
                          >
                            <option value="United States">United States</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Complete addres</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <textarea></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <label>Location</label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="cs-field">
                          <div className="main-search account-search">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                                <div className="select-location">
                                  <input
                                    type="text"
                                    placeholder="All Locations"
                                  />
                                  <a href="#" className="location-btn pop">
                                    <i className="icon-target3"></i>
                                  </a>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <div className="select-location">
                                  <input type="text" placeholder="Latitude" />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="select-location">
                                  <input type="text" placeholder="Longitude" />
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                <div className="search-btn">
                                  <i className="icon-search3"></i>
                                  <input
                                    type="button"
                                    value="&nbsp;"
                                    className="cs-bgcolor"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cs-map-holder">
                            <iframe
                              height="200"
                              src="http://regiohelden.de/google-maps/map_en.php?width=800&amp;height=300&amp;hl=en&amp;q=lohore%20pakistan+(RegioHelden%20GmbH)&amp;ie=UTF8&amp;t=&amp;z=3&amp;iwloc=A&amp;output=embed"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cs-field-holder">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="cs-field">
                          <div className="cs-btn-submit">
                            <input type="submit" value="SUBMIT & CONTINUE" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAddNewVehicle;
