import React, { Fragment } from "react";

const BlogGridPage = () => {
  return (
    <Fragment>
      <div className="cs-subheader">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-subheader-text">
                <h2>WHAT'S TRENDING in Car World</h2>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li className="active">
                      <a href="#">Latest Blog</a>
                    </li>
                    <li>
                      <a href="#">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ paddingTop: "30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-1.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption>
                        <div className="caption-text">
                          <span>STICKY POST</span>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Vehicles</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Avalon Hybrid Built for the endless weekend.
                        </a>
                      </h4>
                    </div>
                    <p>
                      Norwegian airline website named Widerøe generated a
                      tremendous amount of buzz and a lot of very happy
                      customers this weekend.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-1.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Anselm Hannemann</span>
                      <em>March 6, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-2.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption></figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Motors</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Speed Dual-Clutch Featured Special New ReLease
                        </a>
                      </h4>
                    </div>
                    <p>
                      Website named Widerøe generated a tremendous amount of
                      buzz and a lot of very happy customers this weekend when
                      word got out that supposed.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-2.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Arashasghari</span>
                      <em>September 30, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-3.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption></figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Cars and Races</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Human laws, but we cannot resist natural ones
                        </a>
                      </h4>
                    </div>
                    <p>
                      Amount of buzz and a lot of very happy customers this
                      weekend when word got out that supposed unintentional
                      error in their reservation.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-3.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Darrellwhitelaw</span>
                      <em>October 9, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <iframe
                        src="https://player.vimeo.com/video/36305675?color=ff2300&title=0&byline=0&portrait=0"
                        width="500"
                        height="197"
                        allowfullscreen
                      ></iframe>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Vehicles</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Avalon Hybrid Built for the endless weekend.
                        </a>
                      </h4>
                    </div>
                    <p>
                      Norwegian airline website named Widerøe generated a
                      tremendous amount of buzz and a lot of very happy
                      customers this weekend.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-1.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Anselm Hannemann</span>
                      <em>March 6, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-4.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption></figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Motors</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Speed Dual-Clutch Featured Special New ReLease
                        </a>
                      </h4>
                    </div>
                    <p>
                      Website named Widerøe generated a tremendous amount of
                      buzz and a lot of very happy customers this weekend when
                      word got out that supposed.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-2.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Arashasghari</span>
                      <em>September 30, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <ul className="blog-listing-grid-slider">
                      <li>
                        <figure>
                          <img
                            src="assets/extra-images/blog-listing-5.jpg"
                            alt="#"
                          />
                          <figcaption>
                            <em>8 Photos</em>
                          </figcaption>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src="assets/extra-images/blog-listing-5.jpg"
                            alt="#"
                          />
                          <figcaption>
                            <em>8 Photos</em>
                          </figcaption>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src="assets/extra-images/blog-listing-5.jpg"
                            alt="#"
                          />
                          <figcaption>
                            <em>8 Photos</em>
                          </figcaption>
                        </figure>
                      </li>
                      <li>
                        <figure>
                          <img
                            src="assets/extra-images/blog-listing-5.jpg"
                            alt="#"
                          />
                          <figcaption>
                            <em>8 Photos</em>
                          </figcaption>
                        </figure>
                      </li>
                    </ul>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Cars and Races</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Human laws, but we cannot resist natural ones
                        </a>
                      </h4>
                    </div>
                    <p>
                      Amount of buzz and a lot of very happy customers this
                      weekend when word got out that supposed unintentional
                      error in their reservation.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-3.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Darrellwhitelaw</span>
                      <em>October 9, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-6.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption></figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Vehicles</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Avalon Hybrid Built for the endless weekend.
                        </a>
                      </h4>
                    </div>
                    <p>
                      Norwegian airline website named Widerøe generated a
                      tremendous amount of buzz and a lot of very happy
                      customers this weekend.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-1.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Anselm Hannemann</span>
                      <em>March 6, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-7.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption>
                        <div className="caption-text">
                          <i className="icon-audiotrack"></i>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Motors</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Speed Dual-Clutch Featured Special New ReLease
                        </a>
                      </h4>
                    </div>
                    <p>
                      Website named Widerøe generated a tremendous amount of
                      buzz and a lot of very happy customers this weekend when
                      word got out that supposed.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-2.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Arashasghari</span>
                      <em>September 30, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-blog-listing blog-grid">
                  <div className="cs-media">
                    <figure>
                      <a href="#">
                        <img
                          src="assets/extra-images/blog-listing-8.jpg"
                          alt="#"
                        />
                      </a>
                      <figcaption>
                        <div className="caption-text">
                          <i className="icon-videocam"></i>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="blog-text">
                    <div className="post-option">
                      <span className="post-date">Cars and Races</span>
                    </div>
                    <div className="post-title">
                      <h4>
                        <a href="#">
                          Human laws, but we cannot resist natural ones
                        </a>
                      </h4>
                    </div>
                    <p>
                      Amount of buzz and a lot of very happy customers this
                      weekend when word got out that supposed unintentional
                      error in their reservation.
                    </p>
                    <div className="post-meta">
                      <figure>
                        <img
                          src="assets/extra-images/blog-grid-3.jpg"
                          alt="#"
                        />
                      </figure>
                      <span className="post-by">Darrellwhitelaw</span>
                      <em>October 9, 2023</em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: "#19171a" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="cs-ad"
                  style={{ textAlign: "center", padding: "55px 0 32px" }}
                >
                  <div className="cs-media">
                    <figure>
                      <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogGridPage;
