// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import About from "./pages/about";
import { ShopCart, ShopCheckout, ShopDetail, ShopListing } from "./pages/shop/";
import Faq from "./pages/faq";
import { AgentDetail, AgentListing } from "./pages/agent/";
import Services from "./pages/our-services";
import ListingPackages from "./pages/listing-packages";
import Page404 from "./pages/404-page";
import SearchResultPage from "./pages/search-result";
import {
  InventoryDetailPage,
  InventoryGridPage,
  InventoryRowPage,
} from "./pages/inventory";
import SignInPage from "./pages/sign-in";
import CompareCarsPage from "./pages/compare-cars";
import ContactUs from "./pages/contact-us";
import {
  BlogGridPage,
  BlogLargePage,
  BlogMediumPage,
  BlogWithSound,
  BlogWithVideo,
} from "./pages/blogs";
import {
  UserAddNewVehicle,
  UserPackages,
  UserPaymentPage,
  UserProfilePage,
  UserShortlistedVehicles,
  UserVehicleListing,
} from "./pages/user";

function App() {
  return (
    <BrowserRouter>
      <div className="wp-automobile ">
        <div className="wrapper">
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/inventory-grid" element={<InventoryGridPage />} />
              <Route path="/inventory-row" element={<InventoryRowPage />} />
              <Route
                path="/inventory-detail"
                element={<InventoryDetailPage />}
              />
              <Route path="/shopcart" element={<ShopCart />} />
              <Route path="/shopdetail" element={<ShopDetail />} />
              <Route path="/shoplisting" element={<ShopListing />} />
              <Route path="/shopcheckout" element={<ShopCheckout />} />
              <Route path="/compare-cars" element={<CompareCarsPage />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/user-profile" element={<UserProfilePage />} />
              <Route
                path="/user-add-new-vehicle"
                element={<UserAddNewVehicle />}
              />
              <Route
                path="/user-vehicle-listings"
                element={<UserVehicleListing />}
              />
              <Route path="/user-payment" element={<UserPaymentPage />} />
              <Route path="/user-packages" element={<UserPackages />} />
              <Route
                path="/user-shortlisted-vehicles"
                element={<UserShortlistedVehicles />}
              />
              <Route path="/blog-large" element={<BlogLargePage />} />
              <Route path="/blog-medium" element={<BlogMediumPage />} />
              <Route path="/blog-grid" element={<BlogGridPage />} />
              <Route path="/blog-with-sound" element={<BlogWithSound />} />
              <Route path="/blog-with-video" element={<BlogWithVideo />} />
              {/* <Route path="/agentdetail" element={<AgentDetail />} />
              <Route path="/agentlisting" element={<AgentListing />} /> */}
              <Route path="/services" element={<Services />} />
              <Route path="/listing-packages" element={<ListingPackages />} />
              <Route path="/search" element={<SearchResultPage />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </main>
        </div>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
