import React from "react";
import UserNavbar from "../../../components/user-navbar";

const UserVehicleListing = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "url(assets/extra-images/user-bg-img.jpg) no-repeat",
          backgroundSize: "cover",
          minHeight: "175px",
          marginTop: "-30px",
          marginBottom: "-129px",
        }}
      ></div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-user-account-holder">
                <div className="row">
                  <UserNavbar />
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-user-section-title">
                      <h4>My vehicles</h4>
                      <ul>
                        <li>
                          <a href="#" className="cs-active-btn">
                            Active
                          </a>
                          <ul>
                            <li>
                              <a href="#">Expired</a>
                            </li>{" "}
                            <li>
                              <a href="#">Removed</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ul className="cs-featurelisted-car row">
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <span className="cs-featured">Featured</span>
                        <h6>
                          <a href="#">Toyota Corolla XLi VVTi for Sale</a>
                        </h6>
                        <div className="post-options">
                          <span>
                            Expires <em>26 September 2023</em>
                          </span>
                          <span>
                            <a href="#">
                              {" "}
                              Total Views <em>59</em>
                            </a>
                          </span>
                        </div>
                        <div className="cs-post-types">
                          <div className="cs-post-list">
                            <div className="cs-edit-post">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className="icon-edit2"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                            <div className="cs-list">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share"
                              >
                                <i className="icon-forward4"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className=" icon-clone"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download"
                              >
                                <i className="icon-box-add"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                          </div>
                          <span
                            className="cs-default-btn"
                            style={{
                              color: "#4aa818",
                              border: "1px solid #4aa818",
                            }}
                          >
                            Active
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">used Toyota Corolla XLi VVTi 2004</a>
                        </h6>
                        <div className="post-options">
                          <span>
                            Expires <em>26 September 2023</em>
                          </span>
                          <span>
                            <a href="#">
                              {" "}
                              Total Views <em>59</em>
                            </a>
                          </span>
                        </div>
                        <div className="cs-post-types">
                          <div className="cs-post-list">
                            <div className="cs-edit-post">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className="icon-edit2"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                            <div className="cs-list">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share"
                              >
                                <i className="icon-forward4"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className=" icon-clone"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download"
                              >
                                <i className="icon-box-add"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                          </div>
                          <span
                            className="cs-default-btn"
                            style={{
                              color: "#4aa818",
                              border: "1px solid #4aa818",
                            }}
                          >
                            Active
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <span className="cs-featured">Featured</span>
                        <h6>
                          <a href="#">Vezel Hybrid Z Navigation for Sale</a>
                        </h6>
                        <div className="post-options">
                          <span>
                            Expires <em>26 September 2023</em>
                          </span>
                          <span>
                            <a href="#">
                              {" "}
                              Total Views <em>59</em>
                            </a>
                          </span>
                        </div>
                        <div className="cs-post-types">
                          <div className="cs-post-list">
                            <div className="cs-edit-post">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className="icon-edit2"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                            <div className="cs-list">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share"
                              >
                                <i className="icon-forward4"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className=" icon-clone"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download"
                              >
                                <i className="icon-box-add"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                          </div>
                          <span
                            className="cs-default-btn"
                            style={{
                              color: "#d00000",
                              border: "1px solid #d00000",
                            }}
                          >
                            Expired
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Honda Vezel Hybrid Z Navigation</a>
                        </h6>
                        <div className="post-options">
                          <span>
                            Expires <em>26 September 2023</em>
                          </span>
                          <span>
                            <a href="#">
                              {" "}
                              Total Views <em>59</em>
                            </a>
                          </span>
                        </div>
                        <div className="cs-post-types">
                          <div className="cs-post-list">
                            <div className="cs-edit-post">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className="icon-edit2"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                            <div className="cs-list">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share"
                              >
                                <i className="icon-forward4"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className=" icon-clone"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download"
                              >
                                <i className="icon-box-add"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                          </div>
                          <span
                            className="cs-default-btn"
                            style={{
                              color: "#4aa818",
                              border: "1px solid #4aa818",
                            }}
                          >
                            Active
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/car-shortlisted-img5.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <span className="cs-featured">Featured</span>
                        <h6>
                          <a href="#">Vezel Hybrid Z Navigation for Sale</a>
                        </h6>
                        <div className="post-options">
                          <span>
                            Expires <em>26 September 2023</em>
                          </span>
                          <span>
                            <a href="#">
                              {" "}
                              Total Views <em>59</em>
                            </a>
                          </span>
                        </div>
                        <div className="cs-post-types">
                          <div className="cs-post-list">
                            <div className="cs-edit-post">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className="icon-edit2"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                            <div className="cs-list">
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Share"
                              >
                                <i className="icon-forward4"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Post"
                              >
                                <i className=" icon-clone"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Download"
                              >
                                <i className="icon-box-add"></i>
                              </a>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="icon-trash-o"></i>
                              </a>
                            </div>
                          </div>
                          <span
                            className="cs-default-btn"
                            style={{
                              color: "#4aa818",
                              border: "1px solid #4aa818",
                            }}
                          >
                            Active
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="cs-load-more">
                    <a href="#" className="loadmore-btn">
                      Load more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVehicleListing;
