import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <header id="header">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
            <div className="cs-logo">
              <div className="cs-media">
                <figure>
                  <Link to="/">
                    <img src="assets/images/cs-logo.png" alt="logo" />
                  </Link>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-8 col-lg-10 col-xl-10 col-xxl-10">
            <div className="cs-main-nav pull-right">
              <nav className="main-navigation">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/inventory-grid">Inventory</Link>
                    {/* <ul>
                      <li>
                        <Link to="/inventory-grid">Grid Style</Link>
                      </li>
                      <li>
                        <Link to="/inventory-row">classic Style</Link>
                      </li>
                      <li>
                        <Link to="/inventory-detail">Detail Page</Link>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li>
                    <a href="#">Dealers</a>
                    <ul>
                      <li>
                        <Link to="/agentlisting">List View</Link>
                      </li>
                      <li>
                        <Link to="/agentdetail">Detail Page</Link>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <Link to="/compare-cars">Compare</Link>
                    {/* <ul>
                      <li>
                        <Link to="/compare-cars">Listing</Link>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    <a href="#">pages</a>
                    <ul>
                      <li>
                        <Link to="/services">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/user-add-new-vehicle">Post a Vehicle</Link>
                      </li>
                      <li>
                        <Link to="/listing-packages">Listing Packages</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ's & Help</Link>
                      </li>
                      <li>
                        <Link to="/search">Search Result</Link>
                      </li>
                      {/* <li>
                        <a href="underconstruction.html">Under Construction</a>
                      </li>
                      <li>
                        <a href="underconstruction-2.html">
                          Under Construction 2
                        </a>
                      </li> */}
                      <li>
                        <Link to="/signin">Signup/Sign in</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <a href="#">News</a>
                    <ul>
                      <li>
                        <Link to="/blog-large">Large View</Link>
                      </li>
                      <li>
                        <Link to="blog-medium">Medium View</Link>
                      </li>
                      <li>
                        <Link to="/blog-grid">Grid View</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <a href="#">Detail View</a>
                        <ul>
                          <li>
                            <Link to="/blog-with-sound">With Audio</Link>
                          </li>
                          <li>
                            <Link to="/blog-with-video">With Video</Link>
                          </li>
                          <li>
                            <a href="blog-detail-sound.html">With Soundcloud</a>
                          </li>
                          <li>
                            <a href="blog-detail-slider.html">With Slider</a>
                          </li>
                          <li>
                            <a href="blog-detail-post.html">Featured Image</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                  <li>
                    <a href="#">Shop</a>
                    <ul>
                      <li>
                        <Link to="/shoplisting">Products</Link>
                      </li>
                      <li>
                        <Link to="/shopdetail">Detail View</Link>
                      </li>
                      <li>
                        <Link to="/shopcart">Cart</Link>
                      </li>
                      <li>
                        <Link to="/shopcheckout">Checkout</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="cs-user-option">
                    <div className="cs-login">
                      <div className="cs-login-dropdown">
                        {" "}
                        <a href="#">
                          <i className="icon-user2"></i> Kaiser{" "}
                          <i className="icon-chevron-down2"></i>
                        </a>
                        <div className="cs-user-dropdown">
                          {" "}
                          <strong>Post a new Ad</strong>
                          <ul>
                            <li>
                              <Link to="/user-profile">
                                General Setting
                                <span className="cs-bgcolor">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/user-vehicle-listings">
                                My Posted Cars
                                <span className="cs-bgcolor">23</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/user-add-new-vehicle">
                                Post New Car
                              </Link>
                            </li>
                            <li>
                              <Link to="/user-shortlisted-vehicles">
                                Shortlisted
                              </Link>
                            </li>
                            <li>
                              <Link to="/user-payment">Payment</Link>
                            </li>
                            <li>
                              <Link to="/user-packages">Packages</Link>
                            </li>
                          </ul>
                          <a className="btn-sign-out" href="#">
                            Logout
                          </a>
                        </div>
                      </div>
                      <a
                        className="cs-bgcolor btn-form"
                        data-bs-toggle="modal"
                        href="remote.html"
                        data-bs-target="#user-sign-up"
                        aria-hidden="true"
                      >
                        <i className="icon-plus"></i> Sell Car
                      </a>

                      {/* <!-- Modal --> */}
                      <div
                        className="modal fade"
                        id="user-sign-up"
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h4>Create Account</h4>
                              <div className="cs-login-form">
                                <form>
                                  <div className="input-holder">
                                    <label htmlFor="cs-username">
                                      {" "}
                                      <strong>USERNAME</strong>{" "}
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        className=""
                                        id="cs-username"
                                        placeholder="Type desired username"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <label htmlFor="cs-email">
                                      {" "}
                                      <strong>Email</strong>{" "}
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        className=""
                                        id="cs-email"
                                        placeholder="Type desired username"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <label htmlFor="cs-login-password">
                                      {" "}
                                      <strong>Password</strong>{" "}
                                      <i className="icon-unlock40"></i>
                                      <input
                                        type="password"
                                        id="cs-login-password"
                                        placeholder="******"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <label htmlFor="cs-confirm-password">
                                      {" "}
                                      <strong>confirm password</strong>{" "}
                                      <i className="icon-unlock40"></i>
                                      <input
                                        type="password"
                                        id="cs-confirm-password"
                                        placeholder="******"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Create Account"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="modal-footer">
                              {" "}
                              <a
                                data-bs-dismiss="modal"
                                data-bs-target="#user-sign-in"
                                data-bs-toggle="modal"
                                href="javascript:;"
                                aria-hidden="true"
                              >
                                Already have account
                              </a>
                              <div className="cs-separator">
                                <span>or</span>
                              </div>
                              <div className="cs-user-social">
                                {" "}
                                <em>Signin with your Social Networks</em>
                                <ul>
                                  <li>
                                    <a href="#" data-original-title="facebook">
                                      <i className="icon-facebook-f"></i>
                                      facebook
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" data-original-title="twitter">
                                      <i className="icon-twitter4"></i>twitter
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="user-sign-in"
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h4>User Sign in</h4>
                              <div className="cs-login-form">
                                <form>
                                  <div className="input-holder">
                                    <label htmlFor="cs-username-1">
                                      {" "}
                                      <strong>USERNAME</strong>{" "}
                                      <i className="icon-user-plus2"></i>
                                      <input
                                        type="text"
                                        className=""
                                        id="cs-username-1"
                                        placeholder="Type desired username"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <label htmlFor="cs-login-password-1">
                                      {" "}
                                      <strong>Password</strong>{" "}
                                      <i className="icon-unlock40"></i>
                                      <input
                                        type="password"
                                        id="cs-login-password-1"
                                        placeholder="******"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    {" "}
                                    <a
                                      className="btn-forgot-pass"
                                      data-dismiss="modal"
                                      data-target="#user-forgot-pass"
                                      data-toggle="modal"
                                      href="javascript:;"
                                      aria-hidden="true"
                                    >
                                      <i className=" icon-question-circle"></i>{" "}
                                      Forgot password?
                                    </a>{" "}
                                  </div>
                                  <div className="input-holder">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="SIGN IN"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <div className="cs-separator">
                                <span>or</span>
                              </div>
                              <div className="cs-user-social">
                                {" "}
                                <em>Signin with your Social Networks</em>
                                <ul>
                                  <li>
                                    <a href="#" data-original-title="facebook">
                                      <i className="icon-facebook-f"></i>
                                      facebook
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" data-original-title="twitter">
                                      <i className="icon-twitter4"></i>twitter
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="cs-user-signup">
                                {" "}
                                <i className="icon-user-plus2"></i>{" "}
                                <strong>Not a Member yet? </strong>{" "}
                                <a
                                  className="cs-color"
                                  data-dismiss="modal"
                                  data-target="#user-sign-up"
                                  data-toggle="modal"
                                  href="javascript:;"
                                  aria-hidden="true"
                                >
                                  Signup Now
                                </a>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="user-forgot-pass"
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h4>Password Recovery</h4>
                              <div className="cs-login-form">
                                <form>
                                  <div className="input-holder">
                                    <label htmlFor="cs-email-1">
                                      {" "}
                                      <strong>Email</strong>{" "}
                                      <i className="icon-envelope"></i>
                                      <input
                                        type="email"
                                        className=""
                                        id="cs-email-1"
                                        placeholder="Type desired username"
                                      />
                                    </label>
                                  </div>
                                  <div className="input-holder">
                                    <input
                                      className="cs-color csborder-color"
                                      type="submit"
                                      value="Send"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <div className="cs-user-signup">
                                {" "}
                                <i className="icon-user-plus2"></i>{" "}
                                <strong>Not a Member yet? </strong>{" "}
                                <a
                                  href="javascript:;"
                                  data-toggle="modal"
                                  data-target="#user-sign-up"
                                  data-dismiss="modal"
                                  className="cs-color"
                                  aria-hidden="true"
                                >
                                  Signup Now
                                </a>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--<div className="cs-wish-list"><a href="#"><i className=" icon-heart4"></i><span>0</span></a>
                                        <div className="wish-list-dropdown">
                                            <strong>Post a new Ad</strong>
                                            <ul>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-1.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-2.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-3.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-1.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-2.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                                <li className="alert alert-dismissible  fade in" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <div className="cs-media">
                                                        <a href="#">
                                                            <img src="assets/extra-images/single-banner-img-3.jpg" alt="#">
                                                        </a>
                                                    </div>
                                                    <div className="cs-info">
                                                        <h6><a href="#">Desktop Application Developer Support</a></h6>
                                                        Added <span>Feb 8, 2023</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <a className="btn-view-all" href="#">View All</a>
                                        </div>
                                    </div>-->  */}
                  </li>
                </ul>
              </nav>
              <div className="cs-user-option hidden-lg visible-sm visible-xs">
                <div className="cs-login">
                  <div className="cs-login-dropdown">
                    {" "}
                    <a href="#">
                      <i className="icon-user2"></i> Kaiser{" "}
                      <i className="icon-chevron-down2"></i>
                    </a>
                    <div className="cs-user-dropdown">
                      {" "}
                      <strong>Post a new Ad</strong>
                      <ul>
                        <li>
                          <a href="user-genral-setting.html">
                            General Setting<span className="cs-bgcolor">3</span>
                          </a>
                        </li>
                        <li>
                          <a href="user-car-listing.html">
                            My Posted Cars{" "}
                            <span className="cs-bgcolor">23</span>
                          </a>
                        </li>
                        <li>
                          <a href="user-post-new-vehicles.html">Post New Car</a>
                        </li>
                        <li>
                          <a href="user-car-shortlist.html">Shortlisted</a>
                        </li>
                        <li>
                          <a href="user-payments.html">Payment</a>
                        </li>
                        <li>
                          <a href="user-packages.html">Packages</a>
                        </li>
                      </ul>
                      <a className="btn-sign-out" href="#">
                        Logout
                      </a>
                    </div>
                  </div>
                  <a
                    className="cs-bgcolor btn-form"
                    data-bs-toggle="modal"
                    href="remote.html"
                    data-bs-target="#user-sign-up-sm"
                    aria-hidden="true"
                  >
                    <i className="icon-plus"></i> Sell Car
                  </a>
                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="user-sign-up-sm"
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <h4>Create Account</h4>
                          <div className="cs-login-form">
                            <form>
                              <div className="input-holder">
                                <label htmlFor="cs-username11">
                                  {" "}
                                  <strong>USERNAME</strong>{" "}
                                  <i className="icon-user-plus2"></i>
                                  <input
                                    type="text"
                                    className=""
                                    id="cs-username11"
                                    placeholder="Type desired username"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <label htmlFor="cs-email11">
                                  {" "}
                                  <strong>Email</strong>{" "}
                                  <i className="icon-envelope"></i>
                                  <input
                                    type="email"
                                    className=""
                                    id="cs-email11"
                                    placeholder="Type desired username"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <label htmlFor="cs-login-password11">
                                  {" "}
                                  <strong>Password</strong>{" "}
                                  <i className="icon-unlock40"></i>
                                  <input
                                    type="password"
                                    id="cs-login-password11"
                                    placeholder="******"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <label htmlFor="cs-confirm-password11">
                                  {" "}
                                  <strong>confirm password</strong>{" "}
                                  <i className="icon-unlock40"></i>
                                  <input
                                    type="password"
                                    id="cs-confirm-password11"
                                    placeholder="******"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <input
                                  className="cs-color csborder-color"
                                  type="submit"
                                  value="Create Account"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {" "}
                          <a
                            data-dismiss="modal"
                            data-target="#user-sign-in-sm"
                            data-toggle="modal"
                            href="javascript:;"
                            aria-hidden="true"
                          >
                            Already have account
                          </a>
                          <div className="cs-separator">
                            <span>or</span>
                          </div>
                          <div className="cs-user-social">
                            {" "}
                            <em>Signin with your Social Networks</em>
                            <ul>
                              <li>
                                <a href="#" data-original-title="facebook">
                                  <i className="icon-facebook-f"></i>facebook
                                </a>
                              </li>
                              <li>
                                <a href="#" data-original-title="twitter">
                                  <i className="icon-twitter4"></i>twitter
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="user-sign-in-sm"
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <h4>User Sign in</h4>
                          <div className="cs-login-form">
                            <form>
                              <div className="input-holder">
                                <label htmlFor="cs-username-111">
                                  {" "}
                                  <strong>USERNAME</strong>{" "}
                                  <i className="icon-user-plus2"></i>
                                  <input
                                    type="text"
                                    className=""
                                    id="cs-username-111"
                                    placeholder="Type desired username"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <label htmlFor="cs-login-password-111">
                                  {" "}
                                  <strong>Password</strong>{" "}
                                  <i className="icon-unlock40"></i>
                                  <input
                                    type="password"
                                    id="cs-login-password-111"
                                    placeholder="******"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                {" "}
                                <a
                                  className="btn-forgot-pass"
                                  data-dismiss="modal"
                                  data-target="#user-forgot-pass-sm"
                                  data-toggle="modal"
                                  href="javascript:;"
                                  aria-hidden="true"
                                >
                                  <i className=" icon-question-circle"></i>{" "}
                                  Forgot password?
                                </a>{" "}
                              </div>
                              <div className="input-holder">
                                <input
                                  className="cs-color csborder-color"
                                  type="submit"
                                  value="SIGN IN"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="cs-separator">
                            <span>or</span>
                          </div>
                          <div className="cs-user-social">
                            {" "}
                            <em>Signin with your Social Networks</em>
                            <ul>
                              <li>
                                <a href="#" data-original-title="facebook">
                                  <i className="icon-facebook-f"></i>facebook
                                </a>
                              </li>
                              <li>
                                <a href="#" data-original-title="twitter">
                                  <i className="icon-twitter4"></i>twitter
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="cs-user-signup">
                            {" "}
                            <i className="icon-user-plus2"></i>{" "}
                            <strong>Not a Member yet? </strong>{" "}
                            <a
                              className="cs-color"
                              data-dismiss="modal"
                              data-target="#user-sign-up-sm"
                              data-toggle="modal"
                              href="javascript:;"
                              aria-hidden="true"
                            >
                              Signup Now
                            </a>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="user-forgot-pass-sm"
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <h4>Password Recovery</h4>
                          <div className="cs-login-form">
                            <form>
                              <div className="input-holder">
                                <label htmlFor="cs-email-111">
                                  {" "}
                                  <strong>Email</strong>{" "}
                                  <i className="icon-envelope"></i>
                                  <input
                                    type="email"
                                    className=""
                                    id="cs-email-111"
                                    placeholder="Type desired username"
                                  />
                                </label>
                              </div>
                              <div className="input-holder">
                                <input
                                  className="cs-color csborder-color"
                                  type="submit"
                                  value="Send"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="cs-user-signup">
                            {" "}
                            <i className="icon-user-plus2"></i>{" "}
                            <strong>Not a Member yet? </strong>{" "}
                            <a
                              href="javascript:;"
                              data-toggle="modal"
                              data-target="#user-sign-up-sm"
                              data-dismiss="modal"
                              className="cs-color"
                              aria-hidden="true"
                            >
                              Signup Now
                            </a>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--<div className="cs-wish-list"><a href="#"><i className=" icon-heart4"></i><span>0</span></a>
                            <div className="wish-list-dropdown">
                                <strong>Post a new Ad</strong>
                                <ul>
                                    <li className="alert alert-dismissible  fade in" role="alert">

                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-1.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                    <li className="alert alert-dismissible  fade in" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-2.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                    <li className="alert alert-dismissible  fade in" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-3.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                    <li className="alert alert-dismissible  fade in" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-1.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                    <li className="alert alert-dismissible  fade in" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-2.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                    <li className="alert alert-dismissible  fade in" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <div className="cs-media">
                                            <a href="#">
                                                <img src="assets/extra-images/single-banner-img-3.jpg" alt="#">
                                            </a>
                                        </div>
                                        <div className="cs-info">
                                            <h6><a href="#">Desktop Application Developer Support</a></h6>
                                            Added <span>Feb 8, 2023</span>
                                        </div>
                                    </li>
                                </ul>
                                <a className="btn-view-all" href="#">View All</a>
                            </div>
                        </div>-->  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
