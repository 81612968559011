import React from 'react';
import classes from './services.module.css';

const Services = () => {
  return (
    <React.Fragment>
      <div
        className="cs-subheader left"
        style={{
          background:
            'url(assets/extra-images/sub-header-services-img-1.jpg) no-repeat 0 0 /cover',
          minHeight: '296px',
          padding: '90px 0 0',
          marginBottom: '50px',
        }}
      >
        <div className={`${classes['container']} container`}>
          <div className="cs-page-title">
            <h1 className={classes.serviceRed}>Our Services</h1>
            <p style={{ color: '#fff' }}>
              If you think we might be a good fit for a project you’re working
              on and you’d like to get the feel of how we
              <br /> can work together please contact us using contact data.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ marginBottom: '10px' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '70px' }}
                      className="icon-fuel1 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>OIL CHANGES </h6>
                    <p>
                      Vivamus erat diam, condimentum in aliquet sed, bibendum at
                      quam. Phasellus lacus mauris.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '40px' }}
                      className="icon-car230 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>AIR CONDITIONING</h6>
                    <p>
                      Nulla a gravida purus. Fusce at interdum libero, at.
                      Vivamus erat diam, condimentum in bibendum at quam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '40px' }}
                      className="icon-energy42 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>AUTO ELECTRIC </h6>
                    <p>
                      Etiam et maximus nisi, nec scelerisque neque. Nunc euismod
                      iaculis urna, id eleifend lorem aliquet at.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '40px' }}
                      className="icon-car36 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>BRAKE SERVICE </h6>
                    <p>
                      Vivamus erat diam, condimentum in aliquet sed, bibendum at
                      quam. Phasellus lacus mauris.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '40px' }}
                      className="icon-car228 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>TRANSMISSION</h6>
                    <p>
                      Nulla a gravida purus. Fusce at interdum libero, at.
                      Vivamus erat diam, condimentum in bibendum at quam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services">
                  <div className="cs-media">
                    {' '}
                    <i
                      style={{ fontSize: '40px' }}
                      className="icon-transport177 cs-color"
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>TIRE &amp; WHEEL SERVICE</h6>
                    <p>
                      Etiam et maximus nisi, nec scelerisque neque. Nunc euismod
                      iaculis urna, id eleifend lorem aliquet at.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{
            marginBottom: '58px',
            paddingTop: '94px',
            paddingBottom: '80px',
            background: '#f5f6f8',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="cs-column-text">
                  <h1 style={{ lineHeight: '43px !important' }}>
                    Automotive SERVICE CENTER
                  </h1>
                  <p style={{ marginBottom: '25px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Error quia, ad natus corrupti inventore mollitia, dolor
                    omnis nesciunt, molestias officiis eum debitis dolore.
                    Minima magnam odit cupiditate labore accusantium eaque!
                    Consectetur adipisicing elit. Excepturi minus tenetur sunt
                    aspernatur vitae, corporis nostrum quibusdam molestias,
                    laudantium quia in a culpa inventore quidem illo atque.
                  </p>
                  <ul className="cs-icon-list row">
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color "></i>
                      Run though of the landing page for Automark (certified
                      used vehicles){' '}
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>I
                      did a little while ago. Thanks to my boy
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>
                      DDoS &amp; intrusion protection
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>
                      Malicious plugin removal{' '}
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>
                      Automated WordPress updates
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>
                      DDoS &amp; intrusion protection
                    </li>
                    <li className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <i className="icon-radio_button_unchecked cs-color"></i>
                      Malicious plugin removal{' '}
                    </li>
                  </ul>
                  <a
                    style={{
                      color: '#fff',
                      fontSize: '11px',
                      padding: '12px 45px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      border: 'solid 1px',
                      wordSpacing: '3px',
                      letterSpacing: '1px',
                    }}
                    className="cs-color csborder-color"
                    href="#"
                  >
                    Go to home
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="image-frame defualt">
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <img
                        alt="#"
                        src="assets/extra-images/image-frame-2.png"
                      />{' '}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ marginBottom: '53px' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  style={{ color: '#333333', marginBottom: '45px' }}
                  className="section-title"
                >
                  <h2
                    style={{
                      marginBottom: '5px',
                      textTransform: 'uppercase !important',
                    }}
                  >
                    SCHEDULE SERVICE
                  </h2>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <form className="user-post-vehicles row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <input type="text" placeholder="Full Name" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <input type="email" placeholder="Email Address" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <input type="text" placeholder="Phone Number" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <input type="text" placeholder="Make/Model" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <input type="text" placeholder="Mileage (optional)" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <i className="icon-access_time"></i>
                        <input type="text" placeholder="Best time" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cs-field-holder">
                      <div className="cs-field">
                        <div className="cs-btn-submit">
                          <input type="submit" value="Request a service" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                    <div className="cs-field-holder">
                      <p>
                        By submitting this form you will be scheduling a service
                        appointment at no obligation and will be contacted
                        within 48 hours by a service advisor.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <ul className="cs-testimonial-slider">
                  <li>
                    <div className="cs-media">
                      <figure>
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <div className="cs-media">
                      <figure>
                        <img
                          src="assets/extra-images/testimonial-slider-img-1.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="cs-text">
                      <p>
                        Litora torquent per conubia nostra Class aptent taciti
                        sociosqu ad litora torquent per conubia nostra, per
                        inceptos himenaeos.
                      </p>
                      <h6>
                        James Warson<span>Chief Executive Officer </span>
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{ margin: '0', padding: '33px 0', background: '#f8f8f8' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-calltoaction">
                  <h2>CHECK OUT OUR REPAIR SERVICE FOR YOUR CAR</h2>
                  <a href="#" className="csborder-color cs-color has-border">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: '#19171a' }} className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  style={{ textAlign: 'center', padding: '55px 0 25px' }}
                  className="cs-ad"
                >
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <img
                        src="assets/extra-images/cs-ad-img.jpg"
                        alt="#"
                      />{' '}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
