import React from 'react';

const SearchResultPage = () => {
  return (
    <React.Fragment>
      <div
        className="cs-sub-header"
        style={{
          background: '#fafafa',
          borderBottom: '1px solid #eee',
          padding: '20px 0 10px',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-page-title">
                <h2
                  style={{
                    letterSpacing: '0 !important',
                    fontSize: '20px !important',
                    fontWeight: '800 !important',
                  }}
                >
                  Search result
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ paddingTop: '15px' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                <div className="cs-search-result">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="section-title">
                      <h2
                        style={{
                          letterSpacing: '0 !important',
                          marginBottom: '15px',
                        }}
                      >
                        No Pages Were Found Containing "your added text"
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                    <div className="cs-seggetions">
                      <div className="cs-heading">
                        <h4>Suggestions:</h4>
                      </div>
                      <ul>
                        <li>
                          <i className="icon-check cs-color"></i>Make sure all
                          words are spelled correctly
                        </li>
                        <li>
                          <i className="icon-check cs-color"></i>Wildcard
                          searches (using the asterisk *) are not supported
                        </li>
                        <li>
                          <i className="icon-check cs-color"></i>Try more
                          general keywords, especially if you are attempting a
                          name
                        </li>
                      </ul>
                      <div className="cs-form">
                        <form>
                          <div className="input-holder">
                            <input
                              type="text"
                              placeholder="Search by Keyword"
                            />
                            <input type="submit" value="" />
                            <i className="icon-search"></i>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="section-title">
                      <h2
                        style={{
                          letterSpacing: '0 !important',
                          marginBottom: '30px',
                        }}
                      >
                        Most Relevent Links
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-relevent-links">
                      <ul>
                        <li>
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/auto-search-01.jpg"
                                  alt=""
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="cs-text">
                            <span>On January 15, 2023.</span>
                            <h4>
                              <a href="#">
                                Speed Dual-Clutch Featured Special New ReLease
                              </a>
                            </h4>
                            <p>
                              Tempor eleifend augue vulputate posuere ante
                              posuere pharetra augue iaculis proin, platea curae
                              mattis quam donec vivamus mattis sagittis nam
                              vivamus vulputate nullam sodales semper.
                            </p>
                            <a href="#" className="cs-color">
                              http://www.sant.ox.ac.uk/mec/MEClectures/20Constitution.pdf
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/auto-search-02.jpg"
                                  alt=""
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="cs-text">
                            <span>On January 15, 2023.</span>
                            <h4>
                              <a href="#">
                                Speed Dual-Clutch Featured Special New ReLease
                              </a>
                            </h4>
                            <p>
                              Tempor eleifend augue vulputate posuere ante
                              posuere pharetra augue iaculis proin, platea curae
                              mattis quam donec vivamus mattis sagittis nam
                              vivamus vulputate nullam sodales semper.
                            </p>
                            <a href="#" className="cs-color">
                              http://www.sant.ox.ac.uk/mec/MEClectures/20Constitution.pdf
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="section-title">
                      <h2
                        style={{
                          letterSpacing: '0 !important',
                          marginBottom: '30px',
                        }}
                      >
                        Showing Result 1-3 Your Added Text
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-relevent-links">
                      <ul>
                        <li>
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/auto-search-03.jpg"
                                  alt=""
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="cs-text">
                            <span>On January 15, 2023.</span>
                            <h4>
                              <a href="#">
                                Human laws, but we cannot resist natural ones
                              </a>
                            </h4>
                            <p>
                              Tempor eleifend augue vulputate posuere ante
                              posuere pharetra augue iaculis proin, platea curae
                              mattis quam donec vivamus mattis sagittis nam
                              vivamus vulputate nullam sodales semper.
                            </p>
                            <a href="#" className="cs-color">
                              http://www.sant.ox.ac.uk/mec/MEClectures/20Constitution.pdf
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/auto-search-04.jpg"
                                  alt=""
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="cs-text">
                            <span>On January 15, 2023.</span>
                            <h4>
                              <a href="#">
                                Motors Rock! We strive to deliver a superior
                                experience.
                              </a>
                            </h4>
                            <p>
                              Tempor eleifend augue vulputate posuere ante
                              posuere pharetra augue iaculis proin, platea curae
                              mattis quam donec vivamus mattis sagittis nam
                              vivamus vulputate nullam sodales semper.
                            </p>
                            <a href="#" className="cs-color">
                              http://www.sant.ox.ac.uk/mec/MEClectures/20Constitution.pdf
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="cs-media">
                            <figure>
                              <a href="#">
                                <img
                                  src="assets/extra-images/auto-search-05.jpg"
                                  alt=""
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="cs-text">
                            <span>On January 15, 2023.</span>
                            <h4>
                              <a href="#">
                                Fifth consecutive year that Hyundai is
                                recognized.by Ruedas
                              </a>
                            </h4>
                            <p>
                              Tempor eleifend augue vulputate posuere ante
                              posuere pharetra augue iaculis proin, platea curae
                              mattis quam donec vivamus mattis sagittis nam
                              vivamus vulputate nullam sodales semper.
                            </p>
                            <a href="#" className="cs-color">
                              http://www.sant.ox.ac.uk/mec/MEClectures/20Constitution.pdf
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchResultPage;
