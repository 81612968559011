import React from 'react';

const ShopCart = () => {
  return (
    <div className="main-section">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="woocommerce shop-table-responsive">
                <div className="table-responsive">
                  <form action="#">
                    <table className="shop_table shop_table_responsive cart">
                      <thead>
                        <tr>
                          <th className="product-remove">PRODUCT</th>
                          <th className="product-thumbnail">Price</th>
                          <th className="product-name">Quantity</th>
                          <th className="product-price">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-1.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              Brembo Sport Brakes
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$2,000.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-2.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              USB Air Compressor
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$140.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-3.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              Air Filter
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$15.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-4.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              Car speaker
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$20.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-5.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              Car wheel
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$99.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr className="cart_item">
                          <td className="product-remove">
                            <a
                              data-product_sku=""
                              data-product_id="31"
                              title="Remove this item"
                              className="remove"
                              href="#"
                            >
                              ×
                            </a>
                            <a href="#" className="product-thumbnail">
                              <img
                                src="assets/extra-images/product-img-6.jpg"
                                alt="#"
                              />
                            </a>
                            <a href="#" className="product-name">
                              Car Brush
                            </a>
                          </td>
                          <td data-title="Price" className="product-price">
                            <span className="amount cs-color">$2,000.00</span>
                          </td>
                          <td
                            data-title="Quantity"
                            className="product-quantity"
                          >
                            <div className="quantity">
                              <span className="qtyminus">-</span>
                              <input type="text" value="0" className="qty" />
                              <span className="qtyplus">+</span>
                            </div>
                          </td>
                          <td data-title="Total" className="product-subtotal">
                            <span className="amount">$110.00</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="actions" colspan="4">
                            <input
                              type="submit"
                              value="Continue shoping"
                              name="Continue shoping"
                              className="button pull-left"
                            />
                            <input
                              type="submit"
                              value="Update Cart"
                              name="Update Cart"
                              className="button pull-right"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                  <div className="coupon">
                    <label for="coupon_code">Coupon</label>
                    <p>Enter your coupon code if you have one.</p>
                    <input
                      type="text"
                      placeholder="Coupon code"
                      value=""
                      id="coupon_code"
                      className="input-text"
                      name="coupon_code"
                    />
                    <input
                      type="submit"
                      value="Apply Coupon"
                      name="apply_coupon"
                      className="button"
                    />
                  </div>
                  <div className="cart-collaterals">
                    <div className="cart_totals calculated_shipping">
                      <table className="shop_table shop_table_responsive">
                        <tbody>
                          <tr className="cart-subtotal">
                            <th>Sub total:</th>
                            <td data-title="Subtotal">
                              <span className="amount">$559.00</span>
                            </td>
                          </tr>
                          <tr className="order-total">
                            <th>total:</th>
                            <td data-title="Total">
                              <strong>
                                <span className="amount">$559.00</span>
                              </strong>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="wc-proceed-to-checkout">
                        <a
                          className="checkout-button button alt wc-forward"
                          href="#"
                        >
                          Proceed to checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: '#19171a' }} className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                style={{ textAlign: 'center', padding: '55px 0 25px' }}
                className="cs-ad"
              >
                <div className="cs-media">
                  <figure>
                    {' '}
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />{' '}
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCart;
