import React from "react";

const ImageSlider = (props) => {
  const { image, title, amount, msrp } = props;

  return (
    <div className="cs-auto-box-slide">
      <div className="cs-auto-box-inner">
        <div className="cs-media">
          <span className="featured"></span>
          <figure>
            <a href="#">
              <img key={image} src={`assets/extra-images/${image}`} />
            </a>
            <figcaption></figcaption>
          </figure>
          <div className="caption-text">
            <a href="#">
              <h2>{title}</h2>
            </a>
          </div>
        </div>
        <div className="auto-text cs-bgcolor">
          <span>${amount}</span>
          <small>MSRP ${msrp}</small>
          <a href="#" className="cs-button pull-right">
            <i className="icon-arrow_forward"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
