import React, { Fragment } from "react";

const BlogMediumPage = () => {
  return (
    <Fragment>
      <div className="cs-subheader">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-subheader-text">
                <h2>Blog Medium</h2>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li className="active">
                      <a href="#">Latest Blog</a>
                    </li>
                    <li>
                      <a href="#">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section" style={{ paddingTop: "20px" }}>
          <div className="container">
            <div className="row">
              <div className="section-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <div className="content-area">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/blog-medium-img-1.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <div className="caption-text">
                                <span>STICKY POST</span>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                Avalon Hybrid Built for the endless weekend.
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Tempor eleifend augue vulputate posuere ante posuere
                            pharetra augue iaculis proin, platea curae mattis
                            quam donec vivamus mattis sagittis nam vivamus
                            vulputate nullam sodales semper tellus vel ad dui
                            quisque.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Anselm Hannemann</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date"> July 30, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/blog-medium-img-2.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <div className="caption-text">
                                <i className="icon-audiotrack"></i>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                We strive to deliver a superior experience with
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Et elementum suscipit lectus scelerisque consectetur
                            condimentum eget sagittis taciti proin eget,
                            praesent orci est conubia non rhoncus accumsan
                            hendrerit posuere elementum lectus, laoreet sagittis
                            nunc eros.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Katya Dihich</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">8 Comments</a>
                            </span>
                            <span className="post-date"> June 15, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media blog-medium-slider">
                          <figure>
                            <img
                              src="assets/extra-images/blog-medium-img-3.jpg"
                              alt="#"
                            />
                          </figure>
                          <figure>
                            <img
                              src="assets/extra-images/blog-medium-img-3.jpg"
                              alt="#"
                            />
                          </figure>
                        </div>
                        <div className="post-option">
                          <em>8 Photos</em>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                The Tesla Model S isn’t the first truly
                                autonomous{" "}
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Ullamcorper himenaeos in pretium faucibus id
                            eleifend lorem convallis ornare eget, ornare diam
                            tempor suscipit urna justo donec curabitur euismod,
                            praesent viverra metus lectus sodales accumsan
                            augue.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Boris Borisov</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date"> Auguest 30, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/blog-medium-img-4.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption></figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                Motors Rock! We strive to deliver a superior
                                experi
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Et elementum suscipit lectus scelerisque consectetur
                            condimentum eget sagittis taciti proin eget,
                            praesent orci est conubia non rhoncus accumsan
                            hendrerit posuere elementum lectus, laoreet sagittis
                            nunc.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Rahul Bhadauria</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date">
                              {" "}
                              September 12, 2023
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/blog-medium-img-5.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <div className="caption-text">
                                <i className="icon-audiotrack"></i>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                Avalon Hybrid Built for the endless weekend.
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Tempor eleifend augue vulputate posuere ante posuere
                            pharetra augue iaculis proin, platea curae mattis
                            quam donec vivamus mattis sagittis nam vivamus
                            vulputate nullam sodales semper tellus vel ad dui
                            quisque.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Alexandre Pinot</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date"> July 30, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                Fifth consecutive year that Hyundai is
                                recognized.by Ruedas
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Ligula non tempus nunc tellus accumsan torquent mi
                            nisi, pulvinar enim dictum magna nisl torquent
                            tellus sit, cursus rutrum curabitur ultrices
                            elementum curabitur vestibulum. Diam phasellus morbi
                            quisque pharetra. donec curabitur ornare, et felis
                            lobortis metus porta class, eget pretium elementum
                            nullam enim blandit ornare gravida turpis.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Tiago Costa</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date">
                              {" "}
                              November 18, 2023
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="blog-listing medium-view">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/blog-medium-img-6.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption></figcaption>
                          </figure>
                        </div>
                        <div className="cs-text">
                          <div className="post-title">
                            <h4>
                              <a href="#">
                                Largest Selection of New Hyundai’s in the
                                Greater
                              </a>
                            </h4>
                          </div>
                          <ul className="cs-auto-categories">
                            <li>
                              <a href="#" className="cs-color">
                                Vehicles
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                CAr
                              </a>
                            </li>
                            <li>
                              <a href="#" className="cs-color">
                                audi
                              </a>
                            </li>
                          </ul>
                          <p>
                            Commodo blandit mauris taciti placerat proin dictum
                            habitant hendrerit nisl, id eu dictumst venenatis
                            nec ante sed in etiam eleifend, turpis bibendum
                            tortor vivamus etiam proin per turpis sapien
                            vestibulum luctus.
                          </p>
                          <div className="post-detail">
                            <span className="post-author">
                              <i className="icon-user4"></i>{" "}
                              <a href="#">Ghani Pradita</a>
                            </span>
                            <span className="post-comments">
                              <a href="#">0 Comments</a>
                            </span>
                            <span className="post-date">December 30, 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="section-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <div className="widget-search">
                  <div className="input-holder">
                    <input type="text" placeholder="Enter any keyword" />
                    <label>
                      <input type="submit" value="" />
                    </label>
                  </div>
                </div>
                <div className="widget widget-recent-posts">
                  <h6>Recent Posts</h6>
                  <ul>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Nissan Rogue SV AWD Review: best So Roguish What Price
                          Supercar-dom?
                        </a>
                        <span>
                          <i className="icon-clock5"></i>3 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Nissan Rogue SV AWD Review: best So Roguish What Price
                          Supercar-dom?
                        </a>
                        <span>
                          <i className="icon-clock5"></i>27 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Audi Switches Focus to e-tron, Delays Other Models
                        </a>
                        <span>
                          <i className="icon-clock5"></i>58 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Gripping News: Mini Adds All-Wheel-Drive Option to New
                          Clubman
                        </a>
                        <span>
                          <i className="icon-clock5"></i>23 Days Ago
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/recent-widget-5.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <a href="#">
                          Audi Switches Focus to e-tron, Delays Other Models
                        </a>
                        <span>
                          <i className="icon-clock5"></i>23 Days Ago
                        </span>
                      </div>
                    </li>
                  </ul>
                  <a href="#." className="cs-view-blog">
                    View all Blogs
                  </a>
                </div>
                <div className="widget widget-tags">
                  <h6>Tag Cloud</h6>
                  <a href="#">Houses</a>
                  <a href="#">Apartment</a>
                  <a href="#">Rates</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Rates</a>
                  <a href="#">Flats</a>
                  <a href="#">People</a>
                  <a href="#">United Kingdom</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Flats</a>
                  <a href="#">Houses</a>
                  <a href="#">Apartment</a>
                  <a href="#">Rates</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                  <a href="#">Road</a>
                  <a href="#">Flats</a>
                  <a href="#">People</a>
                  <a href="#">United Kingdom</a>
                  <a href="#">Commercial</a>
                  <a href="#">Building</a>
                </div>
                <div className="widget widget-categories">
                  <h6>Top Categores</h6>
                  <ul>
                    <li>
                      <a href="#">Motorbikes</a>
                    </li>
                    <li>
                      <a href="#">Compacts</a>
                    </li>
                    <li>
                      <a href="#">Sedans</a>
                    </li>
                    <li>
                      <a href="#">4x4 SUVs</a>
                    </li>
                    <li>
                      <a href="#">Pickups</a>
                    </li>
                    <li>
                      <a href="#">Vans &amp; Trucks</a>
                    </li>
                    <li>
                      <a href="#">Buy a car</a>
                    </li>
                    <li>
                      <a href="#">Sell your Car</a>
                    </li>
                    <li>
                      <a href="#">Buy Back</a>
                    </li>
                    <li>
                      <a href="#">Buy a car</a>
                    </li>
                    <li>
                      <a href="#">Sell your Car</a>
                    </li>
                    <li>
                      <a href="#">Buy Back</a>
                    </li>
                    <li>
                      <a href="#">Repair Shop</a>
                    </li>
                    <li>
                      <a href="#">Car Land</a>
                    </li>
                    <li>
                      <a href="#">Car Showrooms</a>
                    </li>
                    <li>
                      <a href="#">Repair Shop</a>
                    </li>
                  </ul>
                </div>
                <div className="widget featured-listing">
                  <h6>Featured Listings</h6>
                  <ul>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-1.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ferrari 360 Modena 2dr F1 3.6</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-2.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Mborghini Aventador LP700-4</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-3.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ford Fiesta 1.6 TDCi Zetec 5dr</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-media">
                        <figure>
                          <a href="#">
                            <img
                              src="assets/extra-images/featured-listing-4.jpg"
                              alt="#"
                            />
                          </a>
                        </figure>
                      </div>
                      <div className="cs-text">
                        <h6>
                          <a href="#">Ford Fiesta 1.6 TDCi Zetec 5dr</a>
                        </h6>
                        <span className="cs-color">£6,749.00</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: "#19171a" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  className="cs-ad"
                  style={{ textAlign: "center", padding: "55px 0 32px" }}
                >
                  <div className="cs-media">
                    <figure>
                      <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogMediumPage;
