import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ImageSlider from "../image-slider";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
// import Carousel from "nuka-carousel";

// const style = { height: "260px", width: "100%" };

// const options = {
//   loop: true,
//   margin: 8,
//   autoplay: true,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     600: {
//       items: 3,
//     },
//   },
// };

const CarouselSlider = () => {
  return (
    <OwlCarousel
      items={3}
      autoplay={true}
      loop
      responsive={{
        1: {
          items: 1,
        },
        700: { items: 3 },
      }}
    >
      <ImageSlider
        image="latest-model-01.jpg"
        title="Avalon Hybrid Built for the endless weekend."
        amount="28,000"
        msrp="35,000"
      />
      <ImageSlider
        image="latest-model-02.jpg"
        title="All New BMW ADAPTIVE DRIVE HEAD uP DISPLAY."
        amount="24,000"
        msrp="30,000"
      />
      <ImageSlider
        image="latest-model-01.jpg"
        title="Avalon Hybrid Built for the endless weekend."
        amount="28,000"
        msrp="35,000"
      />
      <ImageSlider
        image="latest-model-02.jpg"
        title="Avalon Hybrid Built for the endless weekend."
        amount="26,000"
        msrp="31,000"
      />
      <ImageSlider
        image="latest-model-01.jpg"
        title="Avalon Hybrid Built for the endless weekend."
        amount="28,000"
        msrp="35,000"
      />
      <ImageSlider
        image="latest-model-02.jpg"
        title="Avalon Hybrid Built for the endless weekend."
        amount="28,000"
        msrp="35,000"
      />
    </OwlCarousel>

    // <Carousel>
    //   {images.map((image) => (
    //     <div className="cs-auto-box-slide">
    //       <div className="cs-auto-box-inner">
    //         <div className="cs-media">
    //           <span className="featured"></span>
    //           <figure>
    //             <a href="#">
    //               <img key={image} src={`assets/extra-images/${image}`} />
    //             </a>
    //             <figcaption></figcaption>
    //           </figure>
    //           <div className="caption-text">
    //             <a href="#">
    //               <h2>Avalon Hybrid Built for the endless weekend.</h2>
    //             </a>
    //           </div>
    //         </div>
    //         <div className="auto-text cs-bgcolor">
    //           <span>$28,000</span>
    //           <small>MSRP $35,000</small>
    //           <a href="#" className="cs-button pull-right">
    //             <i className="icon-arrow_forward"></i>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </Carousel>
  );
};

export default CarouselSlider;
