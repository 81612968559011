import React from "react";
import UserNavbar from "../../../components/user-navbar";

const UserPackages = () => {
  return (
    <div className="main-section">
      <div
        className="page-section"
        style={{
          background: "url(assets/extra-images/user-bg-img.jpg) no-repeat",
          backgroundSize: "cover",
          minHeight: "175px",
          marginTop: "-30px",
          marginBottom: "-129px",
        }}
      ></div>
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-user-account-holder">
                <div className="row">
                  <UserNavbar />
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-user-section-title">
                      <h4>Packages</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="packages-list">
                      <ul>
                        <li>
                          <div className="packages-label">
                            <ul>
                              <li>Packege</li>
                              <li>Trans ID</li>
                              <li>Date</li>
                              <li>Total Ads</li>
                              <li>Used</li>
                              <li>Remaning</li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="packages-content">
                            <ul>
                              <li>
                                <b>human trafficking</b>
                                <em style={{ color: "#d94551" }}>Acytive</em>
                              </li>
                              <li>AB1352CC</li>
                              <li>23.12.2023</li>
                              <li>5</li>
                              <li>5</li>
                              <li>Nil</li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="packages-content">
                            <ul>
                              <li>
                                <b>Featured + Standard</b>
                              </li>
                              <li>B1352CC</li>
                              <li>26.10.2023</li>
                              <li>5</li>
                              <li>5</li>
                              <li>Nil</li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="packages-content">
                            <ul>
                              <li>
                                <b>Basic Packege</b>
                                <em style={{ color: "#ff9000" }}>Pending</em>
                              </li>
                              <li>AB1352</li>
                              <li>8.8.2023</li>
                              <li>5</li>
                              <li>5</li>
                              <li>Nil</li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="packages-content">
                            <ul>
                              <li>
                                <b>Basic Packege</b>
                              </li>
                              <li>1352CC</li>
                              <li>26.10.2023</li>
                              <li>5</li>
                              <li>5</li>
                              <li>Nil</li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="packages-content">
                            <ul>
                              <li>
                                <b>Premium</b>
                              </li>
                              <li>AB1352CC</li>
                              <li>8.8.2023</li>
                              <li>5</li>
                              <li>5</li>
                              <li>Nil</li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPackages;
