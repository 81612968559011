import React from "react";

const CompareCarsPage = () => {
  return (
    <React.Fragment>
      <div
        className="cs-subheader"
        style={{
          backgroundColor: "#fafafa",
          borderBottom: "1px solid #eee",
          padding: "20px 0",
        }}
      >
        <div className="container">
          <div className="cs-page-title pull-left">
            <h3>Honda Alfa Romeo</h3>
          </div>
          <div className="cs-breadcrumbs pull-right">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Latest Cars</a>
              </li>
              <li>Search Results</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section">
          <div className="container">
            <div className="row">
              <div className="section-fullwidth col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-compare">
                  <ul className="cs-compare-list">
                    <li>
                      <div className="cs-compare-box">
                        <div className="Specification-logo">
                          <img
                            src="assets/extra-images/compare-logo.png"
                            alt="#"
                          />
                        </div>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/compare1.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <a href="#" className="cs-bgcolor">
                                <i className="icon-cross2"></i>
                              </a>
                            </figcaption>
                          </figure>
                        </div>
                        <span className="cs-vs">VS</span>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/compare2.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <a href="#" className="cs-bgcolor">
                                <i className="icon-cross2"></i>
                              </a>
                            </figcaption>
                          </figure>
                        </div>
                        <span className="cs-vs">VS</span>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-media">
                          <figure>
                            <a href="#">
                              <img
                                src="assets/extra-images/compare3.jpg"
                                alt="#"
                              />
                            </a>
                            <figcaption>
                              <a href="#" className="cs-bgcolor">
                                <i className="icon-cross2"></i>
                              </a>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <p className="label">BASIC INFO</p>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-post-title">
                          <h6>
                            <a href="#">Toyota Avalon LTX, Leather Sunroof</a>
                          </h6>
                        </div>
                        <div className="cs-price">
                          <strong className="cs-color">$20,045</strong>
                          <em>MSRP $32,000</em>
                        </div>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-post-title">
                          <h6>
                            <a href="#">Kia Sorento 4WD, Good condition</a>
                          </h6>
                        </div>
                        <div className="cs-price">
                          <strong className="cs-color">$20,045</strong>
                          <em>MSRP $32,000</em>
                        </div>
                      </div>
                      <div className="cs-compare-box">
                        <div className="cs-post-title">
                          <h6>
                            <a href="#">
                              Hyundai Genesis, Full Options, Leather
                            </a>
                          </h6>
                        </div>
                        <div className="cs-price">
                          <strong className="cs-color">$20,045</strong>
                          <em>MSRP $32,000</em>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Body Type</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>Seden</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Convertible</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Seden</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Availability</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>Yes</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>N/A</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Yes</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">MSRP 17050</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>Destination Charge</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Destination Charge</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Destination Charge</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Destination Charge</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>$1,595</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>$1,595</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>$1,595</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Invoice Price</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>N/A</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>N/A</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>N/A</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Available Engines</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>237-hp 1.7-liter I-4 (premium)</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>237-hp 1.7-liter I-4 (premium)</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>237-hp 1.7-liter I-4 (premium)</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">
                          Hp 4.8-liter V-8 (premium)
                        </small>
                      </div>
                      <div className="cs-compare-box">
                        <span>500-hp 4.8-liter V-8 (premium)</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>500-hp 4.8-liter V-8 (premium)</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>500-hp 4.8-liter V-8 (premium)</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Transmissions</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>6-spd auto-shift man</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>6-spd auto-shift man</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>6-spd auto-shift man</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Fuel Economy CitY</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Fuel Economy Highway</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Consumer Rating</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Safety Features</small>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>N/A BMW Assist</li>
                          <li>Anti-lock brakes (ABS)</li>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                        </ul>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                        </ul>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                          <li>Standard systems</li>
                        </ul>
                      </div>
                    </li>
                    <li className="cs-compare-sec-title">
                      <div className="cs-compare-box">
                        <h6>Engine and Drive Train</h6>
                      </div>
                      <div className="cs-compare-box"></div>
                      <div className="cs-compare-box"></div>
                      <div className="cs-compare-box"></div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Tachometer</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Available Transmissions</small>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>N/A BMW Assist</li>
                          <li>Anti-lock brakes (ABS)</li>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                        </ul>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                        </ul>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                          <li>Standard systems</li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Fuel Capacity / Type</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>21.7 gal. / Flex-fuel</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>21.7 gal. / Flex-fuel</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>21.7 gal. / Flex-fuel</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Fuel Economy Highway</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>24 mpg</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Child Safety Door Locks</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>34 mpg</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Power Door Locks</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>(4.9) 17 reviews</span>
                      </div>
                    </li>
                    <li className="cs-compare-sec-title">
                      <div className="cs-compare-box">
                        <h6>Transmission</h6>
                      </div>
                      <div className="cs-compare-box"></div>
                      <div className="cs-compare-box"></div>
                      <div className="cs-compare-box"></div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">Weight</small>
                      </div>
                      <div className="cs-compare-box">
                        <span>Sport gauge cluster</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Sport gauge cluster</span>
                      </div>
                      <div className="cs-compare-box">
                        <span>Sport gauge cluster</span>
                      </div>
                    </li>
                    <li>
                      <div className="cs-compare-box">
                        <small className="label">MileagE</small>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>N/A BMW Assist</li>
                          <li>Anti-lock brakes (ABS)</li>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                        </ul>
                        <a href="#" className="cs-view-btn cs-bgcolor">
                          View detail
                        </a>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Standard Standard</li>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                        </ul>
                        <a href="#" className="cs-view-btn cs-bgcolor">
                          View detail
                        </a>
                      </div>
                      <div className="cs-compare-box">
                        <ul>
                          <li>Anti-theft system</li>
                          <li>Optional Optional</li>
                          <li>Child seat anchors</li>
                          <li>Standard systems</li>
                        </ul>
                        <a href="#" className="cs-view-btn cs-bgcolor">
                          View detail
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ background: "#19171a" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="cs-ad"
                  style={{ textAlign: "center", padding: "55px 0 32px" }}
                >
                  <div className="cs-media">
                    <figure>
                      <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompareCarsPage;
