import React from 'react';
const ShopListing = () => {
  return (
    <div className="main-section">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="section-fullwidth col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="row">
                <div className="page-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="site-main">
                        <div className="columns-3">
                          <ul className="products">
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop1.jpg"
                                  alt="shop"
                                />
                                <h5>Brembo Sport Brakes</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop2.jpg"
                                  alt="shop"
                                />
                                <h5>USB Air Compressor</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop3.jpg"
                                  alt="shop"
                                />
                                <h5>Ninja Sound</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop4.jpg"
                                  alt="shop"
                                />
                                <h5>Air Filter</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop5.jpg"
                                  alt="shop"
                                />
                                <h5>Car brush</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop6.jpg"
                                  alt="shop"
                                />
                                <h5>Car mats for BMW F10</h5>
                                <span className="price">
                                  <ins>
                                    <span className="amount">$99.00</span>
                                  </ins>
                                  <del>
                                    <span className="amount">$150.00</span>
                                  </del>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop7.jpg"
                                  alt="shop"
                                />
                                <h5>Car Wheel R18</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop8.jpg"
                                  alt="shop"
                                />
                                <h5>Custom spark-plug</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                            <li className="product">
                              <a href="#">
                                <img
                                  src="assets/extra-images/shop9.jpg"
                                  alt="shop"
                                />
                                <h5>Ninja Rims R20</h5>
                                <span className="price">
                                  <span className="amount">$2,000.00</span>
                                </span>
                                <div
                                  title="Rated 5 out of 5"
                                  className="star-rating"
                                >
                                  <span style={{ width: '66%' }}>
                                    <strong className="rating">5</strong> out of
                                    5
                                  </span>
                                </div>
                              </a>
                              <div className="product-action-button">
                                <a
                                  className="cs-color btn btn-flat button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  href="#"
                                >
                                  <i className="icon-shopping-cart2 cs-bgcolor"></i>
                                  Add to cart
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <nav>
                          <ul className="pagination">
                            <li>
                              <a href="#" aria-label="Previous">
                                <span aria-hidden="true">
                                  <i className="icon-angle-left"></i>
                                </span>
                              </a>
                            </li>
                            <li>
                              <a className="active">1</a>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">3</a>
                            </li>
                            <li>
                              <a href="#">4</a>
                            </li>
                            <li>
                              <a href="#">5</a>
                            </li>
                            <li>
                              <a href="#" aria-label="Next">
                                <span aria-hidden="true">
                                  <i className="icon-angle-right"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <aside className="page-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="widget woocommerce widget_product_categories">
                    <h6>Product Categories</h6>
                    <ul>
                      <li className="cat-item cat-item-3 cat-parent">
                        <a href="#">
                          Clothes &amp; Footwear <span>(17)</span>
                        </a>
                        <ul className="children">
                          <li>
                            <a href="#">
                              Footwear <span>(3)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              Hoodies <span>(7)</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              T-shirts <span>(7)</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">
                          Digital goods <span>(3)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="widget woocommerce widget_top_rated_products">
                    <h6>latest Products</h6>
                    <ul className="product_list_widget">
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget1.jpg"
                            alt="shop"
                          />
                          <span className="product-title">
                            USB Air Compressor
                          </span>
                        </a>
                        <span className="amount cs-color">$500.00</span>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget2.jpg"
                            alt="shop"
                          />
                          <span className="product-title">Ninja Sound</span>
                        </a>
                        <span className="amount cs-color">$800.00</span>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="assets/extra-images/Shop-widget3.jpg"
                            alt="shop"
                          />
                          <span className="product-title">
                            Car mats for BMW
                          </span>
                        </a>
                        <span className="amount cs-color">$900.00</span>
                      </li>
                    </ul>
                  </div>
                  <div className="widget woocommerce widget_shopping_cart">
                    <h6>Top Rated Products</h6>
                    <div className="widget_shopping_cart_content">
                      <ul className="cart_list product_list_widget">
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              alt="shop"
                              src="assets/extra-images/Shop-widget4.jpg"
                            />
                            Brembo Sport Brakes
                          </a>
                          <span className="amount">$500.00</span>
                          <div className="star-rating" title="Rated 5 out of 5">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              alt="shop"
                              src="assets/extra-images/Shop-widget5.jpg"
                            />
                            Custom spark-plug
                          </a>
                          <span className="amount">$800.00</span>
                          <div className="star-rating" title="Rated 5 out of 5">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                        <li className="mini_cart_item">
                          <a href="#">
                            <img
                              alt="shop"
                              src="assets/extra-images/Shop-widget6.jpg"
                            />
                            Air Filter
                          </a>
                          <span className="amount">$900.00</span>
                          <div className="star-rating" title="Rated 5 out of 5">
                            <span style={{ width: '66%' }}>
                              <strong className="rating">5</strong> out of 5
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget widget-tags woocommerce">
                    <h6>PRODUCTS TAG</h6>
                    <a href="#">Houses</a>
                    <a href="#">Apartment</a>
                    <a href="#">Rates</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Rates</a>
                    <a href="#">Flats</a>
                    <a href="#">People</a>
                    <a href="#">United Kingdom</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Flats</a>
                    <a href="#">Houses</a>
                    <a href="#">Apartment</a>
                    <a href="#">Rates</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                    <a href="#">Road</a>
                    <a href="#">Flats</a>
                    <a href="#">People</a>
                    <a href="#">United Kingdom</a>
                    <a href="#">Commercial</a>
                    <a href="#">Building</a>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: '#19171a' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{
                  textAlign: 'MozContextProperties',
                  padding: '55px 0 25px',
                }}
              >
                <div className="cs-media">
                  <figure>
                    <a href="#">
                      <img alt="" src="assets/extra-images/cs-ad-img.jpg" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShopListing;
