import React, { Fragment } from 'react';

const ListingPackages = () => {
  return (
    <Fragment>
      <div
        className="cs-subheader center"
        style={{
          background:
            'url(assets/extra-images/bg-subheader.jpg) no-repeat 0 0 /cover',
          minheight: '296px',
          padding: '90px 0 0',
          marginbottom: '50px',
        }}
      >
        <div className="container">
          <div className="cs-page-title">
            <h1 style={{ color: '#fff !important' }}>Our Listing Packages</h1>
            <p sstyle={{ color: '#fff' }}>
              If you think we might be a good fit for a project you’re working
              on and you’d like to get the feel of how we
              <br /> can work together please contact us using contact data.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services top-center">
                  <div className="cs-media">
                    {' '}
                    <i
                      className="icon-car36 cs-color"
                      style={{ fontSize: '60px' }}
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>BRAKE SERVICE</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Culpa, velit, eum, delectus aliquid dolore numquam dolorem
                      assumenda nisi nemo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services top-center">
                  <div className="cs-media">
                    {' '}
                    <i
                      className=" icon-speedometer cs-color"
                      style={{ fontSize: '60px' }}
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>TRANSMISSION</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Culpa, velit, eum, delectus aliquid dolore numquam dolorem
                      assumenda nisi nemo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                <div className="cs-services top-center">
                  <div className="cs-media">
                    {' '}
                    <i
                      className="icon-transport177 cs-color"
                      style={{ fontSize: '60px' }}
                    >
                      {' '}
                    </i>{' '}
                  </div>
                  <div className="cs-text">
                    <h6>TIRE &amp; WHEEL SERVICE</h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Culpa, velit, eum, delectus aliquid dolore numquam dolorem
                      assumenda nisi nemo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: '30px' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="section-title"
                  style={{
                    textAlign: 'center',
                    color: '#333333',
                    marginBottom: '70px',
                    paddingTop: '30px',
                  }}
                >
                  <h1 style={{ marginBottom: '5px' }}>Our listing packages</h1>
                  <p style={{ color: '#999 !important' }}>
                    Metus ad tempor convallis felis tellus curae taciti mauris,
                    bibendum class aliquam
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                <div className="pricetable-holder modren first-element">
                  <h2>Starter</h2>
                  <div className="price-holder ">
                    <div className="cs-price">
                      <span className="cs-color">
                        <sup className="cs-color">$</sup>199<em>Month</em>
                      </span>
                      <ul>
                        <li>Single Item Access</li>
                        <li>Starter Resource Files</li>
                        <li>Free Premium Courses</li>
                        <li>1 Year License</li>
                        <li>Phone & Email Support</li>
                      </ul>
                    </div>
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                <div className="pricetable-holder modren active">
                  <h2>Professional</h2>
                  <div className="price-holder ">
                    <div className="cs-price">
                      <span className="cs-color">
                        <sup className="cs-color">$</sup>299<em>Month</em>
                      </span>
                      <ul>
                        <li>Single Item Access</li>
                        <li>Starter Resource Files</li>
                        <li>Free Premium Courses</li>
                        <li>1 Year License</li>
                        <li>Phone & Email Support</li>
                      </ul>
                    </div>
                    <a href="#" className="cs-color csborder-color">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                <div className="pricetable-holder modren">
                  <h2>Business</h2>
                  <div className="price-holder ">
                    <div className="cs-price">
                      <span className="cs-color">
                        <sup className="cs-color">$</sup>499<em>Month</em>
                      </span>
                      <ul>
                        <li>Single Item Access</li>
                        <li>Starter Resource Files</li>
                        <li>Free Premium Courses</li>
                        <li>1 Year License</li>
                        <li>Phone & Email Support</li>
                      </ul>
                    </div>
                    <a href="#" className="cs-color csborder-color">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                <div className="pricetable-holder modren last-element">
                  <h2>Enterprise</h2>
                  <div className="price-holder ">
                    <div className="cs-price">
                      <span className="cs-color">
                        <sup className="cs-color">$</sup>499<em>Month</em>
                      </span>
                      <ul>
                        <li>Single Item Access</li>
                        <li>Starter Resource Files</li>
                        <li>Free Premium Courses</li>
                        <li>1 Year License</li>
                        <li>Phone & Email Support</li>
                      </ul>
                    </div>
                    <a href="#" className="cs-color csborder-color">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{ background: '#ecf3f6', marginBottom: '30px' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <i className="icon-truck2 cs-color"> </i>{' '}
                    </figure>
                  </div>
                  <div className="cs-text">
                    {' '}
                    <strong className="counter">3,863</strong>{' '}
                    <span>Car Sold</span>{' '}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <i className="icon-car36  cs-color"> </i>{' '}
                    </figure>
                  </div>
                  <div className="cs-text">
                    {' '}
                    <strong className="counter">89,897</strong>{' '}
                    <span>Car Sold</span>{' '}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <i className="icon-settings  cs-color"> </i>{' '}
                    </figure>
                  </div>
                  <div className="cs-text">
                    {' '}
                    <strong className="counter">741</strong>{' '}
                    <span>Registerd Agents</span>{' '}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="cs-counter">
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <i className="icon-directions_car cs-color"> </i>{' '}
                    </figure>
                  </div>
                  <div className="cs-text">
                    {' '}
                    <strong className="counter">3289</strong>{' '}
                    <span>Certified Agents</span>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: '40px' }}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  className="section-title"
                  style={{
                    textAlign: 'center',
                    color: '#333333',
                    paddingBottom: '30px',
                    paddingTop: '30px',
                  }}
                >
                  <h1 style={{ marginBottom: '5px' }}>ALL PLANS INCLUDE </h1>
                  <p style={{ color: '#999 !important' }}>
                    Nullam ut aliquet felis. Vestibulum eu orci ut lectus
                    placerat ornare eu sed odio.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <ul className="cs-icon-list">
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated daily
                    backups
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated WordPress
                    updates
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>DDoS & intrusion
                    protection
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Malicious plugin
                    removal{' '}
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <ul className="cs-icon-list">
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated daily
                    backups
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated WordPress
                    updates
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>DDoS & intrusion
                    protection
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Malicious plugin
                    removal{' '}
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <ul className="cs-icon-list">
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated daily
                    backups
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Automated WordPress
                    updates
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>DDoS & intrusion
                    protection
                  </li>
                  <li>
                    <i className="icon-check2 cs-color"></i>Malicious plugin
                    removal{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{ margin: '0', padding: '35px 0', background: '#f8f8f8' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-calltoaction">
                  <h2>CHECK OUT OUR REPAIR SERVICE FOR YOUR CAR</h2>
                  <a href="#" className="cs-bgcolor">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: '#19171a' }} className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div
                  style={{ textAlign: 'center', padding: '55px 0 25px' }}
                  className="cs-ad"
                >
                  <div className="cs-media">
                    <figure>
                      {' '}
                      <img
                        src="assets/extra-images/cs-ad-img.jpg"
                        alt="#"
                      />{' '}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListingPackages;
