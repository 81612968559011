import React from "react";
import { Link } from "react-router-dom";
import InventoryNav from "../../../components/inventory-nav";

const InventoryGridPage = () => {
  return (
    <div className="main-section">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <aside className="section-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
              <div className="cs-listing-filters">
                <div className="cs-search">
                  <form className="search-form">
                    <div className="loction-search">
                      <input type="text" placeholder="Select Location" />
                      <a href="#">
                        <i className="icon-hair-cross cs-color"></i>
                      </a>
                    </div>
                    <div className="select-input">
                      <select
                        data-placeholder="Select Make"
                        tabIndex="1"
                        className="chosen-select"
                      >
                        <option>Select make</option>
                        <option>Select make</option>
                        <option>Select make</option>
                        <option>Select make</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className="cs-select-model">
                  <div className="cs-filter-title">
                    <h6>Select Model</h6>
                  </div>
                  <form className="foo foo--inside">
                    <ul className="cs-checkbox-list">
                      <li>
                        <div className="checkbox">
                          <input id="checkbox" type="checkbox" />
                          <label htmlFor="checkbox">5-Speed Automatic</label>
                          <span>(34)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox1" type="checkbox" />
                          <label htmlFor="checkbox1">5-Speed Manual</label>
                          <span>(12)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox2" type="checkbox" />
                          <label htmlFor="checkbox2">6-Speed Automatic</label>
                          <span>(45)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox3" type="checkbox" />
                          <label htmlFor="checkbox3">6-Speed Manual</label>
                          <span>(32)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox4" type="checkbox" />
                          <label htmlFor="checkbox4">6-Speed Semi-Auto</label>
                          <span>(102)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox5" type="checkbox" />
                          <label htmlFor="checkbox5">7-Speed PDK</label>
                          <span>(122)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox6" type="checkbox" />
                          <label htmlFor="checkbox6">8-Speed Automatic</label>
                          <span>(34)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox7" type="checkbox" />
                          <label htmlFor="checkbox7">8-Speed Tiptronic</label>
                          <span>(12)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox8" type="checkbox" />
                          <label htmlFor="checkbox8">5-Speed Automatic</label>
                          <span>(34)</span>
                        </div>
                      </li>
                      <li>
                        <div className="checkbox">
                          <input id="checkbox9" type="checkbox" />
                          <label htmlFor="checkbox9">5-Speed Automatic</label>
                          <span>(34)</span>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>
                <div className="panel-group" id="accordion">
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingOne">
                      <a
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Model year
                      </a>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div className="panel-body">
                        <div className="cs-model-year">
                          <div className="cs-select-filed">
                            <select
                              data-placeholder="Recent Added"
                              className="chosen-select-no-single"
                              tabIndex="5"
                            >
                              <option>2003</option>
                              <option>2004</option>
                              <option>2005</option>
                              <option>2006</option>
                            </select>
                          </div>
                          <span>to</span>
                          <div className="cs-select-filed">
                            <select
                              data-placeholder="Recent Added"
                              className="chosen-select-no-single"
                              tabIndex="5"
                            >
                              <option>2006</option>
                              <option>2005</option>
                              <option>2004</option>
                              <option>2003</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingTwo">
                      <a
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapseTwo"
                        aria-controls="collapseTwo"
                      >
                        Body Style
                      </a>
                    </div>
                    <div
                      id="collapseTwo"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingTwo"
                    >
                      <div className="panel-body">
                        <div className="cs-carbody-style">
                          <form>
                            <ul className="cs-checkbox-list">
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox10" type="checkbox" />
                                  <label htmlFor="checkbox10">
                                    <img
                                      src="assets/extra-images/car-select-img1.jpg"
                                      alt="#"
                                    />
                                    <span>Medium Cars</span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox11" type="checkbox" />
                                  <label htmlFor="checkbox11">
                                    <img
                                      src="assets/extra-images/car-select-img2.jpg"
                                      alt="#"
                                    />
                                    <span>Large Cars</span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox12" type="checkbox" />
                                  <label htmlFor="checkbox12">
                                    <img
                                      src="assets/extra-images/car-select-img3.jpg"
                                      alt="#"
                                    />
                                    <span>Sports Cars</span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox13" type="checkbox" />
                                  <label htmlFor="checkbox13">
                                    <img
                                      src="assets/extra-images/car-select-img4.jpg"
                                      alt="#"
                                    />
                                    <span>People Movers</span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox14" type="checkbox" />
                                  <label htmlFor="checkbox14">
                                    <img
                                      src="assets/extra-images/car-select-img5.jpg"
                                      alt="#"
                                    />
                                    <span>Covertibles</span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox15" type="checkbox" />
                                  <label htmlFor="checkbox15">
                                    <img
                                      src="assets/extra-images/car-select-img6.jpg"
                                      alt="#"
                                    />
                                    <span>Luxury Cars</span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingThree">
                      <a
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapseThree"
                        aria-controls="collapseThree"
                      >
                        PRICE RANGE
                      </a>
                    </div>
                    <div
                      id="collapseThree"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingThree"
                    >
                      <div className="panel-body">
                        <div className="cs-price-range">
                          <form>
                            <input
                              id="price"
                              type="text"
                              className="span2"
                              value="3000"
                              data-slider-min="10"
                              data-slider-max="1000"
                              data-slider-step="5"
                              data-slider-value="[600,200]"
                            />
                            <div className="selector-value">
                              <span>$60,000</span>
                              <span className="pull-right">$20,000</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingfoure">
                      <a
                        className="collapsed"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapsefoure"
                        aria-expanded="false"
                        aria-controls="collapsefoure"
                      >
                        Transmission
                      </a>
                    </div>
                    <div
                      id="collapsefoure"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingfoure"
                    >
                      <div className="panel-body">
                        <div className="cs-select-transmission">
                          <form>
                            <ul className="cs-checkbox-list">
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox16" type="checkbox" />
                                  <label htmlFor="checkbox16">
                                    5-Speed Automatic
                                  </label>
                                  <span>(34)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox17" type="checkbox" />
                                  <label htmlFor="checkbox17">
                                    5-Speed Manual
                                  </label>
                                  <span>(12)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox18" type="checkbox" />
                                  <label htmlFor="checkbox18">
                                    6-Speed Automatic
                                  </label>
                                  <span>(45)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox19" type="checkbox" />
                                  <label htmlFor="checkbox19">
                                    6-Speed Manual
                                  </label>
                                  <span>(32)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox20" type="checkbox" />
                                  <label htmlFor="checkbox20">
                                    6-Speed Semi-Auto
                                  </label>
                                  <span>(102)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox21" type="checkbox" />
                                  <label htmlFor="checkbox21">
                                    7-Speed PDK
                                  </label>
                                  <span>(122)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox22" type="checkbox" />
                                  <label htmlFor="checkbox22">
                                    8-Speed Automatic
                                  </label>
                                  <span>(34)</span>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox23" type="checkbox" />
                                  <label htmlFor="checkbox23">
                                    8-Speed Tiptronic
                                  </label>
                                  <span>(12)</span>
                                </div>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingfive">
                      <a
                        className="collapsed"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapsefive"
                        aria-expanded="false"
                        aria-controls="collapsefive"
                      >
                        Transmission
                      </a>
                    </div>
                    <div
                      id="collapsefive"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingfive"
                    >
                      <div className="panel-body">
                        <div className="cs-select-transmission">
                          <form>
                            <ul className="cs-checkbox-list">
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox24" type="checkbox" />
                                  <label htmlFor="checkbox24">Brand New</label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox25" type="checkbox" />
                                  <label htmlFor="checkbox25">
                                    Slightly Used
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="checkbox">
                                  <input id="checkbox26" type="checkbox" />
                                  <label htmlFor="checkbox26">Used</label>
                                </div>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" id="headingsix">
                      <a
                        className="collapsed"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#collapsesix"
                        aria-expanded="false"
                        aria-controls="collapsesix"
                      >
                        Engine Capacity(cc)
                      </a>
                    </div>
                    <div
                      id="collapsesix"
                      className="panel-collapse collapse show"
                      role="tabpanel"
                      aria-labelledby="headingsix"
                    >
                      <div className="panel-body">
                        <div className="cs-engine-capacity">
                          <form>
                            <input
                              id="engine"
                              type="text"
                              className="span2"
                              value="3000"
                              data-slider-min="10"
                              data-slider-max="1000"
                              data-slider-step="5"
                              data-slider-value="[600,200]"
                            />
                            <div className="selector-value">
                              <span>800CC</span>
                              <span className="pull-right">5500CC</span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <div className="section-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
              <div className="row">
                <InventoryNav />
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="auto-your-search">
                    <ul className="filtration-tags">
                      <li>
                        <a href="#">
                          Hybrid <i className="icon-cross2"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Bmw<i className="icon-cross2"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Compact hatchbac<i className="icon-cross2"></i>
                        </a>
                      </li>
                    </ul>
                    <a href="#" className="clear-tags cs-color">
                      Clear Filters
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media auto-media-slider">
                      <figure>
                        <img
                          src="assets/extra-images/listing-Grid-img1.jpg"
                          alt="#"
                        />
                        <figcaption>
                          <span className="auto-featured">Featured</span>
                          <a href="#" className="play-btn">
                            <i className="icon-play3"></i>
                          </a>
                          <div className="cs-photo">8 Photos</div>
                        </figcaption>
                      </figure>
                      <figure>
                        <img
                          src="assets/extra-images/listing-Grid-img1.jpg"
                          alt="#"
                        />
                        <figcaption>
                          <span className="auto-featured">Featured</span>
                          <a href="#" className="play-btn">
                            <i className="icon-play3"></i>
                          </a>
                          <div className="cs-photo">8 Photos</div>
                        </figcaption>
                      </figure>
                      <figure>
                        <img
                          src="assets/extra-images/listing-Grid-img1.jpg"
                          alt="#"
                        />
                        <figcaption>
                          <span className="auto-featured">Featured</span>
                          <a href="#" className="play-btn">
                            <i className="icon-play3"></i>
                          </a>
                          <div className="cs-photo">8 Photos</div>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h4>
                          <a href="#">Alfa Romeo Launch Edition 2dr Coupe</a>
                        </h4>
                        <h6>
                          <a href="#">Alfa Romeo Launch Edition 2dr Coupe</a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$30,000</span>{" "}
                          <em>MSRP $33,000</em>
                        </div>
                        <a href="#">
                          <img
                            src="assets/extra-images/post-list-img1.jpg"
                            alt="#"
                          />
                        </a>
                      </div>
                      <ul className="auto-info-detail">
                        <li>
                          Year<span>2002</span>
                        </li>
                        <li>
                          Mileage<span>121,899</span>
                        </li>
                        <li>
                          Trans<span>Auto</span>
                        </li>
                        <li>
                          Fuel Type<span>Hybrid</span>
                        </li>
                      </ul>
                      <div className="btn-list">
                        <a
                          href="javascript:void(0)"
                          className="btn btn-danger collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#list-view"
                        ></a>
                        <div id="list-view" className="collapse">
                          <ul>
                            <li>30/36 est. mpg 18</li>
                            <li>Black front grille with chrome accent</li>
                            <li>Cruise control</li>
                            <li>Remote keyless entry system</li>
                            <li>
                              Tilt 3-spoke steering wheel with audio controls
                            </li>
                            <li>15-in. alloy wheels</li>
                          </ul>
                        </div>
                      </div>
                      <p>
                        Started by a group of maverick Nissan engineers heights
                        of New performance almost...
                      </p>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list" />
                        <label htmlFor="check-list">Compare</label>
                      </div>
                      <a href="#" className="short-list cs-color">
                        <i className="icon-heart-o"></i>ShortList
                      </a>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img2.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h4>
                          <a href="#">Mercedes Benz E Class Cabriolet</a>
                        </h4>
                        <h6>
                          <a href="#">Mercedes Benz E Class Cabriolet</a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$22,000</span>{" "}
                          <em>MSRP $25,000</em>
                        </div>
                        <a href="#">
                          <img
                            src="assets/extra-images/post-list-img2.jpg"
                            alt="#"
                          />
                        </a>
                      </div>
                      <ul className="auto-info-detail">
                        <li>
                          Year<span>2002</span>
                        </li>
                        <li>
                          Mileage<span>121,899</span>
                        </li>
                        <li>
                          Trans<span>Auto</span>
                        </li>
                        <li>
                          Fuel Type<span>Hybrid</span>
                        </li>
                      </ul>
                      <div className="btn-list">
                        <a
                          href="javascript:void(0)"
                          className="btn btn-danger collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#list-view1"
                        ></a>
                        <div id="list-view1" className="collapse">
                          <ul>
                            <li>30/36 est. mpg 18</li>
                            <li>Black front grille with chrome accent</li>
                            <li>Cruise control</li>
                            <li>Remote keyless entry system</li>
                            <li>
                              Tilt 3-spoke steering wheel with audio controls
                            </li>
                            <li>15-in. alloy wheels</li>
                          </ul>
                        </div>
                      </div>
                      <p>
                        Start by a group of maverick Nissan engineers heights of
                        Nissan performance almost...
                        <a href="#" className="read-more cs-color">
                          + More
                        </a>
                      </p>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list1" />
                        <label htmlFor="check-list1">Compare</label>
                      </div>
                      <a href="#" className="short-list cs-color">
                        <i className="icon-heart-o"></i>ShortList
                      </a>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img3.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h4>
                          <a href="#">Mercedes Benz E Class Cabriolet</a>
                        </h4>
                        <h6>
                          <a href="#">Mercedes Benz E Class Cabriolet</a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$22,000</span>{" "}
                          <em>MSRP $25,000</em>
                        </div>
                        <a href="#">
                          <img
                            src="assets/extra-images/post-list-img3.jpg"
                            alt="#"
                          />
                        </a>
                      </div>
                      <ul className="auto-info-detail">
                        <li>
                          Year<span>2002</span>
                        </li>
                        <li>
                          Mileage<span>121,899</span>
                        </li>
                        <li>
                          Trans<span>Auto</span>
                        </li>
                        <li>
                          Fuel Type<span>Hybrid</span>
                        </li>
                      </ul>
                      <div className="btn-list">
                        <a
                          href="javascript:void(0)"
                          className="btn btn-danger collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#list-view2"
                        ></a>
                        <div id="list-view2" className="collapse">
                          <ul>
                            <li>30/36 est. mpg 18</li>
                            <li>Black front grille with chrome accent</li>
                            <li>Cruise control</li>
                            <li>Remote keyless entry system</li>
                            <li>
                              Tilt 3-spoke steering wheel with audio controls
                            </li>
                            <li>15-in. alloy wheels</li>
                          </ul>
                        </div>
                      </div>
                      <p>
                        Start by a group of maverick Nissan engineers heights of
                        Nissan performance almost...
                      </p>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list2" />
                        <label htmlFor="check-list2">Compare</label>
                      </div>
                      <a href="#" className="short-list cs-color">
                        <i className="icon-heart-o"></i>ShortList
                      </a>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img4.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list3" />
                        <label htmlFor="check-list3">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img5.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list4" />
                        <label htmlFor="check-list4">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img6.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list5" />
                        <label htmlFor="check-list5">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img7.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list6" />
                        <label htmlFor="check-list6">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img8.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list8" />
                        <label htmlFor="check-list8">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="auto-listing auto-grid">
                    <div className="cs-media">
                      <figure>
                        {" "}
                        <img
                          src="assets/extra-images/listing-Grid-img9.jpg"
                          alt="#"
                        />
                      </figure>
                    </div>
                    <div className="auto-text">
                      <span className="cs-categories">
                        <a href="#">Timlers Motors</a>
                      </span>
                      <div className="post-title">
                        <h6>
                          <a href="#">Mazda CX-5 SX, V6, ABS, Sunroof </a>
                        </h6>
                        <div className="auto-price">
                          <span className="cs-color">$25,000</span>{" "}
                          <em>MSRP $27,000</em>
                        </div>
                      </div>
                      <div className="cs-checkbox">
                        <input type="checkbox" name="list" id="check-list9" />
                        <label htmlFor="check-list9">Compare</label>
                      </div>
                      <a href="#" className="View-btn">
                        View Detail<i className=" icon-arrow-long-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <nav>
                    <ul className="pagination">
                      <li>
                        <a href="#" aria-label="Previous">
                          <span aria-hidden="true">
                            <i className="icon-angle-left"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="active">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#" aria-label="Next">
                          <span aria-hidden="true">
                            <i className="icon-angle-right"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section" style={{ background: "#19171a" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div
                className="cs-ad"
                style={{ textAlign: "center", padding: "55px 0 32px" }}
              >
                <div className="cs-media">
                  <figure>
                    <img src="assets/extra-images/cs-ad-img.jpg" alt="#" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryGridPage;
