import React from 'react';
const Faq = () => {
  return (
    <div className="main-section">
      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="section-content col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
              <div className="content-area">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-section-title alignleft">
                      <h3>Frequently Ask Questions</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-faq-tabs">
                      <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation">
                          <a
                            href="#home"
                            className="active"
                            aria-controls="home"
                            role="tab"
                            data-bs-toggle="tab"
                          >
                            General Information
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#profile"
                            aria-controls="profile"
                            role="tab"
                            data-bs-toggle="tab"
                          >
                            Vehicle Pricing
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#messages"
                            aria-controls="messages"
                            role="tab"
                            data-bs-toggle="tab"
                          >
                            Used Vehicles
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#settings"
                            aria-controls="settings"
                            role="tab"
                            data-bs-toggle="tab"
                          >
                            Repairs &amp; Maintenance
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="home"
                        >
                          <div
                            className="panel-group"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingOne"
                              >
                                <h6 className="panel-title">
                                  <a
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion"
                                    href="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    <span className="cs-color">Q.</span>Do You
                                    want to sell a car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapseOne"
                                className="panel-collapse collapse show"
                                role="tabpanel"
                                data-bs-parent="#accordion"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingTwo"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion"
                                    href="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapseTwo"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingThree"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion"
                                    href="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapseThree"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingfour"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion"
                                    href="#collapsefour"
                                    aria-expanded="false"
                                    aria-controls="collapsefour"
                                  >
                                    <span className="cs-color">Q.</span>Maintain
                                    your car to stay safe on the road
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapsefour"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingfive"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion"
                                    href="#collapsefive"
                                    aria-expanded="false"
                                    aria-controls="collapsefive"
                                  >
                                    <span className="cs-color">Q.</span>We go
                                    through extensive factory training so that
                                    we may provide
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapsefive"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="profile"
                        >
                          <div
                            className="panel-group"
                            id="accordion2"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading6"
                              >
                                <h6 className="panel-title">
                                  <a
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion2"
                                    href="#collapse6"
                                    aria-expanded="true"
                                    aria-controls="collapse6"
                                  >
                                    <span className="cs-color">Q.</span>Do You
                                    want to sell a car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse6"
                                className="panel-collapse collapse show"
                                role="tabpanel"
                                data-bs-parent="#accordion2"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading7"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion2"
                                    href="#collapse7"
                                    aria-expanded="false"
                                    aria-controls="collapse7"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse7"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion2"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading8"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion2"
                                    href="#collapse8"
                                    aria-expanded="false"
                                    aria-controls="collapse8"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse8"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion2"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading9"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion2"
                                    href="#collapse9"
                                    aria-expanded="false"
                                    aria-controls="collapse9"
                                  >
                                    <span className="cs-color">Q.</span>Maintain
                                    your car to stay safe on the road
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse9"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion2"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading10"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion2"
                                    href="#collapse10"
                                    aria-expanded="false"
                                    aria-controls="collapse10"
                                  >
                                    <span className="cs-color">Q.</span>We go
                                    through extensive factory training so that
                                    we may provide
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse10"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion2"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="messages"
                        >
                          <div
                            className="panel-group"
                            id="accordion3"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading15"
                              >
                                <h6 className="panel-title">
                                  <a
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion3"
                                    href="#collapse15"
                                    aria-expanded="true"
                                    aria-controls="collapse15"
                                  >
                                    <span className="cs-color">Q.</span>Do You
                                    want to sell a car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse15"
                                className="panel-collapse collapse show"
                                role="tabpanel"
                                data-bs-parent="#accordion3"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading16"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion3"
                                    href="#collapse16"
                                    aria-expanded="false"
                                    aria-controls="collapse16"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse16"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion3"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading17"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion3"
                                    href="#collapse17"
                                    aria-expanded="false"
                                    aria-controls="collapse17"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse17"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion3"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading18"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion3"
                                    href="#collapse18"
                                    aria-expanded="false"
                                    aria-controls="collapse18"
                                  >
                                    <span className="cs-color">Q.</span>Maintain
                                    your car to stay safe on the road
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse18"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion3"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading20"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion3"
                                    href="#collapse20"
                                    aria-expanded="false"
                                    aria-controls="collapse20"
                                  >
                                    <span className="cs-color">Q.</span>We go
                                    through extensive factory training so that
                                    we may provide
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse20"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion3"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane fade"
                          id="settings"
                        >
                          <div
                            className="panel-group"
                            id="accordion4"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading21"
                              >
                                <h6 className="panel-title">
                                  <a
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion4"
                                    href="#collapse21"
                                    aria-expanded="true"
                                    aria-controls="collapse21"
                                  >
                                    <span className="cs-color">Q.</span>Do You
                                    want to sell a car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse21"
                                className="panel-collapse collapse show"
                                role="tabpanel"
                                data-bs-parent="#accordion4"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading22"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion4"
                                    href="#collapse22"
                                    aria-expanded="false"
                                    aria-controls="collapse22"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse22"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion4"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading23"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion4"
                                    href="#collapse23"
                                    aria-expanded="false"
                                    aria-controls="collapse23"
                                  >
                                    <span className="cs-color">Q.</span>Are You
                                    looking for a new car?
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse23"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion4"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading24"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion4"
                                    href="#collapse24"
                                    aria-expanded="false"
                                    aria-controls="collapse24"
                                  >
                                    <span className="cs-color">Q.</span>Maintain
                                    your car to stay safe on the road
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse24"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion4"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="panel panel-default">
                              <div
                                className="panel-heading"
                                role="tab"
                                id="heading25"
                              >
                                <h6 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-bs-toggle="collapse"
                                    data-bs-parent="#accordion4"
                                    href="#collapse25"
                                    aria-expanded="false"
                                    aria-controls="collapse25"
                                  >
                                    <span className="cs-color">Q.</span>We go
                                    through extensive factory training so that
                                    we may provide
                                  </a>
                                </h6>
                              </div>
                              <div
                                id="collapse25"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                data-bs-parent="#accordion4"
                              >
                                <div className="panel-body">
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious. Our
                                  subconscious mind contains such power and
                                  complexity that it literally staggers the
                                  imagination. We possess within us two minds.
                                  <ul>
                                    <li>
                                      <i className="icon-check3"></i>Volutpat
                                      luctus lacus hendrerit taciti lobortis in.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Faucibus
                                      amet consectetur erat donec venenatis leo.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Ullamcorper
                                      conubia, aenean quisque mattis malesuada.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>quam
                                      quisque ligula, maecenas ligula proin ut
                                      augue.
                                    </li>
                                    <li>
                                      <i className="icon-check3"></i>Tincidunt
                                      quisque habitasse suspendisse class
                                      pharetra dapibus.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="cs-section-title alignleft">
                      <h3>Got Questions? We've Got Answers</h3>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div
                      className="panel-group box"
                      id="accordion5"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading30"
                        >
                          <h6 className="panel-title">
                            <a
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse30"
                              aria-expanded="true"
                              aria-controls="collapse30"
                            >
                              <i className="icon-directions_car"></i>We go
                              through extensive factory training so that we may
                              provide
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse30"
                          className="panel-collapse collapse show"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading31"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse31"
                              aria-expanded="false"
                              aria-controls="collapse31"
                            >
                              <i className="icon-key22"></i>You need to make an
                              educated decision in choosing the vehicle
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse31"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading32"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse32"
                              aria-expanded="false"
                              aria-controls="collapse32"
                            >
                              <i className="icon-cog2"></i>Motors, we think you
                              should also have pretty high expectations for your
                              dealership.
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse32"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading33"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse33"
                              aria-expanded="false"
                              aria-controls="collapse33"
                            >
                              <i className="icon-grooveshark"></i>Fusce eu massa
                              nec diam fermentum imperdiet ac vel est?
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse33"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading35"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse35"
                              aria-expanded="false"
                              aria-controls="collapse35"
                            >
                              <i className=" icon-support2"></i>WHAT IS THE
                              PRICE OF YOUR COOL PSD
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse35"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading36"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse36"
                              aria-expanded="false"
                              aria-controls="collapse36"
                            >
                              <i className="icon-earth"></i>HOW MANY HOURS DOES
                              IT TAKES
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse36"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading"
                          role="tab"
                          id="heading37"
                        >
                          <h6 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion5"
                              href="#collapse37"
                              aria-expanded="false"
                              aria-controls="collapse37"
                            >
                              <i className=" icon-meter"></i>CAN I BUY IT
                              IMMEDIATELY
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapse37"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          data-bs-parent="#accordion5"
                        >
                          <div className="panel-body">
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination. We possess within us two
                            minds. So far I have written only of the conscious
                            mind. I would now like to introduce you to your
                            second mind.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="cs-section-title alignleft">
                          <h3>Frequent Questions?</h3>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="cs-services top-center has-border">
                          <div className="cs-media">
                            {' '}
                            <i
                              className=" icon-speedometer cs-color"
                              style={{ fontSize: '60px' }}
                            >
                              {' '}
                            </i>{' '}
                          </div>
                          <div className="cs-text">
                            <h4>Need support?</h4>
                            <p>
                              Make sure your customer sees this but also be
                              careful to spray in the opposite direction. The
                              Cool Care spray is a coolant?{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="cs-services top-center has-border">
                          <div className="cs-media">
                            {' '}
                            <i
                              className="icon-transport177 cs-color"
                              style={{ fontSize: '60px' }}
                            >
                              {' '}
                            </i>{' '}
                          </div>
                          <div className="cs-text">
                            <h4>CHECK FORUM?</h4>
                            <p>
                              Make sure your customer sees this but also be
                              careful to spray in the opposite direction. The
                              Cool Care spray is a coolant?{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside className="section-sidebar col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
              <div className="widget widget-search">
                <h6>Recent Posts</h6>
                <div className="input-holder">
                  <form>
                    <input type="text" placeholder="Enter any keyword" />
                    <label>
                      <input type="submit" value="" />
                    </label>
                  </form>
                </div>
              </div>
              <div className="widget widget-text">
                <h6>ONE MORE QUESTION ?</h6>
                <p>
                  Eagle and Pig isn’t your average barbershop; it’s a place
                  where Dane combines all his favourite things.classic. men’s
                  grooming, beer.
                </p>
                <a href="#" className="contact-btn cs-color">
                  Contact us
                </a>
              </div>
              <div className="widget widget-comments">
                <h6>rECENT COMMENTS</h6>
                <ul>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>Prueba on
                      Happy Ninja Hoodie
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>me on This is
                      a Gallery Post
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>wholesale
                      bags on This is a Sticky.
                    </a>
                  </li>
                </ul>
              </div>
              <div className="widget widget-archives">
                <h6>ARCHIVES</h6>
                <ul>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>December 2023
                    </a>
                    (2)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>November 2023
                    </a>
                    (1)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>October 2023
                    </a>
                    (1)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>September
                      2023
                    </a>
                    (1)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>August 2023
                    </a>
                    (1)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>July 2023
                    </a>
                    (1)
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-keyboard_arrow_right"></i>April 2023
                    </a>
                    (3)
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
