import React from "react";
import { Link } from "react-router-dom";

const InventoryNav = () => {
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
      <div className="auto-sort-filter">
        <div className="auto-show-resuilt">
          <span>
            SHOWING <em>25</em> RESULTS FOR YOUR SEARCH
          </span>
        </div>
        <div className="auto-list">
          <span>Sort</span>
          <ul>
            <li>
              <div className="cs-select-post">
                <select
                  data-placeholder="Recent Added"
                  className="chosen-select-no-single"
                  tabIndex="5"
                >
                  <option>Recent Added</option>
                  <option>Recent Added</option>
                  <option>Recent Added</option>
                  <option>Recent Added</option>
                </select>
              </div>
            </li>
            <li>
              <Link to="/inventory-grid">
                <i className=" icon-view_module"></i>
              </Link>
            </li>
            <li>
              <Link to="/inventory-row">
                <i className="icon-view_array"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InventoryNav;
