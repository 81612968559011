import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <React.Fragment>
      <div className="cs-subheader">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="cs-subheader-text">
                <h2>404 Page</h2>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="active">
                      <a href="#">404 Page</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div
          className="page-section"
          style={{ paddingTop: '280px', paddingBottom: '280px' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-page-not-found">
                  <div className="cs-text">
                    <p>Sorry, but the page that you requested doesn't exist.</p>
                    <span className="cs-error">
                      404<em>Error</em>
                    </span>
                  </div>
                  <form>
                    <div className="input-holder">
                      <i className="icon-search2"> </i>
                      <input type="text" placeholder="Search by Keyword" />
                      <label>
                        <input
                          className="cs-bgcolor"
                          type="submit"
                          value="search"
                        />
                        <i className="icon-search2"> </i>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-seprater-v1">
                  <Link to="/">
                    <span>
                      <i className="icon-home2 cs-bgcolor"> </i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Page404;
