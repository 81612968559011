import React from "react";

const ContactUs = () => {
  return (
    <React.Fragment>
      <div
        className="cs-subheader left"
        style={{
          background:
            "url(assets/extra-images/bg-subheader-01.jpg) no-repeat 0 0 /cover",
          minHeight: "296px",
          padding: "90px 0 0",
          marginBottom: "50px",
        }}
      >
        <div className="container">
          <div className="cs-page-title">
            <h1 style={{ color: "#fff !important" }}>Get in touch</h1>
            <p style={{ color: "#fff" }}>
              If you think we might be a good fit for a project you’re working
              on and you’d like to get the
              <br /> feel of how we can work together please contact us using
              contact data.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Main Start --> */}
      <div className="main-section">
        <div
          className="page-section"
          style={{ paddingTop: "20px", paddingBottom: "10px" }}
        >
          <div className="container">
            <div className="row">
              <div className="section-fullwidtht col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="row">
                  {/* <!--Element Section Start--> */}
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cs-contact-info left">
                      <div className="cs-media">
                        <i className="icon-map4 cs-color"></i>
                      </div>
                      <div className="cs-text">
                        <h6>Contact Info</h6>
                        <p>
                          Automotive inc. # O Office Address 101 Gray's Inn Rd,
                          London United Kingdom
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cs-contact-info left">
                      <div className="cs-media">
                        <i className="icon-envelope2 cs-color"></i>
                      </div>
                      <div className="cs-text">
                        <h6>Emaill Address</h6>
                        <p>
                          <a href="#">info@automotive.com</a>
                          <a href="#">email-2@automotive.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cs-contact-info left">
                      <div className="cs-media">
                        <i className="icon-phone4 cs-color"></i>
                      </div>
                      <div className="cs-text">
                        <h6>Phone Numbers</h6>
                        <p>
                          <span>Telephone: 281-290-4755</span>
                          <span>Fax: 281-290-4755</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cs-contact-info left">
                      <div className="cs-media">
                        <i className="icon-briefcase3 cs-color"></i>
                      </div>
                      <div className="cs-text">
                        <h6>Office Timings</h6>
                        <p>
                          <span>WEEK DAYS: 05:00 – 22:00</span>
                          <span>SATURDAY: 08:00 – 18:00</span>
                          <span>SUNDAY: CLOSED</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="cs-seprater" style={{ textAlign: "center" }}>
                    {" "}
                    <span>
                      {" "}
                      <i className="icon-transport177"> </i>{" "}
                    </span>{" "}
                  </div>
                  {/* <!--Element Section End--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section"
          style={{ paddingTop: "40px", paddingBottom: "60px" }}
        >
          <div className="container">
            <div className="row">
              <div className="section-fullwidtht col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="cs-contact-form">
                  {/* <!--Element Section Start--> */}
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="cs-section-title">
                        <h3
                          style={{
                            textTransform: "uppercase !important",
                          }}
                        >
                          GET IN TOUCH WITH US
                        </h3>
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "11px",
                            color: "#999999 !important",
                          }}
                        >
                          We possess within us two minds. So far I have written
                          only of the conscious mind. I would now like to
                          introduce you <br />
                          to your second mind, the hidden and mysterious
                          subconscious.
                        </p>
                      </div>
                    </div>
                    <form className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-form-holder">
                                <div className="input-holder">
                                  <input type="text" placeholder="Full Name" />
                                  <i className=" icon-user"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-form-holder">
                                <div className="input-holder">
                                  <input
                                    type="text"
                                    placeholder="Email Address"
                                  />
                                  <i className=" icon-envelope"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-form-holder">
                                <div className="input-holder">
                                  <input
                                    type="text"
                                    placeholder="Phone Number"
                                  />
                                  <i i className="icon-mobile2"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-form-holder">
                                <div className="input-holder" id="data-toggle">
                                  <input
                                    type="checkbox"
                                    id="cbox2"
                                    value="second_checkbox"
                                  />{" "}
                                  <label htmlFor="cbox2">
                                    Subscribe and Get latest updates and offers
                                    by Email
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-form-holder">
                                <div className="input-holder">
                                  <textarea placeholder="I am interested in a price quote on this vehicle. Please contact me at you earliest convenience with your best price for this vehicle"></textarea>
                                  <i></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                              <div className="cs-btn-submit cs-color">
                                <input type="submit" value="Contact Dealer" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <!--Element Section End--> */}
            </div>
          </div>
        </div>
        <div className="page-section" style={{ marginBottom: "25px" }}>
          <div className="container">
            <div className="row">
              <div className="section-fullwidtht col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="row">
                  <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="maps loader">
                      <iframe
                        height="350"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d85325.03550975422!2d-1.957436106486313!3d53.45954307751346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited+Kingdom!5e0!3m2!1sen!2s!4v1455785475576"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
